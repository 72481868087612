import * as React from "react";

function Saved(props) {
  return (
    <svg
      id="prefix__Layer_2"
      data-name="Layer 2"
      width="4em"
      height="4em"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:none;stroke-width:0}"}</style>
      </defs>
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path className="prefix__cls-1" d="M0 0h128v128H0z" />
        <g strokeWidth={0}>
          <path
            d="M92.18 110.755c-.45 0-.894-.113-1.32-.338L69.561 99.22c-1.708-.899-3.632-1.373-5.562-1.373s-3.853.474-5.562 1.373L37.14 110.417a2.816 2.816 0 01-1.322.338c-.82 0-1.64-.388-2.187-1.038a2.733 2.733 0 01-.608-2.291l4.068-23.714a11.947 11.947 0 00-3.438-10.58l-17.23-16.795a2.783 2.783 0 01-.717-2.907 2.784 2.784 0 012.29-1.932l23.81-3.46a11.952 11.952 0 009-6.538l10.65-21.576c.488-.99 1.44-1.581 2.543-1.581s2.055.59 2.543 1.58L77.193 41.5a11.945 11.945 0 009 6.538l23.81 3.46c1.093.16 1.95.88 2.29 1.932a2.783 2.783 0 01-.718 2.907l-17.23 16.795a11.95 11.95 0 00-3.437 10.58l4.068 23.715a2.733 2.733 0 01-.608 2.29c-.548.65-1.366 1.038-2.187 1.038z"
            fill={props.fill || "#faaf40"}
          />
          <path
            d="M64 20.842c.21 0 .26.104.302.188L74.95 42.606a14.45 14.45 0 0010.883 7.907l23.81 3.46c.093.013.208.03.272.229.065.199-.018.28-.085.345L92.6 71.342a14.45 14.45 0 00-4.156 12.792l4.067 23.715c.024.136.002.189-.054.256-.073.087-.19.15-.277.15a.364.364 0 01-.157-.05L70.725 97.006c-2.065-1.086-4.391-1.66-6.725-1.66s-4.66.574-6.725 1.66l-21.298 11.197a.363.363 0 01-.157.05c-.088 0-.204-.062-.277-.15-.056-.066-.078-.118-.054-.255l4.067-23.715A14.45 14.45 0 0035.4 71.342L18.17 54.547c-.067-.065-.15-.146-.085-.345.064-.2.18-.216.271-.23l23.811-3.46a14.45 14.45 0 0010.882-7.906L63.7 21.03c.04-.084.092-.188.301-.188m0-5c-1.903 0-3.807.992-4.785 2.975l-10.65 21.577a9.453 9.453 0 01-7.117 5.17l-23.81 3.46c-4.378.637-6.126 6.016-2.958 9.103l17.23 16.795a9.453 9.453 0 012.718 8.367l-4.067 23.715c-.592 3.45 2.149 6.25 5.259 6.25.82 0 1.667-.195 2.484-.624L59.6 101.433a9.448 9.448 0 018.798 0l21.297 11.197c.818.43 1.664.625 2.484.625 3.11 0 5.85-2.802 5.26-6.25l-4.068-23.716a9.453 9.453 0 012.718-8.367l17.23-16.795c3.168-3.087 1.42-8.466-2.957-9.102l-23.811-3.46a9.453 9.453 0 01-7.118-5.171L68.785 18.817c-.978-1.983-2.882-2.975-4.785-2.975z"
            fill="#1a1a1a"
          />
        </g>
        <path className="prefix__cls-1" d="M0 0h128v128H0z" />
      </g>
    </svg>
  );
}

const MemoSaved = React.memo(Saved);
export default MemoSaved;
