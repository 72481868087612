import * as React from "react";

function Save(props) {
  return (
    <svg
      id="prefix__Layer_2"
      data-name="Layer 2"
      width="4em"
      height="4em"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1,.prefix__cls-2{fill:none;stroke-width:0}.prefix__cls-2{fill:#1a1a1a}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path className="prefix__cls-1" d="M0 0h128v128H0z" />
        <path
          className="prefix__cls-2"
          d="M64 20.842c.21 0 .26.104.302.188L74.95 42.606a14.45 14.45 0 0010.883 7.907l23.81 3.46c.093.013.208.03.272.229.065.199-.018.28-.085.345L92.6 71.342a14.45 14.45 0 00-4.156 12.792l4.067 23.715c.024.136.002.189-.054.256-.073.087-.19.15-.277.15a.364.364 0 01-.157-.05L70.726 97.006c-2.066-1.086-4.392-1.66-6.726-1.66s-4.66.574-6.725 1.66l-21.298 11.197a.363.363 0 01-.157.05c-.088 0-.204-.062-.277-.15-.056-.066-.078-.118-.054-.255l4.067-23.715A14.45 14.45 0 0035.4 71.342L18.17 54.547c-.067-.065-.15-.146-.085-.345.064-.2.18-.216.271-.23l23.812-3.46a14.45 14.45 0 0010.881-7.906L63.7 21.03c.04-.084.092-.188.301-.188m0-5c-1.903 0-3.807.992-4.785 2.975l-10.65 21.577a9.453 9.453 0 01-7.116 5.17l-23.812 3.46c-4.377.637-6.125 6.016-2.957 9.103l17.23 16.795a9.453 9.453 0 012.718 8.367l-4.067 23.715c-.592 3.45 2.149 6.25 5.259 6.25.82 0 1.667-.195 2.484-.624L59.6 101.433a9.449 9.449 0 018.798 0l21.297 11.197c.818.43 1.664.625 2.484.625 3.11 0 5.85-2.802 5.26-6.25l-4.068-23.716a9.453 9.453 0 012.718-8.367l17.23-16.795c3.168-3.087 1.42-8.466-2.957-9.102l-23.811-3.46a9.453 9.453 0 01-7.118-5.171L68.786 18.817c-.98-1.983-2.883-2.975-4.786-2.975z"
        />
        <path
          className="prefix__cls-2"
          d="M101.04 40.757a2.5 2.5 0 01-2.5-2.5v-25.6a2.5 2.5 0 115 0v25.6a2.5 2.5 0 01-2.5 2.5z"
        />
        <path
          className="prefix__cls-2"
          d="M113.84 27.957h-25.6a2.5 2.5 0 110-5h25.6a2.5 2.5 0 110 5z"
        />
        <path className="prefix__cls-1" d="M0 0h128v128H0z" />
      </g>
    </svg>
  );
}

const MemoSave = React.memo(Save);
export default MemoSave;
