[
  {
    "id": 1,
    "day": "Sunday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "\"Acoustic Community Sound Jam\" — Bring acoustic instruments & jam with new friends in our cozy magical medicine space!Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 2,
    "day": "Friday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "\"Cosmic Sound Journey in SPAAAACE!!\" — Get galactivated on our immersive sonic space ship! Dive through a portal to interstellar realms & receive healing sonic transmissions from another\n dimension.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 3,
    "day": "Friday",
    "when": "12:15 PM-12:50 PM",
    "eventType": "Class/Workshop",
    "what": "\"Gong Bath & Breathwork\" — Join us for a gong bath & breathwork session to regulate your nervous system.\n Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 4,
    "day": "Saturday",
    "when": "12:15 PM-12:50 PM",
    "eventType": "Class/Workshop",
    "what": "\"Gong Bath & Breathwork\" — Join us for a gong bath & breathwork session to regulate your nervous system.\n Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 5,
    "day": "Thursday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Class/Workshop",
    "what": "\"Grounding Meditation Sound Journey\" — Set yourself up for success at SOAK at our intentional landing pad. We'll do a guided meditation focused on grounding, centering, & shielding. We'll call in our helping spirits & set our intentions for the weekend ahead with the help of sound therapy & energy cleansing tools.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 6,
    "day": "Friday",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Food/Beverage",
    "what": "\"Haven Can't Wait\" — Happy Hour: Drinks or Tea. BIPOC focused.Seating, Alcohol-Free",
    "where": "unityHaven",
    "area": "Main Field"
  },
  {
    "id": 7,
    "day": "Friday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "\"Holistic Harm Reduction with Ericat of Hedonist Health\"\n — Maximize vitality when you party! Learn how to optimize recovery before, during, & after any altered states experience with natural food & lifestyle health hacks. This workshop demonstrates how different substances affect brain chemicals & how food creates & protects these neurotransmitters. 1st hour is lecture, 2nd hour is Q&A.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 8,
    "day": "Saturday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "\"Holistic Harm Reduction with Ericat of Hedonist Health\"\n — Maximize vitality when you party! Learn how to optimize recovery before, during, & after any altered states experience with natural food & lifestyle health hacks. This workshop demonstrates how different substances affect brain chemicals & how food creates & protects these neurotransmitters. 1st hour is lecture, 2nd hour is Q&A.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 9,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "\"Including...You!\" — Open Mic happy hour! Share a talent, or a story! Signups occurring all day. BIPOC focused.Seating",
    "where": "UnityHaven",
    "area": "Main Field"
  },
  {
    "id": 10,
    "day": "Sunday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "\"Including...You!\" — Open Mic happy hour! Share a talent, or a story! Signups occurring all day. BIPOC focused.Seating",
    "where": "UnityHaven",
    "area": "Main Field"
  },
  {
    "id": 11,
    "day": "Sunday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "\"Navigating Conflict with Ease with Nate Powers\" — Learn conflict resolution strategies for approaching difficult conversations with a professional mediator. Participants will learn about non-violent communication, active listening, & de-escalation techniques. Through recognizing our biases & being mindful listeners, we can address conflict with more ease, compassion, & grace.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 12,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:11 AM-01:11 PM",
    "eventType": "Food/Beverage",
    "what": "\"Puer & Chill\" — slow sips from tiny tea cups; enter this moment of playful presence, relaxation, and connection; leave filled to the brim with delight & an energy boost for future shenanigans.Seating, Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 13,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:11 AM-01:11 PM",
    "eventType": "Food/Beverage",
    "what": "\"Puer & Chill\" — slow sips from tiny tea cups; enter this moment of playful presence, relaxation, and connection; leave filled to the brim with delight & an energy boost for future shenanigans.Seating, Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 14,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:11 AM-01:11 PM",
    "eventType": "Food/Beverage",
    "what": "\"Puer & Chill\" — slow sips from tiny tea cups; enter this moment of playful presence, relaxation, and connection; leave filled to the brim with delight & an energy boost for future shenanigans.Seating, Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 15,
    "day": "Saturday",
    "when": "06:00 PM-07:15 PM",
    "eventType": "Class/Workshop",
    "what": "\"Shamanic Sound Journey with the Elements\" — Join us for a ritual to invoke the medicine wheel & weave the elements through a series of purifying practices & sound therapy. In this session, we will experience somatic release, elemental breathwork, healing sound vibrations, & energy clearing.Alcohol-Free",
    "where": "Sonic Sauna",
    "area": "Main Field"
  },
  {
    "id": 16,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "\"Spill Some Tea\" — Tea Time Social Hour. Enjoy some tea (black, green, herbal) and mingle! BIPOC focused.Seating, Alcohol-Free",
    "where": "unityHaven",
    "area": "Main Field"
  },
  {
    "id": 17,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "\"Spill Some Tea\" — Tea Time Social Hour. Enjoy some tea (black, green, herbal) and mingle! BIPOC focused.Seating, Alcohol-Free",
    "where": "UnityHaven",
    "area": "Main Field"
  },
  {
    "id": 18,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "\"Spill Some Tea\" — Tea Time Social Hour. Enjoy some tea (black, green, herbal) and mingle! BIPOC focused.Seating, Alcohol-Free",
    "where": "UnityHaven",
    "area": "Main Field"
  },
  {
    "id": 19,
    "day": "Friday",
    "when": "09:00 PM-09:15 PM",
    "eventType": "Performance/Entertainment",
    "what": "$TARR 69 BURLESQUE PERFORMANCE: Step into the\n whimsical world of my burlesque act where silliness means sensuality and electrifying bedroom escapade. With a playful puppet as co-star, prepare for a roller coaster of laughter and titillation, leaving you utterly dazzled and thoroughly entertained.Loud Noise, Seating, Vegetarian, Vegan, Gluten Free, 18+",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 20,
    "day": "Saturday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "20-hand rotating massage! 35-minute experience. Join the people giving the massages and rotating around the table with our rockin' music until it's your turn to be massaged. Arrive by 11:30 at the latest; sign up while you get your Astro Shack coffee.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 21,
    "day": "Sunday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "20-hand rotating massage! 35-minute experience. Join the people giving the massages and rotating around the table with our rockin' music until it's your turn to be massaged. Arrive by 11:30 at the latest; sign up while you get your Astro Shack coffee.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 22,
    "day": "Sunday",
    "when": "04:20 PM-07:06 PM",
    "eventType": "Experience/Interactive Art",
    "what": "666 Sinday Service, Cum Celebate Sinday with us. Whether you Whoreship as a Priest or Priestess or Nun at all, everyone's welcome to bow down and take punishment for your sins. Restrain them on the Pentagram or the Cross and give them the Crown of Thorns, just don’t spill any blood please. 21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 23,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "A Mad Hatter’s tea party for a mad world! Travel down the rabbit hole and join us for “high” tea, a wonderland of CBD-infused tea, hookah smoking, and sweets. Delve into collegial or nonsensical conversation. Be courageous but don't be late or you may be accosted by the queen of hearts or confronted by the camp Cheshire cat.",
    "where": "Mad Hatter's HIGH Tea",
    "area": "Main Field"
  },
  {
    "id": 24,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "A Mad Hatter’s tea party for a mad world! Travel down the rabbit hole and join us for “high” tea, a wonderland of CBD-infused tea, hookah smoking, and sweets. Delve into collegial or nonsensical conversation. Be courageous but don't be late or you may be accosted by the queen of hearts or confronted by the camp Cheshire cat.",
    "where": "Mad Hatter's HIGH Tea",
    "area": "Main Field"
  },
  {
    "id": 25,
    "day": "Friday",
    "daily": "Everyday",
    "when": "06:00 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "A sea cave inspired cuddle-puddle featuring a spinning LED sea urchin dazzling in the sky. It will be a wonder to behold! Come and enjoy after dark for the light show or any time for a peaceful area to relax. Flashing Lights, Seating",
    "where": "Fractious Flow",
    "area": "Riverside"
  },
  {
    "id": 26,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "06:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "A sea cave inspired cuddle-puddle featuring a spinning LED sea urchin dazzling in the sky. It will be a wonder to behold! Come and enjoy after dark for the light show or any time for a peaceful area to relax. Flashing Lights, Seating",
    "where": "Fractious Flow",
    "area": "Riverside"
  },
  {
    "id": 27,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "06:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "A sea cave inspired cuddle-puddle featuring a spinning LED sea urchin dazzling in the sky. It will be a wonder to behold! Come and enjoy after dark for the light show or any time for a peaceful area to relax. Flashing Lights, Seating",
    "where": "Fractious Flow",
    "area": "Riverside"
  },
  {
    "id": 28,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "A sea cave inspired cuddle-puddle featuring a spinning LED sea urchin dazzling in the sky. It will be a wonder to behold! Come and enjoy after dark for the light show or any time for a peaceful area to relax. Flashing Lights, Seating",
    "where": "Fractious Flow",
    "area": "Riverside"
  },
  {
    "id": 29,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "ABBA Dance Party — You can dance, you can dance...as BRCU revives the ABBA Dance Party featuring all the best of the Swedish pop phenomenon as well as remixes and covers of their hits. Groove to the 70s-tastic classics in an afternoon of throwback fun.\n Having the time of your life. Find us in the Main Field, near The Barn.",
    "where": "Black Rock Center for Unlearning",
    "area": "Main Field"
  },
  {
    "id": 30,
    "day": "Saturday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "Absolute Beginner Crochet — Calling all aspiring hookers. This hands-on class will let you see if you love the magical art of weaving yarn into dreams. Yarn and hooks provided. All experience levels welcome to attend beginner class for camaraderie and crojo.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 31,
    "day": "Sunday",
    "when": "07:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Acoustic, non-amplified community jam session! Do you play an instrument and love to play with others? If so, bring your non-amplified instrument and playful spirit to the Embassy after-dark warming fire for an unscripted sharing of sound. All skill levels welcomed. <3 Warm your bodies around the fire and warm the souls of others with your jams.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 32,
    "day": "Thursday",
    "when": "07:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Acoustic, non-amplified community jam session! Do you play an instrument and love to play with others? If so, bring your non-amplified instrument and playful spirit to the Embassy after-dark warming fire for an unscripted sharing of sound. All skill levels welcomed. <3 Warm your bodies around the fire and warm the souls of others with your jams.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 33,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "ACRO YOGA JAM — Join us to hone/calibrate your\n L-base acro skills with our resident acrobatics hobbyists. If this brand of nonsense is new to you, we can teach some introductory poses and spotting. Bring a yoga mat or blanket.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 34,
    "day": "Sunday",
    "when": "12:30 PM-01:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Acroyoga jam (all levels welcome) — Come play with other acroyoga enthusiasts! This is an unguided session focused on play and community experience. We will rotate through stretches, people, and positions on a padded surface. If you can plank for 30 seconds, you can do acro!",
    "where": "Practical Pleasures",
    "area": "Forest Entry"
  },
  {
    "id": 35,
    "day": "Saturday",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Affirmation body painting. Inspired by Dr. Emoto's work. Come, set an intention for this cycle of your life, and be blessed with words of affirmation and Love.",
    "where": "Camp Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 36,
    "day": "Sunday",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Affirmation body painting. Inspired by Dr. Emoto's work. Come, set an intention for this cycle of your life, and be blessed with words of affirmation and Love.",
    "where": "Camp Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 37,
    "day": "Sunday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "After School Special (ASS): Tired of schooling with all the other fish in this Electric Ocean? Join us for an After School Special! Bring your crush & get your pizza rolls 'n' chill on to the sultry sounds of 90s/00s Hip Hop & RnB. Extra credit will be given for booty shorts & bucket hats. (VG/GF snacks available!)Seating, Vegetarian, Vegan, Gluten Free, Nuts",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 38,
    "day": "Saturday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "AfterBurn - We let it burn! Now, it’s time to burn up the dance floor with those saucy moves you’ve got. Come bring your heat to Camp Clusterfuck as Loopacabra & friends pump up the tunes and keep your fire going. Be sure to catch us and some of your SOAK Favorites groovin’ through the night.Loud Noise, Flashing Lights, Seating",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 39,
    "day": "Sunday",
    "when": "02:30 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "Afternoon Delight - Ménage-à-drawing Soirée: Artists and models wanted for timed poses with volunteer models. Drawing supplies provided or BYO. Paint me like one of your French girls!Seating, Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 40,
    "day": "Saturday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Afternoon Tea at Bucketham Palace: Get ROYALLY pampered! While away the afternoon sipping a Bucket of Tea™ with Ms. Soak 2023 + Ms. Soak 2024! Treat yourself to some R&R (Royal Relaxation). Enjoy the soothing pleasures of tea from buckets, nibble on\n bucket-shaped cookies, up your fashion game with bucket accessories, and compete in Big Bucket Hunter games.",
    "where": "BUCKETS",
    "area": "Upper Bowl"
  },
  {
    "id": 41,
    "day": "Saturday",
    "when": "03:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Ahoy mateys! Come win/lose all ye' marbles down in the depths o' de dome. Arr, lads, lasses, and laxes, don ye' finest scallawag rags and come about to draw five cards hold 'em if ye' dare.Seating",
    "where": "Hypnodrome",
    "area": "Main Field"
  },
  {
    "id": 42,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Ahoy Yogis! Venture into the depths of your aquatic being to find oneness in mind, body, and spirit. Our amazing yogini Sara is teaching an hour-long yoga class to bring fluidity, buoyancy, and weightlessness into your being. You're fucking pearl-fect, okay?! Sea you there!",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 43,
    "day": "Thursday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Alice's Birthday aka SOAK 😉 Stop by the Embassy warming fire and help make our youngest Ambassador\n Alice feel special as she celebrates her birthday with everyone who comes to SOAK just for her birthday. 🎂 Cotton candy, smiles, & wholesome memories to\n share.❤",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 44,
    "day": "Friday",
    "when": "11:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "Alright Alright's Deep & Dirty Dive: Hold onto your hats! Alright Alright serves up a piping hot plate of filthy Deep House beats that will keep you dancing 'til midnight. It's cute, it's dirty, it's exactly what you need.Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 45,
    "day": "Friday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "AQUATIC ANIMAL PARADE! Come ye fishes, ye\n sea-mammals, ye mer-people of the ocean blue! Join us as we parade about SOAK as singular school of\n sea-thangs.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 46,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Arachnophilia Protest — Not everyone loves spiders as much as we do, and while you may be wrong, we do support your right to free expression. Come check out our fine selection of protest signs and yell some\n anti-spider slogans at us any time our love for spiders needs to be kept in check (except for you Donny, you know what you did.)",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 47,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Arachnophilia Protest — Not everyone loves spiders as much as we do, and while you may be wrong, we do support your right to free expression. Come check out our fine selection of protest signs and yell some\n anti-spider slogans at us any time our love for spiders needs to be kept in check (except for you Donny, you know what you did.)",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 48,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Arachnophilia Protest — Not everyone loves spiders as much as we do, and while you may be wrong, we do support your right to free expression. Come check out our fine selection of protest signs and yell some\n anti-spider slogans at us any time our love for spiders needs to be kept in check (except for you Donny, you know what you did.)",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 49,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Are you ready for BILY this year?! Did you miss us last year?! Either way, come by for GF/VG Bakon served a delicious, colorful and creative way once again! Not hungry but want an ice cold, alcohol-free, drank and shade? Come hang! We’re looking forward to your visit, or maybe for a late-night sound stage Bakon attack!Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "BILY Camp",
    "area": "Riverside"
  },
  {
    "id": 50,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Are you ready for BILY this year?! Did you miss us last year?! Either way, come by for GF/VG Bakon served a delicious, colorful and creative way once again! Not hungry but want an ice cold, alcohol-free, drank and shade? Come hang! We’re looking forward to your visit, or maybe for a late-night sound stage Bakon attack!Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "BILY Camp",
    "area": "Riverside"
  },
  {
    "id": 51,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Are you ready for BILY this year?! Did you miss us last year?! Either way, come by for GF/VG Bakon served a delicious, colorful and creative way once again! Not hungry but want an ice cold, alcohol-free, drank and shade? Come hang! We’re looking forward to your visit, or maybe for a late-night sound stage Bakon attack!Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "BILY Camp",
    "area": "Riverside"
  },
  {
    "id": 52,
    "day": "Saturday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Are you ready to step into the realm of quick wit and banter at Softer Space? Curious about the multiplayer variant of the Questions game where players form two lines and ask questions without using statements?\n Ready to test your cleverness, stay sharp, outwit your opponents, and revel in the fast-paced and engaging atmosphere of this entertaining activity?",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 53,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-02:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Are you tired of being heckled? Do you miss your eccentric grandmother’s home? (Well, this probably isn’t exactly the same but close enough). Come by Home Sweet Dome at Camp Clusterfuck to snuggle with your friends in our open cuddle zone with surprise Tarot reading sessions during the day and surprise grilled cheese, praise, & words of encouragement at night.\n Seating, Vegetarian, Alcohol-Free",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 54,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-02:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Are you tired of being heckled? Do you miss your eccentric grandmother’s home? (Well, this probably isn’t exactly the same but close enough). Come by Home Sweet Dome at Camp Clusterfuck to snuggle with your friends in our open cuddle zone with surprise Tarot reading sessions during the day and surprise grilled cheese, praise, & words of encouragement at night.\n Seating, Vegetarian, Alcohol-Free",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 55,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-08:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Are you tired of being heckled? Do you miss your eccentric grandmother’s home? (Well, this probably isn’t exactly the same but close enough). Come by Home Sweet Dome at Camp Clusterfuck to snuggle with your friends in our open cuddle zone with surprise Tarot reading sessions during the day and surprise grilled cheese, praise, & words of encouragement at night.\n Seating, Vegetarian, Alcohol-Free",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 56,
    "day": "Thursday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Art installation - Rising from the depths every evening in tropical seas, the largest migration on Earth occurs in a bioluminecent ballet of Pelagic Resilience!",
    "where": "Pelagic Resilence",
    "area": "Main Field"
  },
  {
    "id": 57,
    "day": "Friday",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "ART!! Shrinky Dink Action Returns!! Now with Electric Ocean Themes!! Come make a pendant, some earrings, or some other interesting when shrunk thing.\n PG-13Seating, Loud Noise",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 58,
    "day": "Saturday",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "ART!! Shrinky Dink Action Returns!! Now with Electric Ocean Themes!! Come make a pendant, some earrings, or some other interesting when shrunk thing.\n PG-14Seating, Loud Noise",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 59,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "Arts & crafts hour! Come to Softer Space to make ocean-themed necklaces and magnets. Supplies\n provided for up to 40 participants—glass cabochons, sea creature art, magnets, metal hardware, glue, cord, etc.",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 60,
    "day": "Sunday",
    "when": "02:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Awkward Songs to Strip To: Improving children's songs and religious anthems since 2018.Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 61,
    "day": "Thursday",
    "when": "08:00 PM-10:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "BABE MIXER. Interested in playing in MOIST’S play space but need to find a buddy? Looking for sex-positive babes to connect with? Come to this mixer for some structured socialization to ease your way. REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 62,
    "day": "Thursday",
    "when": "09:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "Back to Werk: You did it! You helped build SOAK! Don't worry about taking off your hard hat and reflective vests because we're throwing a high-viz, super duper \"safe\" rager. Free drinks for anyone that helped build the city! Loud Noise, Flashing Lights, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 63,
    "day": "Friday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Backbends with your bestie! Stretches with your sweety! Join us for a partner yoga class over at Camp Clusterfuck and learn all the ways yoga can be even better together. Loud Noise",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 64,
    "day": "Friday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Bad Answers Booth: WWJDD? What Would Jason Dean Do? Come ask our resident Know-It-All your deepest, unanswered questions. The answers are bad, and the company is even worse. Best case scenario, you solve zero percent of your problems and have a shitty beer at our shitty bar, because this camp is super fucking\n over-rated.21+",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 65,
    "day": "Thursday",
    "when": "10:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Bad Erotic Fan Fiction Live Reading — Join us for some of the worst erotic fan fiction the internet has to offer!\n Come be regailed by salacious tales of your favorite IPs crossing realities just to have sex with other IPs.Seating, 18+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 66,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Bad Morning Fantasy Storytelling — Elijah will be serving breakfast tea and reading chapters from iconic and terrible high fantasy novels. Tropes abound!Seating, Alcohol-Free",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 67,
    "day": "Friday",
    "when": "07:30 PM-08:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Bad Romance — Do you like your bodice rippers read aloud with a heaping helping of ridiculous accents? Then we've got the event for you! We'll be reading from the worst selections of romance literature - bring your own or choose one of ours. Speakers and listeners welcome.",
    "where": "Camp Take the Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 68,
    "day": "Thursday",
    "when": "08:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "BAD SANTA PARTY: \"Are you Naughty or Nice?\" — Party with us at the North Pole for a Bad Santa Party. Jolly and Vixen activities. Come sit on Santa's lap and show him how good or bad you've been. Cocktails and music in a Winter Wonderland are provided. What kind of Soak are you wishing for this year?Loud Noise, Flashing Lights, Seating",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 69,
    "day": "Saturday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Banana bread feed! Come grab a slice of homemade banana bread just like grandma used to make.Vegetarian, Nuts, Alcohol-Free",
    "where": "Camp\n Mis-Behaving",
    "area": "Meadow"
  },
  {
    "id": 70,
    "day": "Sunday",
    "when": "12:00 PM-01:30 PM",
    "eventType": "Class/Workshop",
    "what": "BARE AS YOU DARE YOGA Come stretch, play, and connect through individual and partner poses attired in whatever state you desire. All levels encouraged and modifications will be offered. Please bring a yoga mat or towel. Solo and partnered participants welcome!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 71,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Other",
    "what": "Battery charging for all your devices, arts, and lights.Seating",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 72,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Other",
    "what": "Battery charging for all your devices, arts, and lights.Seating",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 73,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Other",
    "what": "Battery charging for all your devices, arts, and lights.Seating",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 74,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Other",
    "what": "Battery charging for all your devices, arts, and lights.Seating",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 75,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "BE THE SEX PARTY YOU WISH TO SEE IN THE\n WORLD! Dying to throw a sex party but don’t know where to start? Let us show you the way! We’ve got tons of experience throwing play parties, orgies, threesomes, and all kinds of other 3+ people sexy events. Bring your questions! REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 76,
    "day": "Saturday",
    "when": "06:00 PM-10:00 PM",
    "eventType": "Food/Beverage",
    "what": "BEAN BAR: Get ready to awaken your senses and indulge in rich flavors of beans like never before! Whether you're a dedicated bean enthusiast or simply curious to explore the culinary possibilities, our Bean Bar promises an unforgettable experience that will leave your tastebuds wanting more. From savory to sweet, we feature exciting snacks and beverages that showcase the versatility and flavor of beans.",
    "where": "Bean Here Now",
    "area": "Main Field"
  },
  {
    "id": 77,
    "day": "Saturday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "BEAN KARAOKE: Beans! Beans! The musical fruit! Are you a humanbean who loves to belt it out? Sing to your heart's content at one of our Bean-Themed Karaoke Nights. We will have classics like “Don’t Stop Beanlieving” by Journey, “REFRIED” by Aretha Franklin, “Dancing Bean” by ABBA, “Zombean” by The Cranberries and so many more! Come pick your favorite song from our carefully curated catalog.",
    "where": "Bean Here Now",
    "area": "Main Field"
  },
  {
    "id": 78,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "BEAN POOL: Open 24 hours a day with quiet hours from 3am-8am. This tranquil oasis will be full to the brim with dried beans. Relax as you float effortlessly on a sea of buoyant beans. Please note, lifeguards may not always be on duty so swim at your own risk. No diving and be sure to de-bean before continuing on to your next adventure",
    "where": "BEAN HERE NOW",
    "area": "Main Field"
  },
  {
    "id": 79,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "BEAN POOL: Open 24 hours a day with quiet hours from 3am-8am. This tranquil oasis will be full to the brim with dried beans. Relax as you float effortlessly on a sea of buoyant beans. Please note, lifeguards may not always be on duty so swim at your own risk. No diving and be sure to de-bean before continuing on to your next adventure",
    "where": "BEAN HERE NOW",
    "area": "Main Field"
  },
  {
    "id": 80,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "BEAN POOL: Open 24 hours a day with quiet hours from 3am-8am. This tranquil oasis will be full to the brim with dried beans. Relax as you float effortlessly on a sea of buoyant beans. Please note, lifeguards may not always be on duty so swim at your own risk. No diving and be sure to de-bean before continuing on to your next adventure",
    "where": "BEAN HERE NOW",
    "area": "Main Field"
  },
  {
    "id": 81,
    "day": "Thursday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "BEAN POOL: Open 24 hours a day with quiet hours from 3am-8am. This tranquil oasis will be full to the brim with dried beans. Relax as you float effortlessly on a sea of buoyant beans. Please note, lifeguards may not always be on duty so swim at your own risk. No diving and be sure to de-bean before continuing on to your next adventure.",
    "where": "Bean Here Now",
    "area": "Main Field"
  },
  {
    "id": 82,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "08:00 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "BEANS, to warm the soul. We will also be offering games with your delicious meals. Come early—once the pot is empty, we are done!Seating, Vegetarian",
    "where": "BowlovFarts of Broken Dreams",
    "area": "Forest Entry"
  },
  {
    "id": 83,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "08:00 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "BEANS, to warm the soul. We will also be offering games with your delicious meals. Come early—once the pot is empty, we are done!Seating, Vegetarian",
    "where": "BowlovFarts of Broken Dreams",
    "area": "Forest Entry"
  },
  {
    "id": 84,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "08:00 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "BEANS, to warm the soul. We will also be offering games with your delicious meals. Come early—once the pot is empty, we are done!Seating, Vegetarian",
    "where": "BowlovFarts of Broken Dreams",
    "area": "Forest Entry"
  },
  {
    "id": 85,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Bee One with the Flowers! Explore a microcosm of giant flowers! Try on handmade botanical hats/antennae and buzz around the installation to immerse yourself in the feeling of being a pollinator. Each flower is imbued with an essential oil, inviting you to “stop and smell the flowers.” The flowers are activated with light at sunset, creating an otherworldly effect.",
    "where": "Bee One With the Flowers",
    "area": "Forest Entry"
  },
  {
    "id": 86,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Bee One with the Flowers! Explore a microcosm of giant flowers! Try on handmade botanical hats/antennae and buzz around the installation to immerse yourself in the feeling of being a pollinator. Each flower is imbued with an essential oil, inviting you to “stop and smell the flowers.” The flowers are activated with light at sunset, creating an otherworldly effect.",
    "where": "Bee One With the Flowers",
    "area": "Forest Entry"
  },
  {
    "id": 87,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Bee One with the Flowers! Explore a microcosm of giant flowers! Try on handmade botanical hats/antennae and buzz around the installation to immerse yourself in the feeling of being a pollinator. Each flower is imbued with an essential oil, inviting you to “stop and smell the flowers.” The flowers are activated with light at sunset, creating an otherworldly effect.",
    "where": "Bee One With the Flowers",
    "area": "Forest Entry"
  },
  {
    "id": 88,
    "day": "Friday",
    "when": "11:00 AM-12:30 PM",
    "eventType": "Class/Workshop",
    "what": "Beginner-friendly acroyoga — Come learn how to do basic partnered yoga exercises in a gently guided setting. We will rotate through stretches and poses on a padded surface. If you can plank for 30 seconds, you can do acro!",
    "where": "Practical Pleasures",
    "area": "Forest Entry"
  },
  {
    "id": 89,
    "day": "Friday",
    "when": "02:30 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "BEST BI: THE BISEXUAL OLYMPICS. So you say\n you’re bisexual? Get your sexy butt over here and prove it! Compete with other bi-babes to show off how bisexually you can do a variety of tropes. A collab between Best Butt and MOIST, this is your chance for once-in-a-SOAK bisexual glory! REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 90,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Best Butt Open Mic: Come one come all and throw down some booty beats at the Best Butt Open Mic. We know you have singing, instrument playing, and booty shaking skills that need to be showcased outside of your shower. We'll have a guitar, but bring your own shower harmonicas.Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 91,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Bloody Mary Bar Crawl(z) for alcoholic / NA drinks and BM Stone Soup Support camps. Let’s CRAWL between stops. Vertically challenged people can support themselves and / or crawlers. Will bars offer a “wee bar” for crawlers to chin up to, nothing fancy, and not a tripping hazard? Daily stops / route will be listed at Kinky Fucking Camp.",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 92,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Bloody Mary Bar Crawl(z) for alcoholic / NA drinks and BM Stone Soup Support camps. Let’s CRAWL between stops. Vertically challenged people can support themselves and / or crawlers. Will bars offer a “wee bar” for crawlers to chin up to, nothing fancy, and not a tripping hazard? Daily stops / route will be listed at Kinky Fucking Camp.",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 93,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Bloody Mary Bar Crawl(z) for alcoholic / NA drinks and BM Stone Soup Support camps. Let’s CRAWL between stops. Vertically challenged people can support themselves and / or crawlers. Will bars offer a “wee bar” for crawlers to chin up to, nothing fancy, and not a tripping hazard? Daily stops / route will be listed at Kinky Fucking Camp.",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 94,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Bloody Mary Bar Crawl(z) for alcoholic / NA drinks and BM Stone Soup Support camps. Let’s CRAWL between stops. Vertically challenged people can support themselves and / or crawlers. Will bars offer a “wee bar” for crawlers to chin up to, nothing fancy, and not a tripping hazard? Daily stops / route will be listed at Kinky Fucking Camp.",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 95,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Blueberry pancakes and fresh pour—over coffee. Come hang out on Stoop, meet some neighbors, enjoy a blueberry pancake and a cup of coffee—affogato style if you'd like.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 96,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Blueberry pancakes and fresh pour—over coffee. Come hang out on Stoop, meet some neighbors, enjoy a blueberry pancake and a cup of coffee—affogato style if you'd like.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 97,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Blueberry pancakes and fresh pour—over coffee. Come hang out on Stoop, meet some neighbors, enjoy a blueberry pancake and a cup of coffee—affogato style if you'd like.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 98,
    "day": "Friday",
    "daily": "Everyday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "BOBABLIVION will satisfy your chewiest of cravings! Two flavors offered daily, such as classic milk tea, hibiscus coconut lychee jelly, Thai iced tea boba, Vietnamese coffee boba, and more. Bring your beverage vessel. Take a reusable fat straw with you for future slurping (while supplies last). Stay a while and play some bobagames or cozy up in our squish-filled Bobadome.Vegetarian, Vegan, Gluten Free, Nuts, Alcohol-Free",
    "where": "Bobablivion",
    "area": "Main Field"
  },
  {
    "id": 99,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "BOBABLIVION will satisfy your chewiest of cravings! Two flavors offered daily, such as classic milk tea, hibiscus coconut lychee jelly, Thai iced tea boba, Vietnamese coffee boba, and more. Bring your beverage vessel. Take a reusable fat straw with you for future slurping (while supplies last). Stay a while and play some bobagames or cozy up in our squish-filled Bobadome.Vegetarian, Vegan, Gluten Free, Nuts, Alcohol-Free",
    "where": "Bobablivion",
    "area": "Main Field"
  },
  {
    "id": 100,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "BOBABLIVION will satisfy your chewiest of cravings! Two flavors offered daily, such as classic milk tea, hibiscus coconut lychee jelly, Thai iced tea boba, Vietnamese coffee boba, and more. Bring your beverage vessel. Take a reusable fat straw with you for future slurping (while supplies last). Stay a while and play some bobagames or cozy up in our squish-filled Bobadome.Vegetarian, Vegan, Gluten Free, Nuts, Alcohol-Free",
    "where": "Bobablivion",
    "area": "Main Field"
  },
  {
    "id": 101,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "BOBABLIVION will satisfy your chewiest of cravings! Two flavors offered daily, such as classic milk tea, hibiscus coconut lychee jelly, Thai iced tea boba, Vietnamese coffee boba, and more. Bring your beverage vessel. Take a reusable fat straw with you for future slurping (while supplies last). Stay a while and play some bobagames or cozy up in our squish-filled Bobadome.Vegetarian, Vegan, Gluten Free, Nuts, Alcohol-Free",
    "where": "Bobablivion",
    "area": "Main Field"
  },
  {
    "id": 102,
    "day": "Friday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Class/Workshop",
    "what": "Body Painting Hangout. Come paint or be painted! Body painting supplies provided, as well as stencils and temporary tattoos for those less artistically inclined.\n Come decorate yourself and have fun!Seating",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 103,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Body Painting Roulette: Roll the dice to choose your design, then press your luck to see which body part will be adorned by our artists!",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 104,
    "day": "Friday",
    "daily": "Everyday",
    "when": "08:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Bottomless Tea — Stop by anytime to fill your cup with soothing herbal teas. We offer a rotating selection of chamomile, ginger turmeric, rooibos, and more.",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 105,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "08:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Bottomless Tea — Stop by anytime to fill your cup with soothing herbal teas. We offer a rotating selection of chamomile, ginger turmeric, rooibos, and more.",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 106,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "08:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Bottomless Tea — Stop by anytime to fill your cup with soothing herbal teas. We offer a rotating selection of chamomile, ginger turmeric, rooibos, and more.",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 107,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Bottomless Tea — Stop by anytime to fill your cup with soothing herbal teas. We offer a rotating selection of chamomile, ginger turmeric, rooibos, and more.",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 108,
    "day": "Sunday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Breakfast with Bey - Listen, Diva, Soldier, you don't want this Bootylicious breakfast to be The Best Thing You Never Had - So! Move Your Body, get in Formation, and indulge in Flawless french toast, Irreplaceable mimosas, (until we run out), and iconic tunes to dip it, bop it, twerk it, stop it... to! (if you wanna!) Love on Top optional.",
    "where": "Oregon Snail Trail",
    "area": "Riverside"
  },
  {
    "id": 109,
    "day": "Friday",
    "when": "08:30 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "BRINE BASH! Join us for a clamming good time celebrating the inaugural lighting of TRIFECTA, our new art piece! Work together to unlock the secret flames! Set sail on the maiden voyage over grassy seas on the INEVITABLE Pirate Ship. Or join the school of fish feeding off the warmth of underwater Flaming Sunflowers. Welcome to an ocean of possibilities!",
    "where": "Plunderground",
    "area": "Main Field"
  },
  {
    "id": 110,
    "day": "Thursday",
    "when": "07:00 PM-09:00 PM",
    "eventType": "Class/Workshop",
    "what": "Bring a cup. Try a sampling of meads while hearing the down-to-earth, plain English breakdown of the process. Jargon often gets in the way of enjoying or getting your feet in the door of a new hobby. Understand more about what you’re consuming or learn how to make some, there will be something here for you.Vegetarian, Gluten Free, 21+",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 111,
    "day": "Sunday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Bring your kiddo (and/or yourself) over to Babe Camp for an hour of educational kids music about marine biology and the natural world. All original music by Babe Camper Aaron Sea! There will be sciency songs about manatees, sea stars, how to keep an aquarium, and much more!\n Stick around for a kids dance party by DJ Hustleberry!Loud Noise",
    "where": "Babe Camp",
    "area": "Mezzanine"
  },
  {
    "id": 112,
    "day": "Friday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Bubbles & Bingo!!!!——- Who’s ready to play some Bingo?!? Mimosas available while they last!",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 113,
    "day": "Sunday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Bubbles & Bingo!!!!——- Who’s ready to play some Bingo?!? Mimosas available while they last!",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 114,
    "day": "Friday",
    "when": "10:00 PM-03:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "BUCKET NIGHTS - Come shake your bucket and let the sound waves of bass, hip-hop, house, and funk electrify your feet and soul at this full-tilt BUCKET Nights dance party! Join us ‘neath the luminous Disco Bucket, or try a surprise midnight treat served on the dance floor…from a BUCKET. BUCKETS will have DJ’s jamming. Find us under the BUCKET, ok?",
    "where": "BUCKETS",
    "area": "Upper Bowl"
  },
  {
    "id": 115,
    "day": "Friday",
    "when": "08:00 PM-10:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Burning Man Bar Trivia 4 categories of 10 questions each. All relating to Burning Man and regional events. Which team knows the most? Lots of prizes and swag for playing. Find us in the Main Field, near The Barn.21+",
    "where": "Black Rock Center for Unlearning",
    "area": "Main Field"
  },
  {
    "id": 116,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Butt BUDdies: We’ve got the buds, you’ve got the butts. Join our circle of yoga mats, smokeables, and edibles, and we’ll lead you through a sensual stretch that will enhance your psychoactive adventure ~limited cbd options available~Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 117,
    "day": "Sunday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Butt BUDdies: We’ve got the buds, you’ve got the butts. Join our circle of yoga mats, smokeables, and edibles, and we’ll lead you through a sensual stretch that will enhance your psychoactive adventure ~limited cbd options available~Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 118,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 PM-01:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Butt Hurt bar spicy bloody Mary's, Moscow mules, and n/a spicy shrubs. If we're there, were open!Vegetarian, Vegan, Gluten Free, 21+, Alcohol-Free",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  },
  {
    "id": 119,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 PM-01:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Butt Hurt bar spicy bloody Mary's, Moscow mules, and n/a spicy shrubs. If we're there, were open!Vegetarian, Vegan, Gluten Free, 21+, Alcohol-Free",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  },
  {
    "id": 120,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 PM-01:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Butt Hurt bar spicy bloody Mary's, Moscow mules, and n/a spicy shrubs. If we're there, were open!Vegetarian, Vegan, Gluten Free, 21+, Alcohol-Free",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  },
  {
    "id": 121,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 PM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Butt Hurt bar spicy bloody Mary's, Moscow mules, and n/a spicy shrubs. If we're there, were open!Vegetarian, Vegan, Gluten Free, 21+, Alcohol-Free",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  },
  {
    "id": 122,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Butt Jousting Tournament: Dust off your brackets because May Madness is here. The top butt jousters from the winter season gather for a winner take all tournament right here at SOAK. Remember, hands MUST remain on your ankles at all times. Sign-ups start at 3:45PM.Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 123,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Camp Clusterfuck invites you to play, practice, & perform during unscheduled time. The camp features: an aerial rig with rotating apparatus including silk, lyra, flying pole, straps, rope as well as a dance pole stage, two lollipop pole stages and a flying chandelier dance cage. 24/7 - not under supervision. If allowing kids to play, we ask parents to supervise.",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 124,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Camp Clusterfuck invites you to play, practice, & perform during unscheduled time. The camp features: an aerial rig with rotating apparatus including silk, lyra, flying pole, straps, rope as well as a dance pole stage, two lollipop pole stages and a flying chandelier dance cage. 24/7 - not under supervision. If allowing kids to play, we ask parents to supervise.",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 125,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-08:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Camp Clusterfuck invites you to play, practice, & perform during unscheduled time. The camp features: an aerial rig with rotating apparatus including silk, lyra, flying pole, straps, rope as well as a dance pole stage, two lollipop pole stages and a flying chandelier dance cage. 24/7 - not under supervision. If allowing kids to play, we ask parents to supervise.",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 126,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "Camp Clusterfuck presents! Drum roll please…. Octopussy! Octopussy is here to host an Aerial Silks workshop for all levels. Beginners will be prioritized. Come wrap yourself up in our finest silks.Loud Noise, Seating",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 127,
    "day": "Sunday",
    "when": "06:00 PM-08:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Casting Call: Polyjamorous Music Video! Have you ever dreamed of being a starlet in a music video? So have we! And YOU are already cast as an extra! Or even an on-camera talent! Glam up at Golden Hour and get a taste of the silver screen and maybe something a little sweeter... ;) (No, seriously, we are making a REAL music video. It's not a joke)Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 128,
    "day": "Friday",
    "daily": "Fri, Sat",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Chai @ Noon - Amazing chai, great people, awesome neighbors.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Camp Chai",
    "area": "Main Field"
  },
  {
    "id": 129,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Chai @ Noon - Amazing chai, great people, awesome neighbors.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Camp Chai",
    "area": "Main Field"
  },
  {
    "id": 130,
    "day": "Saturday",
    "daily": "Fri, Sat",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Chai @ Noon - Amazing chai, great people, awesome neighbors.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Camp Chai",
    "area": "Main Field"
  },
  {
    "id": 131,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Chai @ Noon - Amazing chai, great people, awesome neighbors.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Camp Chai",
    "area": "Main Field"
  },
  {
    "id": 132,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Chai @ Noon - Amazing chai, great people, awesome neighbors.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Camp Chai",
    "area": "Main Field"
  },
  {
    "id": 133,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Chai cafe @ Hypnodrome — Swing by for a cup of homemade masala chai tea. Take some for the road or stay, sip, relax, and enjoy the warm atmosphere. No frills, just nice vibes and delicious chai!Seating",
    "where": "Hypnodrome",
    "area": "Main Field"
  },
  {
    "id": 134,
    "day": "Sunday",
    "when": "04:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Chai Roulette: Chai your luck! Spin the wheel to win a prize... or punishmentSeating",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 135,
    "day": "Friday",
    "when": "08:30 PM-10:30 PM",
    "eventType": "Food/Beverage",
    "what": "Challenging Spirits Tasting: Be valiant with your mouth! Your own Gate Cluster crews have trod the far corners of the earth to retrieve these strongly flavored shots. Deep Woods? Funky Euro herbal stuff? Dare we say... Malört? Oh, what tastes we have to show you...Seating, 21+",
    "where": "Black Hole West",
    "area": "North Point Forest"
  },
  {
    "id": 136,
    "day": "Saturday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Check out a “Book” at the Human Library and have a 15-minute conversation with someone about their favorite subject! There will be lots of topics to choose from. Saturday 3-5pm at Caffeinated Catalyst. Drop in anytime! Want to volunteer to be a Book? Visit bit.ly/HumanLibrarySOAK2024Seating",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 137,
    "day": "Sunday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Chef Tonberry \"Sunday Sachet\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 138,
    "day": "Friday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Chef Tonberry's — Sizzlin Sounds",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 139,
    "day": "Saturday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Chef Tonberry's Smooth Cuts",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 140,
    "day": "Friday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Chicken and veggie soup feed — Bring your own bowl and enjoy some hot soup.Alcohol-Free",
    "where": "Camp\n Mis-Behaving",
    "area": "Meadow"
  },
  {
    "id": 141,
    "day": "Saturday",
    "when": "12:00 PM-01:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Circle Time! — Explore and express your inner child! Join our school of fish for Circle Time. We will read to you while you snack on goldfish and juice boxes. The classic '90s tale of \"The Rainbowfish\" will be featured, and we will finish in a bubble parade to show off our shiny selves! Seating",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 142,
    "day": "Sunday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Cold Brew with Cú: Drift slowly into Sunday with a cup of cold brew coffee while we journey with the selkie finding her lost voice in a viewing of the film “Song of the Sea.”",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 143,
    "day": "Saturday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come by for some BUBBLY ENTERTAINMENT. Enjoy our bubble machines, mimosas, and donut holes at the Great Green Room. Kids of all ages can play in our semi-enclosed kids area, while adults relax with a glass of bubbles, alcoholic or not.",
    "where": "The Great Green Room",
    "area": "Forest Entry"
  },
  {
    "id": 144,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come by Pampered Potions to indulge in our delectable concoctions crafted to tantalize the senses and nourish the soul. Partake in electrolyte and health-focused elixirs brewed with care to revitalize your spirit and invigorate your being.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 145,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come by Pampered Potions to indulge in our delectable concoctions crafted to tantalize the senses and nourish the soul. Partake in electrolyte and health-focused elixirs brewed with care to revitalize your spirit and invigorate your being.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 146,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come by Pampered Potions to indulge in our delectable concoctions crafted to tantalize the senses and nourish the soul. Partake in electrolyte and health-focused elixirs brewed with care to revitalize your spirit and invigorate your being.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 147,
    "day": "Friday",
    "when": "08:30 PM-09:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "Come catch Camp Clusterfuck’s annual circus performance featuring some of the most entertaining sea sirens and mariners that the “Electric Ocean” has to offer. Watch as we plunge into the abyss where the seven deadly sins merge with lore of the aquatic deep. \"Sins on the Sea\" will be a damp display of acrobatics, aerialists, flow performances, and so so much more. Stay after the show to take a dip in our amateur hour. Loud Noise, Flashing Lights, Seating",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 148,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "Come color at CAMP! Hang out and use our crayons to color limited edition, SOAK-themed coloring pages or take some coloring pages to go!",
    "where": "CAMP",
    "area": "Forest Entry"
  },
  {
    "id": 149,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Come color at CAMP! Hang out and use our crayons to color limited edition, SOAK-themed coloring pages or take some coloring pages to go!",
    "where": "CAMP",
    "area": "Forest Entry"
  },
  {
    "id": 150,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Come color at CAMP! Hang out and use our crayons to color limited edition, SOAK-themed coloring pages or take some coloring pages to go!",
    "where": "CAMP",
    "area": "Forest Entry"
  },
  {
    "id": 151,
    "day": "Friday",
    "when": "06:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Come drop your bags, check in and chill out at Baggage Check for some Karaoke! You've come so far! Stop here to rest, get your singing needs met, entertain us, or be entertained!",
    "where": "Baggage Check",
    "area": "Meadow"
  },
  {
    "id": 152,
    "day": "Friday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come enjoy an afternoon delight. A not-so-late-night long one. DSF and drinks!21+",
    "where": "DSF",
    "area": "Main Field"
  },
  {
    "id": 153,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come experience an underwater aquarium like no other. Find the deeper love for yourself and others within our illuminated dome.Flashing Lights",
    "where": "Currents of the Abyss",
    "area": "Forest Entry"
  },
  {
    "id": 154,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come experience an underwater aquarium like no other. Find the deeper love for yourself and others within our illuminated dome.Flashing Lights",
    "where": "Currents of the Abyss",
    "area": "Forest Entry"
  },
  {
    "id": 155,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come experience an underwater aquarium like no other. Find the deeper love for yourself and others within our illuminated dome.Flashing Lights",
    "where": "Currents of the Abyss",
    "area": "Forest Entry"
  },
  {
    "id": 156,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come find self-serve vegan hot cocoa to feel cozy and electrified before setting sail again.Vegan",
    "where": "Cracked Pot",
    "area": "Upper Bowl"
  },
  {
    "id": 157,
    "day": "Sunday",
    "daily": "Th, Fri, Sun",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come find self-serve vegan hot cocoa to feel cozy and electrified before setting sail again.Vegan",
    "where": "Cracked Pot",
    "area": "Upper Bowl"
  },
  {
    "id": 158,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come find self-serve vegan hot cocoa to feel cozy and electrified before setting sail again.Vegan",
    "where": "Cracked Pot",
    "area": "Upper Bowl"
  },
  {
    "id": 159,
    "day": "Friday",
    "when": "06:00 PM-08:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come get pickled! Happy Hour featuring our Pickle-tini, Pickleback Cocktail, or Spicy Pickle-rita, and other favorites to pickle your fancy.21+",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 160,
    "day": "Saturday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come get pickled! Happy Hour featuring our Pickle-tini, Pickleback Cocktail, or Spicy Pickle-rita, and other favorites to pickle your fancy.21+",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 161,
    "day": "Friday",
    "when": "12:00 PM-12:30 PM",
    "eventType": "Class/Workshop",
    "what": "Come get your blood pumping with heavy beats and light impact at our Hard Point Workout at the Softer Space. 30 minutes of things like push-ups, planks, and, if you want it, playful flogging, will burn off the night before and ignite the way for more. Think BDSM tabatas. Levels from light sweat to heavy breathing welcome!Alcohol-Free",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 162,
    "day": "Saturday",
    "when": "02:00 PM-02:30 PM",
    "eventType": "Class/Workshop",
    "what": "Come get your blood pumping with heavy beats and light impact at our Hard Point Workout at the Softer Space. 30 minutes of things like push-ups, planks, and, if you want it, playful flogging, will burn off the night before and ignite the way for more. Think BDSM tabatas. Levels from light sweat to heavy breathing welcome!Alcohol-Free",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 163,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Come get your morning started right with Mini-mimosas (special blend) and morning mantras. You’ll leave happy and motivated. Plus there will definitely be good conversation and lovely faces. Supplies may be limited.21+",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 164,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "eventType": "Food/Beverage",
    "what": "Come get your morning started right with Mini-mimosas (special blend) and morning mantras. You’ll leave happy and motivated. Plus there will definitely be good conversation and lovely faces. Supplies may be limited.21+",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 165,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Come get your morning started right with Mini-mimosas (special blend) and morning mantras. You’ll leave happy and motivated. Plus there will definitely be good conversation and lovely faces. Supplies may be limited.21+",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 166,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 PM-04:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come help us light up the night with your flame! The Cats in Space fire circle will have fire safeties and music provided, BYOF (bring your own fuel) encouraged.\n Please check in with a fire safety when you first arrive if you'd like to spin with us.Loud Noise, Seating",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 167,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 PM-06:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come help us light up the night with your flame! The Cats in Space fire circle will have fire safeties and music provided, BYOF (bring your own fuel) encouraged.\n Please check in with a fire safety when you first arrive if you'd like to spin with us.Loud Noise, Seating",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 168,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come help us light up the night with your flame! The Cats in Space fire circle will have fire safeties and music provided, BYOF (bring your own fuel) encouraged.\n Please check in with a fire safety when you first arrive if you'd like to spin with us.Loud Noise, Seating",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 169,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come help us light up the night with your flame! The Cats in Space fire circle will have fire safeties and music provided, BYOF (bring your own fuel) encouraged.\n Please check in with a fire safety when you first arrive if you'd like to spin with us.Loud Noise, Seating",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 170,
    "day": "Friday",
    "when": "06:00 PM-08:00 PM",
    "eventType": "Class/Workshop",
    "what": "Come make rosaries to help you worship whatever gets your party started! Supplies, tools and guidance are provided. Loud Noise, 18+",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 171,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Come monkey around with Camp Monkey Business and parade the streets of Soak. Bring your banana/monkey costumes and join in our bananagins. Come share the joy of going bananas. We split from camp and hope to bunch with as many bananas and monkeys as we can. Loud Noise",
    "where": "Camp Monkey Business",
    "area": "Upper Bowl"
  },
  {
    "id": 172,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come on down to Pickleodeon to fold some undersea origami. Books, designs, and lots of paper on offer so you can make and take your own folded ocean creatures home.",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 173,
    "day": "Sunday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come on down to Pickleodeon to fold some undersea origami. Books, designs, and lots of paper on offer so you can make and take your own folded ocean creatures home.",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 174,
    "day": "Friday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come on over to Snack Shack and share everyone’s favorite tube of meat! We have veggie/vegan options for those a little more weenie shy. Let our bun spreaders, wiener slingers, and squirters help satisfy your gluttonous tendencies with our evening service from\n 4-6pm everyday.Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 175,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come on over to Snack Shack and share everyone’s favorite tube of meat! We have veggie/vegan options for those a little more weenie shy. Let our bun spreaders, wiener slingers, and squirters help satisfy your gluttonous tendencies with our evening service from\n 4-6pm everyday.Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 176,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come on over to Snack Shack and share everyone’s favorite tube of meat! We have veggie/vegan options for those a little more weenie shy. Let our bun spreaders, wiener slingers, and squirters help satisfy your gluttonous tendencies with our evening service from\n 4-6pm everyday.Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 177,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come on over to Snack Shack and share everyone’s favorite tube of meat! We have veggie/vegan options for those a little more weenie shy. Let our bun spreaders, wiener slingers, and squirters help satisfy your gluttonous tendencies with our evening service from\n 4-6pm everyday.Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 178,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 PM-04:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Come out to the Meadow and chill with old and new friends in The Psychedahlia Garden! This interactive art piece features giant, whimsical flowers, colorful, vibration-activated spinning lights, and bench seating. Conceived and constructed by Tyler FuQua Creations, outside of Portland, Oregon. Flashing Lights, Seating",
    "where": "Psychedahlia Garden",
    "area": "Meadow"
  },
  {
    "id": 179,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 PM-04:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Come out to the Meadow and chill with old and new friends in The Psychedahlia Garden! This interactive art piece features giant, whimsical flowers, colorful, vibration-activated spinning lights, and bench seating. Conceived and constructed by Tyler FuQua Creations, outside of Portland, Oregon. Flashing Lights, Seating",
    "where": "Psychedahlia Garden",
    "area": "Meadow"
  },
  {
    "id": 180,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 PM-04:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Come out to the Meadow and chill with old and new friends in The Psychedahlia Garden! This interactive art piece features giant, whimsical flowers, colorful, vibration-activated spinning lights, and bench seating. Conceived and constructed by Tyler FuQua Creations, outside of Portland, Oregon. Flashing Lights, Seating",
    "where": "Psychedahlia Garden",
    "area": "Meadow"
  },
  {
    "id": 181,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 PM-04:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Come out to the Meadow and chill with old and new friends in The Psychedahlia Garden! This interactive art piece features giant, whimsical flowers, colorful, vibration-activated spinning lights, and bench seating. Conceived and constructed by Tyler FuQua Creations, outside of Portland, Oregon. Flashing Lights, Seating",
    "where": "Psychedahlia Garden",
    "area": "Meadow"
  },
  {
    "id": 182,
    "day": "Friday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come sit down, enjoy a lil’ beverage, and share your thoughts about your SOAK experience with a researcher from the Census crew who’s eager to document your story. Understanding unique individual experiences is crucial as we endeavor to provide the best possible experience to all SOAK attendees, so please stop on by and ask for Dad Vibes.Seating",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 183,
    "day": "Saturday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come sit down, enjoy a lil’ beverage, and share your thoughts about your SOAK experience with a researcher from the Census crew who’s eager to document your story. Understanding unique individual experiences is crucial as we endeavor to provide the best possible experience to all SOAK attendees, so please stop on by and ask for Dad Vibes.Seating",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 184,
    "day": "Friday",
    "daily": "Everyday",
    "when": "11:59 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come to Synthesthesia and witness the trees and clouds come to life! You will see sounds and hear colors at our performance with live music and interactive sound sculptures!Loud Noise, Flashing Lights, Seating",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 185,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:59 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come to Synthesthesia and witness the trees and clouds come to life! You will see sounds and hear colors at our performance with live music and interactive sound sculptures!Loud Noise, Flashing Lights, Seating",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 186,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come to Synthesthesia and witness the trees and clouds come to life! You will see sounds and hear colors at our performance with live music and interactive sound sculptures!Loud Noise, Flashing Lights, Seating",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 187,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "11:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Come to Synthesthesia and witness the trees and clouds come to life! You will see sounds and hear colors at our performance with live music and interactive sound sculptures!Loud Noise, Flashing Lights, Seating",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 188,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Come travel through Sea, Sky, & Space. Three rooms of immersive creation, offering areas to view art through connecting rooms with psychedelic motifs and invitations to play. Lounge in the ocean and jive with jellyfish. Soar through pink skies and cuddle clouds. Step through a cosmic portal and let your eyes shine from the galactic paintings that glow in space.",
    "where": "Cosmic Portal: From Sea to Space",
    "area": "Forest Entry"
  },
  {
    "id": 189,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Come travel through Sea, Sky, & Space. Three rooms of immersive creation, offering areas to view art through connecting rooms with psychedelic motifs and invitations to play. Lounge in the ocean and jive with jellyfish. Soar through pink skies and cuddle clouds. Step through a cosmic portal and let your eyes shine from the galactic paintings that glow in space.",
    "where": "Cosmic Portal: From Sea to Space",
    "area": "Forest Entry"
  },
  {
    "id": 190,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Come travel through Sea, Sky, & Space. Three rooms of immersive creation, offering areas to view art through connecting rooms with psychedelic motifs and invitations to play. Lounge in the ocean and jive with jellyfish. Soar through pink skies and cuddle clouds. Step through a cosmic portal and let your eyes shine from the galactic paintings that glow in space.",
    "where": "Cosmic Portal: From Sea to Space",
    "area": "Forest Entry"
  },
  {
    "id": 191,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Come travel through Sea, Sky, & Space. Three rooms of immersive creation, offering areas to view art through connecting rooms with psychedelic motifs and invitations to play. Lounge in the ocean and jive with jellyfish. Soar through pink skies and cuddle clouds. Step through a cosmic portal and let your eyes shine from the galactic paintings that glow in space.",
    "where": "Cosmic Portal: From Sea to Space",
    "area": "Forest Entry"
  },
  {
    "id": 192,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Come try a selection of Irish whisky and enjoy the soothing sounds of Weird Al. Seating",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 193,
    "day": "Saturday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Cosmic Margaritaville: During this past year’s burn, the world lost a legend—Jimmy James William Buffet. Lover of margaritas & parrots, takin’ it easy, and relaxing on a beach with the sand between his toes and a drink in his hand. Break out your Hawaiian shirts because we’re throwing a Kaleidoscopic Space Beach Jimmy Buffet Tribute Dance Party.Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 194,
    "day": "Sunday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Costco Customer Appreciation Day — For all returning and new club members, bring your souls and your appetites! We'll be serving our famous samples and hot dogs (now with vegan and gluten-free options!) while providing a place to mingle with fellow human(ish) customers.Vegetarian, Vegan, Gluten Free",
    "where": "Costco Soulmate Trading Outlet",
    "area": "Riverside"
  },
  {
    "id": 195,
    "day": "Friday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Craft Soup! Roll up to the craft bar. Get served a bowl of random craft components, with a side of cutting tools and adhesives. Apply your creativity. Leave with a craft!\n Teen-run, everyone-friendly, craft at your own risk.Seating",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 196,
    "day": "Sunday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Craft Soup! Roll up to the craft bar. Get served a bowl of random craft components, with a side of cutting tools and adhesives. Apply your creativity. Leave with a craft!\n Teen-run, everyone-friendly, craft at your own risk.Seating",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 197,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "Create a glowing jellyfish and tropical fish to travel with you on your journey through the Electric Ocean! Craft your unique glowing masterpieces and marvel at the mesmerizing spectacles you've brought to life. By the end, you'll leave with a stick to carry and display your new glowing, radiant tokens of your creativity and the magical wonders of the sea! Seating",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 198,
    "day": "Friday",
    "when": "12:00 PM-12:40 PM",
    "eventType": "Class/Workshop",
    "what": "Cult Yoga (High Vibration People only) — Join other beautiful ethereal souls who have been called by the universe to align their minds and bodies as an aspiring not-a-cult leader guides you through only the very best savasanas. Praise and acknowledgment for Yoga Teacher encouraged but not required. BYO matVegan",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 199,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "CupCakke! Cum frost our slutty lil cupcakes. We promise they deserve it. We might even let you eat one, but only if you're very, very good. (All vegan, GF available. This event is an intentionally Queer Space, but all are welcome!)Seating, Vegetarian, Vegan, Gluten Free, 18+",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 200,
    "day": "Sunday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Dance Church: Bucket All to Heck—We will be your deacon of dance as we play tracks of booty-shaking devotion while lifting your spirits—demons be gone!! EVERY BODY WELCOME AT DANCE CHURCH, and\n remember, BUCKET ALL TO HECK! Bring your best Bucket Confessionals to share and watch out for Bucket Communion.",
    "where": "BUCKETS",
    "area": "Upper Bowl"
  },
  {
    "id": 201,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Decorative mask-making — Invite your favorite divinity, woodland creature, elemental spirit, or other theatrical muse. Together, we will embody our creative souls as we craft masks to don at the burns and indulge in an additional layer of drama and play. Seating",
    "where": "Practical Pleasures",
    "area": "Forest Entry"
  },
  {
    "id": 202,
    "day": "Friday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Deep House Takeoff with BobWire: Kick off your Friday night with BobWire! Feel the stress melt away with uplifting Deep House beats that will get your feet moving and your mood soaring.Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 203,
    "day": "Sunday",
    "when": "11:11 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Deep Sea Storytime—We will be reading some of our favorite ocean-themed children's books. Kids and kids at heart are all welcome.",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 204,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Den Zolo's Lil Wizards Daycare! Send the kiddos over to the toilet bowl for an exploration modern wizardry. Under Den Zolo's enchanting guidance, children unlock the mystical secrets of 21st-century wizardry, blending the ancient art of sorcery with the modern magic of touchscreen technology. With a sprinkle of imagination and a dash of glitter, every day at Den Zolo's is a whimsical adventure into the realm of playful enchantment. Loud Noise, Flashing Lights",
    "where": "Super Happy Invincible Titanic (The Toilet Bowl)",
    "area": "Upper Bowl"
  },
  {
    "id": 205,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Den Zolo's Lil Wizards Daycare! Send the kiddos over to the toilet bowl for an exploration modern wizardry. Under Den Zolo's enchanting guidance, children unlock the mystical secrets of 21st-century wizardry, blending the ancient art of sorcery with the modern magic of touchscreen technology. With a sprinkle of imagination and a dash of glitter, every day at Den Zolo's is a whimsical adventure into the realm of playful enchantment. Loud Noise, Flashing Lights",
    "where": "Super Happy Invincible Titanic (The Toilet Bowl)",
    "area": "Upper Bowl"
  },
  {
    "id": 206,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Den Zolo's Lil Wizards Daycare! Send the kiddos over to the toilet bowl for an exploration modern wizardry. Under Den Zolo's enchanting guidance, children unlock the mystical secrets of 21st-century wizardry, blending the ancient art of sorcery with the modern magic of touchscreen technology. With a sprinkle of imagination and a dash of glitter, every day at Den Zolo's is a whimsical adventure into the realm of playful enchantment. Loud Noise, Flashing Lights",
    "where": "Super Happy Invincible Titanic (The Toilet Bowl)",
    "area": "Upper Bowl"
  },
  {
    "id": 207,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Den Zolo's Lil Wizards Daycare! Send the kiddos over to the toilet bowl for an exploration modern wizardry. Under Den Zolo's enchanting guidance, children unlock the mystical secrets of 21st-century wizardry, blending the ancient art of sorcery with the modern magic of touchscreen technology. With a sprinkle of imagination and a dash of glitter, every day at Den Zolo's is a whimsical adventure into the realm of playful enchantment. Loud Noise, Flashing Lights",
    "where": "Super Happy Invincible Titanic (The Toilet Bowl)",
    "area": "Upper Bowl"
  },
  {
    "id": 208,
    "day": "Thursday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Class/Workshop",
    "what": "DIRTY TALK WORKSHOP Discover the art of seductive communication at our Dirty Talk Workshop! Explore passion and intimacy through expert guidance in a safe, playful environment. Spice up your relationships and unleash your desires!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 209,
    "day": "Thursday",
    "when": "09:00 PM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Disco Dance Party- DTF will be spinning those funky beets to set your weekend off right!Loud Noise, Flashing Lights",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 210,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Discover the magic of face paint! Unleash your inner child with fun face paint designs courtesy of the artists at Camp Touchy Feely. We will specialize in ocean creatures in celebration of the Electric Ocean theme. The event ends promptly after one hour. Brought to you by Camp Touchy Feely, a camp specializing in sensory objects and fidget toys.Seating, Alcohol-Free",
    "where": "Touchy Feely",
    "area": "Forest Entry"
  },
  {
    "id": 211,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Dive into the depths of the electric ocean where a silent behemoth looms, its form an enigmatic silhouette against the void. Entranced by the glow of an otherworldly light, you’re pulled closer, enveloped in an ethereal dance between curiosity and apprehension. What mysteries lie in the depths? Flashing Lights, Seating",
    "where": "Glow-Getter",
    "area": "Upper Bowl"
  },
  {
    "id": 212,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Dive into the depths of the electric ocean where a silent behemoth looms, its form an enigmatic silhouette against the void. Entranced by the glow of an otherworldly light, you’re pulled closer, enveloped in an ethereal dance between curiosity and apprehension. What mysteries lie in the depths? Flashing Lights, Seating",
    "where": "Glow-Getter",
    "area": "Upper Bowl"
  },
  {
    "id": 213,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Dive into the depths of the electric ocean where a silent behemoth looms, its form an enigmatic silhouette against the void. Entranced by the glow of an otherworldly light, you’re pulled closer, enveloped in an ethereal dance between curiosity and apprehension. What mysteries lie in the depths? Flashing Lights, Seating",
    "where": "Glow-Getter",
    "area": "Upper Bowl"
  },
  {
    "id": 214,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Dive into the depths of the electric ocean where a silent behemoth looms, its form an enigmatic silhouette against the void. Entranced by the glow of an otherworldly light, you’re pulled closer, enveloped in an ethereal dance between curiosity and apprehension. What mysteries lie in the depths? Flashing Lights, Seating",
    "where": "Glow-Getter",
    "area": "Upper Bowl"
  },
  {
    "id": 215,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Dj Diplo Dancy Drinky Spanky Party. Loud Noise, Vegetarian, Vegan, 21+, Alcohol-Free",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  },
  {
    "id": 216,
    "day": "Saturday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Do you have a love of running? Do you at least have a tolerance for running? How about a nice walk? What if I told you it starts and ends at a camp with coffee? Don’t party too hard Friday night and wake up early on Saturday for a 5K!! Kids' run (less than 1 mile) beforehand — all are welcome!",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 217,
    "day": "Saturday",
    "when": "10:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Do you want to learn to play chess? As a beginner, do you want to become a challenging player? As an expert, do you want little challenge defeating an opponent?\n Regardless of your skill level, come play a game of chess with an avid amateur and expert conversationalist; you choose the topic and enjoy a refreshment while you chat and chess.",
    "where": "Baggage Check",
    "area": "Meadow"
  },
  {
    "id": 218,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Do your ears need a break? Relax in the\n sound-dampened lounge of Softer Space! Our insulated tent really cuts down on outside noise. Enjoy a delicious cup of tea and a cookie and watch the art on the walls change as the lighting cycles through different colors. A beautiful spot for a snack/drink while you rest your ears.Seating",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 219,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:30 PM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Do your ears need a break? Relax in the\n sound-dampened lounge of Softer Space! Our insulated tent really cuts down on outside noise. Enjoy a delicious cup of tea and a cookie and watch the art on the walls change as the lighting cycles through different colors. A beautiful spot for a snack/drink while you rest your ears.Seating",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 220,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:30 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Do your ears need a break? Relax in the\n sound-dampened lounge of Softer Space! Our insulated tent really cuts down on outside noise. Enjoy a delicious cup of tea and a cookie and watch the art on the walls change as the lighting cycles through different colors. A beautiful spot for a snack/drink while you rest your ears.Seating",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 221,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Do your ears need a break? Relax in the\n sound-dampened lounge of Softer Space! Our insulated tent really cuts down on outside noise. Enjoy a delicious cup of tea and a cookie and watch the art on the walls change as the lighting cycles through different colors. A beautiful spot for a snack/drink while you rest your ears.Seating",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 222,
    "day": "Saturday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Don't Take it Fursonal: Badly Drawn Fursonas — come sit, enjoy a cup of tea, and have your fursona badly sketched by actual artists, who don't draw furries. If you don't have a fursona, we'll assign you one!",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 223,
    "day": "Saturday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Down to Fold? Come learn how to make an origami paper crane! Can we make 1000?",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 224,
    "day": "Sunday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Down to Fold? Come learn how to make an origami paper dragon!",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 225,
    "day": "Saturday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Dr. Kidney and Nurse Slayer will perform the exciting and thrilling \"World Famous 4 handed Swedish Fish Massage\" TM",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 226,
    "day": "Sunday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Dr. Kidney and Nurse Slayer will perform the exciting and thrilling \"World Famous 4 handed Swedish Fish Massage\" TM",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 227,
    "day": "Saturday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Drift off into bliss. Silky? Aromatic? Sweet? Prickly? Tickly? You decide. Sit back and let us treat you to a feast of sensory delights. Get your day started right—your Future Self will thank you.Seating",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 228,
    "day": "Sunday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Drift off into bliss. Silky? Aromatic? Sweet? Prickly? Tickly? You decide. Sit back and let us treat you to a feast of sensory delights. Get your day started right—your Future Self will thank you.Seating",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 229,
    "day": "Saturday",
    "when": "04:20 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Drive By Towel Whipping offered, ass in you drive by KFC. No time for a full scene? Pep your step or petal the metal @ 5mph, SLOW DOWN! Don't forget to bring a Towel(ie) for this event on International Towel Day. Make\n / use a Rat Tail whip. Barrel lay, or take it like a post. Spankings and paddlings offered. 21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 230,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "Drop into Baggage Check camp to assemble and color a little light-up fishy friend to school with as you wander. No crafting skills required. All ages welcome (with grownup supervision).",
    "where": "Baggage Check",
    "area": "Meadow"
  },
  {
    "id": 231,
    "day": "Saturday",
    "when": "09:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "Drum n' Butts: Attention all drum and bass enthusiasts and twerk aficionados! We’re cranking up the subwoofers for some beats that'll get your booty bouncing and your body grooving so make sure you don't miss out on this dance floor.Loud Noise, Flashing Lights, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 232,
    "day": "Friday",
    "when": "01:00 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "Dungeons and Dragons - Always been a little\n bard-curious? Do you think Swords are pretty cool? Well then, come learn how to play Dungeons and Dragons in a friendly environment! Dice and materials will be provided, inexperienced players preferred. Limit 6 seats per session.",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 233,
    "day": "Thursday",
    "when": "01:00 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "Dungeons and Dragons - Always been a little\n bard-curious? Do you think Swords are pretty cool? Well then, come learn how to play Dungeons and Dragons in a friendly environment! Dice and materials will be provided, inexperienced players preferred. Limit 6 seats per session.",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 234,
    "day": "Saturday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "During this 1-hr workshop, our resident VR/AR artist will demonstrate how they work creating virtual art and landscapes.",
    "where": "Dusty Bunnies",
    "area": "Forest Entry"
  },
  {
    "id": 235,
    "day": "Friday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "Elation Station Workshop: Come learn how to interact with the electronic musical instruments of the Elation Station. All skill levels welcome.",
    "where": "Elation Station",
    "area": "Mezzanine"
  },
  {
    "id": 236,
    "day": "Saturday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "Elation Station Workshop: Come learn how to interact with the electronic musical instruments of the Elation Station. All skill levels welcome.",
    "where": "Elation Station",
    "area": "Mezzanine"
  },
  {
    "id": 237,
    "day": "Friday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Electro Booty: Are you kink-curious-curious? Did you thrive in the late 2000s heydey of electro-house? Stop by for a high voltage treat from a violet wand and stay for the high-energy dancing. ZAP ZAP!Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 238,
    "day": "Saturday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Electro Swing — come groove to some electro swing beats on our dance floor! Loud Noise",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 239,
    "day": "Saturday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Electro-Swing Speakeasy: Come get fancy and dance to electro-swing while drinking our craft beer and cider with DJ Rugcutter (AKA Ian Dustrial). Formal wear encouraged.Loud Noise, Flashing Lights, 21+",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 240,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Electrolyte Bar: Welcome to the oasis. Rehydrate that beautiful bod of yours with some essential minerals. Your Future Self will thank you.Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 241,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Electrolyte Bar: Welcome to the oasis. Rehydrate that beautiful bod of yours with some essential minerals. Your Future Self will thank you.Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 242,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Electrolyte Bar: Welcome to the oasis. Rehydrate that beautiful bod of yours with some essential minerals. Your Future Self will thank you.Alcohol-Free",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 243,
    "day": "Friday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "EmOceanal Sound Bath and 8-Tentacle Massages: join us for an adoration of cosmic bodies featuring singing bowls and 8-handed massages (with optional tentacles for cupping). Semi-nude permitted, please keep bottoms on. 21+",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 244,
    "day": "Saturday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "EmOceanal Sound Bath and 8-Tentacle Massages: join us for an adoration of cosmic bodies featuring singing bowls and 8-handed massages (with optional tentacles for cupping). Semi-nude permitted, please keep bottoms on. 21+",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 245,
    "day": "Sunday",
    "when": "09:00 PM-11:55 PM",
    "eventType": "Experience/Interactive Art",
    "what": "End of the World Party — Don your peachy best and revel with us in blossom’s eternal embrace as we welcome the apocalypse! Indulge in juicy delights, dance under twilight skies, and join in our sacred rites as we prepare ourselves in sacrifice to meet the Eternal Harvester of the Hallowed Grove. (After Temple Burn.)",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 246,
    "day": "Saturday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann — Folk Rock Power Hour",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 247,
    "day": "Friday",
    "daily": "Everyday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann — Weird Afternoon",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 248,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann — Weird Afternoon",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 249,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann — Weird Afternoon",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 250,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann — Weird Afternoon",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 251,
    "day": "Thursday",
    "when": "02:00 AM-03:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann \"Which House?\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 252,
    "day": "Friday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Erich Zann's Freestyle Favorites",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 253,
    "day": "Friday",
    "when": "09:00 PM-10:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "EROS THEATER - LIVE PORN Next Level Voyeurism & Exhibitionism Do you like to watch other people having sex on a screen? Is it even better watching in person? Come and find out at EROS THEATER - LIVE PORN!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 254,
    "day": "Friday",
    "when": "07:00 PM-08:30 PM",
    "eventType": "Class/Workshop",
    "what": "EROTIC FIRE PLAY See chasing figure 8s of flame racing along a back! See fire wands drumming along a thigh! Watch us light a fuse and the -whoosh- of flash cotton! We'll discuss some basics of erotic fire play, talk about safety protocols, and conduct some demos! The modalities will include hair mousse, fire wands, and flash cotton.",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 255,
    "day": "Saturday",
    "when": "11:00 PM-11:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Ever wanted to play with a fire hose? Or take a selfie with a firefighter? Come help the Fire Safety team drain the drop tank and wet down the hillside after the MBS perimeter drops. No experience necessary. Please be capable of understanding and following directions.",
    "where": "The upper bowl, above the MBS",
    "area": "Upper Bowl"
  },
  {
    "id": 256,
    "day": "Sunday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Experience spiritual nourishment at the Church of Cheese Sunday Mass. Join us for a sacred gathering where the aroma of cheese fills the air and the soul finds solace. Embrace the divine harmony of faith and flavor as we come together to worship, reflect, and savor the goodness of community and cheese",
    "where": "Church of Cheese",
    "area": "Main Field"
  },
  {
    "id": 257,
    "day": "Friday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Extraterrestrial Glow: Alien Blacklight Body Painting — Journey to the stars, where bodies become canvases and luminescent paint transforms ordinary humans into otherworldly beings.",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 258,
    "day": "Saturday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Family Spaghetti dinner! Because when you're here, you're family (as long as you're not uncle Tim, fuck that guy). BYOBowl. Vegan and GF options available. Vegan, Gluten Free",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 259,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Feeling a bit low on energy? Need a little pick me up? Come get gassed up with us at the Gas Station! This support camp for the Parking department offers different fuel varieties! If there's an attendant at the bar, we are happy to fill your tank. Come say hi! Loud Noise, Flashing Lights, Seating, 21+",
    "where": "The Gas Station",
    "area": "Somewhere"
  },
  {
    "id": 260,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Feeling a bit low on energy? Need a little pick me up? Come get gassed up with us at the Gas Station! This support camp for the Parking department offers different fuel varieties! If there's an attendant at the bar, we are happy to fill your tank. Come say hi! Loud Noise, Flashing Lights, Seating, 21+",
    "where": "The Gas Station",
    "area": "Somewhere"
  },
  {
    "id": 261,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Feeling a bit low on energy? Need a little pick me up? Come get gassed up with us at the Gas Station! This support camp for the Parking department offers different fuel varieties! If there's an attendant at the bar, we are happy to fill your tank. Come say hi! Loud Noise, Flashing Lights, Seating, 21+",
    "where": "The Gas Station",
    "area": "Somewhere"
  },
  {
    "id": 262,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Feeling a bit low on energy? Need a little pick me up? Come get gassed up with us at the Gas Station! This support camp for the Parking department offers different fuel varieties! If there's an attendant at the bar, we are happy to fill your tank. Come say hi! Loud Noise, Flashing Lights, Seating, 21+",
    "where": "The Gas Station",
    "area": "Somewhere"
  },
  {
    "id": 263,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Feeling lucky? Join us and win FABULOUS PRIZES at Chameleon Camp Bingo! This is for all ages. Come for the FABULOUS PRIZES and leave with new friends.Alcohol-Free",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 264,
    "day": "Friday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Class/Workshop",
    "what": "Feeling wheely crafty? Roll on over and learn to make a recycled bicycle spoke bracelet with us. Craft and customize your own stainless steel bracelet from clean, pre-loved bike parts. Bling it out or keep it minimal, makes a strong steel clip/carabiner as well.",
    "where": "Cirque De Licious!",
    "area": "Main Field"
  },
  {
    "id": 265,
    "day": "Saturday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Class/Workshop",
    "what": "Feeling wheely crafty? Roll on over and learn to make a recycled bicycle spoke bracelet with us. Craft and customize your own stainless steel bracelet from clean, pre-loved bike parts. Bling it out or keep it minimal, makes a strong steel clip/carabiner as well.",
    "where": "Cirque De Licious!",
    "area": "Main Field"
  },
  {
    "id": 266,
    "day": "Thursday",
    "when": "05:00 PM-06:30 PM",
    "eventType": "Class/Workshop",
    "what": "Fiber Arts Class: Learn to crochet cute critters to join you on your journeys. No experience necessary.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 267,
    "day": "Sunday",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "Fiber Arts Class: Learn to crochet simple flowers—great for accentuating your cute outfit or giving to a beautiful stranger. No experience necessary.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 268,
    "day": "Friday",
    "when": "08:00 PM-08:50 PM",
    "eventType": "Class/Workshop",
    "what": "Fire Circle Safety Fundamentals — Come learn how to help maintain a safe space in the fire circle. We will go over fire circle layout and etiquette, safety equipment, responsibilities of the fire safety person on duty, types of fuels & fuel depot procedures, what types of clothing are more or less fire safe, fire dancer responsibilities, extinguishing techniques, & types of burns along with their appropriate first aid.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 269,
    "day": "Friday",
    "when": "11:30 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Fire play show. Truly lighting the body on fire. Come see how the art of fire play is so amazing . See the beautiful flames dance across the body. 18+",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 270,
    "day": "Friday",
    "when": "11:00 PM-11:30 PM",
    "eventType": "Class/Workshop",
    "what": "Fire Safety. Setting up a safe play space.18+",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 271,
    "day": "Saturday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "FISHING for OCEAN LIFE — Ages 0-12. Grab a pole and fish for Sea Life plushies and Electric Ocean tattoos. Non-professional anglers only. No batteries required. No batteries included. Expensive high speed deep sea fishing boat not included. Consider staying for the Merfolk Photography Booth at 3pm. Family and ADA friendly.",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 272,
    "day": "Sunday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "FISHING for OCEAN LIFE — Ages 0-12. Grab a pole and fish for Sea Life plushies and Electric Ocean tattoos. Non-professional anglers only. No batteries required. No batteries included. Expensive high speed deep sea fishing boat not included. Consider staying for the Merfolk Photography Booth at 3pm. Family and ADA friendly.",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 273,
    "day": "Saturday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Class/Workshop",
    "what": "Flogs & Dogs — Ever wonder what’s in a hotdog?? Ever wanted to make your very own flogger?? Well come on down to Shack Shack for Flogs & Dogs! We will be serving from 4-6pm and also be teaching you how to make your very own leather flogger.Loud Noise, Seating, 18+",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 274,
    "day": "Saturday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "FLORAL TEA TIME — Flowers are pretty to look at and all, but what if you put some floral essence INSIDE OF YOU?! Help yourself to a splash of our fleur du jour (probably chamomile, jasmine, rose, or hibiscus, etc.) BYO cup. You never leave camp without your cup, right? Right?!",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 275,
    "day": "Sunday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "FLORAL TEA TIME — Flowers are pretty to look at and all, but what if you put some floral essence INSIDE OF YOU?! Help yourself to a splash of our fleur du jour (probably chamomile, jasmine, rose, or hibiscus, etc.) BYO cup. You never leave camp without your cup, right? Right?!",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 276,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "FLOWER BOWER BOUTIQUE — Deflower us! ...by accessorizing yourself with fabulous clip-on flowers and other faux floral creations at our 24-hour self-service boutique. Then come back at night and let it mesmerize you. Let. It. Floral fashion consultants available sporadically.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 277,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "FLOWER BOWER BOUTIQUE — Deflower us! ...by accessorizing yourself with fabulous clip-on flowers and other faux floral creations at our 24-hour self-service boutique. Then come back at night and let it mesmerize you. Let. It. Floral fashion consultants available sporadically.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 278,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "FLOWER BOWER BOUTIQUE — Deflower us! ...by accessorizing yourself with fabulous clip-on flowers and other faux floral creations at our 24-hour self-service boutique. Then come back at night and let it mesmerize you. Let. It. Floral fashion consultants available sporadically.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 279,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "07:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "FLOWER BOWER BOUTIQUE — Deflower us! ...by accessorizing yourself with fabulous clip-on flowers and other faux floral creations at our 24-hour self-service boutique. Then come back at night and let it mesmerize you. Let. It. Floral fashion consultants available sporadically.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 280,
    "day": "Sunday",
    "when": "04:00 PM-04:50 PM",
    "eventType": "Class/Workshop",
    "what": "Flowersticks Knowledge Drop — Come level up your flowersticks game, all skill levels welcome. Some flowersticks will be available to borrow, but bring your own if available to you.\n To spin Flowersticks / Dance with the laws of physics / Delightful chaos.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 281,
    "day": "Sunday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Four on the Floor \"Dark Wavish\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 282,
    "day": "Friday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor — Ambient Ambien",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 283,
    "day": "Sunday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor — Found Sounds",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 284,
    "day": "Saturday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor — New Wavish",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 285,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "02:00 AM-03:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor \"Disco's Revenge\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 286,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "02:00 AM-03:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor \"Disco's Revenge\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 287,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "01:00 AM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Four to the Floor \"Disco's Revenge\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 288,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Fresh Squeezed Bullshit! We bought a juice press on Craigslist and need to justify our purchase. Let us make you a fresh-squeezed mimosa or sparkly juice (NA).\n Available from morning-ish to when we run out of oranges.",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 289,
    "day": "Sunday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Fresh Squeezed Bullshit! We bought a juice press on Craigslist and need to justify our purchase. Let us make you a fresh-squeezed mimosa or sparkly juice (NA).\n Available from morning-ish to when we run out of oranges.",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 290,
    "day": "Friday",
    "when": "07:00 PM-09:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Friday Night Fright: Don your darkest duds and come get spooky on our dance floor while DJ Crush Hazard spins only the most chilling and thrilling Goth, Industrial, and other deep cuts from the dark side. Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 291,
    "day": "Friday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Fuchsia Bot is an AI DJ / musical distortion experience / agent of chaos / fun for the whole family! Mess around with chopped up, mixed, and mashed up bits of music, no experience required, weird results guaranteed!",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 292,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Funk at noon — Come, freak da funk at noon! Oh so soon It’s Jah Funkey grooove 4 ya spunky moves! In the key of free and happy! Be yourself and do the do, woohoo! Hey now, alright, express yourself! Bada bing, bada boom! — Zoom and savor a hunk-a-funk at noon — Disco Peace Mothership Home. Namaste NOW, Shalom, and AUM! <3Loud Noise",
    "where": "Drumamine",
    "area": "Mezzanine"
  },
  {
    "id": 293,
    "day": "Sunday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Funk at noon — Come, freak da funk at noon! Oh so soon It’s Jah Funkey grooove 4 ya spunky moves! In the key of free and happy! Be yourself and do the do, woohoo! Hey now, alright, express yourself! Bada bing, bada boom! — Zoom and savor a hunk-a-funk at noon — Disco Peace Mothership Home. Namaste NOW, Shalom, and AUM! <4Loud Noise",
    "where": "Drumamine",
    "area": "Mezzanine"
  },
  {
    "id": 294,
    "day": "Thursday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Funky Music, Funky Cheese! Funky cheez tasting paired with extra funky tunez. Get the funk ova here!Vegetarian, Gluten Free",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 295,
    "day": "Friday",
    "when": "07:00 PM-09:00 PM",
    "eventType": "Class/Workshop",
    "what": "Fusion dance is a social improvised partner dance combining different dance styles to create a new aesthetic not conforming to particular defined dance styles, using a lead-follow approach that emphasizes musicality. Fusion is very flexible in the level of intimacy within the dance. Please be respectful of others’ boundaries and be clear in setting your own. 7:30 for lesson.",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 296,
    "day": "Thursday",
    "when": "07:00 PM-10:30 PM",
    "eventType": "Food/Beverage",
    "what": "Game Night at KID BAR - Bring your game trading cards for the trading table and get ready for an evening of bar drinks, sweet and salty snacks, and all kinds of games. (20 and under only)",
    "where": "KID BAR",
    "area": "Main Field"
  },
  {
    "id": 297,
    "day": "Saturday",
    "when": "07:00 PM-09:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "GAME NIGHT: Are you ready for a night of laughter, suspense, and friendly competition? Or maybe you have a deep, dark secret for our “Spill the Beans” game show? Think 2 truths and a lie, but more juicy and with audience participation. After the ice is broken, we'll transition to everyone's favorite classic game: BEANGO. Winner of BEANGO gets a special prize!!",
    "where": "Bean Here Now",
    "area": "Main Field"
  },
  {
    "id": 298,
    "day": "Saturday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Gate Pancake Breakfast - reunite the scattered pieces of your dark and dusty soul with a surprising trip to Gate's (w)holesome side. Watch the city come to life from our riverside lair while you feast on pancakes and toppings of all kinds! BYO bevvies.Vegetarian",
    "where": "Black Hole West",
    "area": "North Point Forest"
  },
  {
    "id": 299,
    "day": "Friday",
    "when": "08:45 PM-10:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Gather at Softer Space and feel the anticipation of the Electric Ocean Wave Parade kicking off at 9:00 p.m., sparking excitement and lighting up the night. Join in the fun, wearing sea creature costumes, onesies, or blowing bubbles, as we ride this enchanting wave through the festival! Concluding back at Softer Space, unwind there or continue celebrating at nearby dance parties.Loud Noise, Flashing Lights",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 300,
    "day": "Friday",
    "when": "10:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Get Funky Fresh with Trim: It's time to unleash your inner groove machine! Trim throws down a funky blend of House, Tech House, and Nu-Disco that's guaranteed to get you on the dance floor.\n Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 301,
    "day": "Friday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Get ready to bend, balance, stretch, and bliss out at Hammock Yoga, presented by Hang Loose Camp! Join us in a guided flow, all while suspended in hammock heaven. It's yoga with an extra dose of chill vibes, new possibilities, and unique challenges. Don't miss the chance to swing into serenity with us!",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 302,
    "day": "Saturday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Get ready to bend, balance, stretch, and bliss out at Hammock Yoga, presented by Hang Loose Camp! Join us in a guided flow, all while suspended in hammock heaven. It's yoga with an extra dose of chill vibes, new possibilities, and unique challenges. Don't miss the chance to swing into serenity with us!",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 303,
    "day": "Friday",
    "when": "06:30 PM-08:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Get ready to spread your wings and channel your inner metamorphosis at our Cocoon to Butterfly expo!\n Participate for prizes by most elegantly transforming from a cozy cocooned being into a soaring butterfly. This is your chance to show off your growth and dazzle! Fly on in and witness the magic unfold!",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 304,
    "day": "Saturday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Giant twister competition — ready to test your skills in being flexible? All are welcome to compete in this\n back-bending challenge on our giant twister board! Silly outfits are encouraged!",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 305,
    "day": "Friday",
    "when": "08:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "GIRLS' NIGHT, BOXED CHAMPAGNE: Calling all\n Queens to the Igloo at WET TRIBE! Bring your furs, puffs, and ski suits to the Igloo. We've got BADD B**** music, karaoke, mash-ups, and boxed champagne.\n Come get served by our camp boys in booty shorts, as you deserve Queens!Loud Noise, Flashing Lights, Seating",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 306,
    "day": "Friday",
    "when": "09:00 PM-10:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "Goth Grandpa funky house DJ set Loud Noise, Flashing Lights",
    "where": "Hypnodrome",
    "area": "Main Field"
  },
  {
    "id": 307,
    "day": "Friday",
    "when": "09:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Grand Opening and Closing — experience Hypnodrome in all its glory, for the first and last time! Live DJs, dance your face off, or relax in our cozy space!Loud Noise, Seating",
    "where": "Hypnodrome",
    "area": "Main Field"
  },
  {
    "id": 308,
    "day": "Thursday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Grand Re Re-opening Birthday Party: Is it your birthday? Was it your birthday? Will it be your birthday? Come celebrate you with us for our Grand re re-opening event!",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 309,
    "day": "Friday",
    "when": "02:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Green Fairy Trading Post: One person's junk is another person's treasure! Haggle your junk for more junk. Or complete quests in our tavern to earn special potions or trinkets for your adventures.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 310,
    "day": "Thursday",
    "when": "08:00 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Green Fairy Trading Post: One person's junk is another person's treasure! Haggle your junk for more junk. Or complete quests in our tavern to earn special potions or trinkets for your adventures.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 311,
    "day": "Sunday",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Grilled Cheese. Bring something to put it on. Serving until they are gone.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 312,
    "day": "Saturday",
    "when": "12:30 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "GROUP SENSUAL MASSAGE — Experience the ecstasy of a many-handed massage. Everyone has a chance to give and receive, and you'll have time to set your boundaries before you're in the middle (any desires from therapeutic to erotic are respected and welcome.) Come solo or as a group! REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 313,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Gummy Candy Buffet: Come one, come all and step right up to the glorious Gummy Candy Buffet! Enjoy a fruity, chewy, sour-or-sweet afternoon treat. Vegan options available. Grab one or a handful!Seating, Vegetarian, Vegan, Gluten Free",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 314,
    "day": "Saturday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Hair of the Pickle! Come enjoy a balanced breakfast of pickled things and pickle cocktails, including the World Famous (citation needed) Pickle-mosa and Pickled Mary, or a non-alcoholic pickleade.21+",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 315,
    "day": "Sunday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Hair of the Pickle! Come enjoy a balanced breakfast of pickled things and pickle cocktails, including the World Famous (citation needed) Pickle-mosa and Pickled Mary, or a non-alcoholic pickleade.21+",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 316,
    "day": "Friday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang Loose Scavenger Hunt — Get the first clue at our camp! Complete our quest and receive a special prize!",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 317,
    "day": "Saturday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang Loose Scavenger Hunt — Get the first clue at our camp! Complete our quest and receive a special prize!",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 318,
    "day": "Friday",
    "daily": "Everyday",
    "when": "06:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang out at the Radiance Dome, be chill, and watch the pretty colors.Flashing Lights",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 319,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "06:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang out at the Radiance Dome, be chill, and watch the pretty colors.Flashing Lights",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 320,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "06:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang out at the Radiance Dome, be chill, and watch the pretty colors.Flashing Lights",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 321,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Hang out at the Radiance Dome, be chill, and watch the pretty colors.Flashing Lights",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 322,
    "day": "Friday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "Happy Hour Grilled Cheeses, Cocktails, and Storytelling: Come on by, sit on the Stoop, and enjoy a tasty grilled cheese and Electric Ocean cocktails while yqu share a story.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 323,
    "day": "Thursday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "Happy Hour Grilled Cheeses, Cocktails, and Storytelling: Come on by, sit on the Stoop, and enjoy a tasty grilled cheese and Electric Ocean cocktails while yqu share a story.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 324,
    "day": "Saturday",
    "when": "03:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Have you ever drank baileys from a shoe? Come find the funk at our old gregg themed happy hour dance party!\n Open decks (come play with our decks) from 3-5pm, with our camp local DJs spinning from 5-8pm. Loud Noise, Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 325,
    "day": "Friday",
    "when": "08:30 PM-11:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "High Seas Strip Tease (door closes at 9:00pm): Embody your inner stripper at this Psychedelegant Tea Party. As vulnerability deepens authentic connection, witnessing and being witnessed in our sexuality awakens the flow of eros. Running this cosmic life force energy through our bodies infuses everything else we do with inspiration and pure love. All genders, shapes, sizes, expressions, and orientations are welcome!21+",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 326,
    "day": "Sunday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "HIGH TEA: Wet Tribe requests the honor of your company for an afternoon par-tea. You have worked so hard all weekend and deserve a relaxing treat! A variety of fine teas will be brewed, and finger sandwiches and treats will be provided. Remember, it’s always tea time, so you can’t be late!\n Seating, Vegetarian, Vegan, Nuts",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 327,
    "day": "Thursday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Hobbit Tea Time: Come drink tea, eat baked goods, and smoke like a hobbit. Bring your own Longbottom Leaf. Seating, Vegetarian, Vegan, Nuts, Alcohol-Free",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 328,
    "day": "Saturday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "HOT GLUE HOUR — Bedazzle! Repair! Fuse Together! You've been at SOAK for 48 hours and certain items are definitely falling apart or need sprucing up. Perhaps avail yourself of some HOT DOGGONE GLUE! You bring things to attach to each other. We've got some glue guns.Seating",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 329,
    "day": "Saturday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Hot Lips Hot Sauce Tasting: Come get spicy with us at our Hot Ones SOAK-style hot sauce tasting bar. From mild to a 4-alarm fire, come sample a bevy of sauces across the Scoville scale and get as hot and bothered as you dare! Enjoy alone or challenge someone to a spicy duel. Seating, Vegetarian, Vegan, Gluten Free,\n Alcohol-Free",
    "where": "Camp Take The Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 330,
    "day": "Saturday",
    "when": "03:00 PM-09:30 PM",
    "eventType": "Food/Beverage",
    "what": "HOT SAUCE CHALLENGE: For all my spicy, spice, espice burners out there. Fancy yourself a spicy one, come prove it by testing our seriously hot sauces! As the sauces get hotter, so will the tongue-twisters. Preliminary qualifiers will be held throughout the day, with the championship round held during our MBS PRE-BURN PARTY.",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 331,
    "day": "Friday",
    "when": "04:30 PM-05:15 PM",
    "eventType": "Experience/Interactive Art",
    "what": "How many wieners can you stuff down your throat? Waddle on over to Snack Shack at 4:30pm to challenge these voracious eaters to our first annual hot dog eating contest at SOAK! Every participant takes home a\n 5-gallon bucket as a consolation prize. We’re all wieners here!Loud Noise, Seating",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 332,
    "day": "Friday",
    "when": "04:20 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Human petz and mythical creaturez boarding service offered. Need a break from your petz? We’ll follow all care instructions. Beasts may occasionally break out of our cage and become unleashed. We encourage chasing of cars, barking at strangers, playing fetch and with toys. Follow the snarls, yelps, howls, and meows for the most Scaly Furry Feathered Fun to be found.",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 333,
    "day": "Saturday",
    "when": "09:00 AM-10:00 AM",
    "eventType": "Food/Beverage",
    "what": "Hydration station — offering a variety of flavors to choose from to fill your empty cup!Alcohol-Free",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 334,
    "day": "Sunday",
    "when": "09:00 AM-10:00 AM",
    "eventType": "Food/Beverage",
    "what": "Hydration station — offering a variety of flavors to choose from to fill your empty cup!Alcohol-Free",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 335,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "ICE BAR HAPPY HOUR: Come chill out in our inflatable igloo. We're serving up Music, Cocktails, Sushi, and Champagne too!Loud Noise, Seating, Vegetarian, Vegan",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 336,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "ICE BAR HAPPY HOUR: Come chill out in our inflatable igloo. We're serving up Music, Cocktails, Sushi, and Champagne too!Loud Noise, Seating, Vegetarian, Vegan",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 337,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "ICE BAR HAPPY HOUR: Come chill out in our inflatable igloo. We're serving up Music, Cocktails, Sushi, and Champagne too!Loud Noise, Seating, Vegetarian, Vegan",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 338,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Immerse yourself in the celestial vibrations of a sound bath ritual, held each day from Friday to Sunday, bewitching hours from 1 PM to 2 PM. Let the majestic melodies transport you to realms beyond, where harmony and healing intertwine. With an additional pre-dinner sound healing session from 4pm-5pm on Saturday.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 339,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Immerse yourself in the celestial vibrations of a sound bath ritual, held each day from Friday to Sunday, bewitching hours from 1 PM to 2 PM. Let the majestic melodies transport you to realms beyond, where harmony and healing intertwine. With an additional pre-dinner sound healing session from 4pm-5pm on Saturday.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 340,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Immerse yourself in the celestial vibrations of a sound bath ritual, held each day from Friday to Sunday, bewitching hours from 1 PM to 2 PM. Let the majestic melodies transport you to realms beyond, where harmony and healing intertwine. With an additional pre-dinner sound healing session from 4pm-5pm on Saturday.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 341,
    "day": "Saturday",
    "when": "07:00 PM-09:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Improv Musical Theater — Do you have the theatrical itch? Come break down the barrier between audience and stage. Between fantasy and reality. Let the drama with you lead while the Polyjam band joins in with uproarous harmony. Arrive with your \"Yes! And!\" mentality. Dynamic characters and amusing scenarios will be provided for the spontaneous creation of short improvised musical scenes. 7pm until the burn.Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 342,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "In the year 10,002,024, an Earth disassembly team finds a frozen shipping container full of instant ramen and decides to open a restaurant. Come enjoy cyberpunk vibes and \"HOT RAMEN!\" at CRORE!Loud Noise, Flashing Lights, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 343,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "In the year 10,002,024, an Earth disassembly team finds a frozen shipping container full of instant ramen and decides to open a restaurant. Come enjoy cyberpunk vibes and \"HOT RAMEN!\" at CRORE!Loud Noise, Flashing Lights, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 344,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "In the year 10,002,024, an Earth disassembly team finds a frozen shipping container full of instant ramen and decides to open a restaurant. Come enjoy cyberpunk vibes and \"HOT RAMEN!\" at CRORE!Loud Noise, Flashing Lights, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 345,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "In the year 10,002,024, an Earth disassembly team finds a frozen shipping container full of instant ramen and decides to open a restaurant. Come enjoy cyberpunk vibes and \"HOT RAMEN!\" at CRORE!Loud Noise, Flashing Lights, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "CRORE",
    "area": "Riverside"
  },
  {
    "id": 346,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 AM-12:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Indulge in our delicious cookies and baked goods daily. Self serve. Come try our gluten-free offerings while they last, and satisfy your immortal cravings. Gluten Free, Nuts, Alcohol-Free",
    "where": "Lil' Hell Bake",
    "area": "Forest Entry"
  },
  {
    "id": 347,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 AM-12:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Indulge in our delicious cookies and baked goods daily. Self serve. Come try our gluten-free offerings while they last, and satisfy your immortal cravings. Gluten Free, Nuts, Alcohol-Free",
    "where": "Lil' Hell Bake",
    "area": "Forest Entry"
  },
  {
    "id": 348,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 AM-12:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Indulge in our delicious cookies and baked goods daily. Self serve. Come try our gluten-free offerings while they last, and satisfy your immortal cravings. Gluten Free, Nuts, Alcohol-Free",
    "where": "Lil' Hell Bake",
    "area": "Forest Entry"
  },
  {
    "id": 349,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-12:00 AM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Indulge in our delicious cookies and baked goods daily. Self serve. Come try our gluten-free offerings while they last, and satisfy your immortal cravings. Gluten Free, Nuts, Alcohol-Free",
    "where": "Lil' Hell Bake",
    "area": "Forest Entry"
  },
  {
    "id": 350,
    "day": "Friday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Intentional Vibratory Solutions: Chakra attunement sound bath with Crystal singing bowls. Let's focus our Wu all the way through and get that sound all the way down!\n Feel free to bring your own mat, blanket, pillow for comfort to lay or sit with.",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 351,
    "day": "Sunday",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Intentional Vibratory Solutions: Chakra attunement sound bath with Crystal singing bowls. Let's focus our Wu all the way through and get that sound all the way down!\n Feel free to bring your own mat, blanket, pillow for comfort to lay or sit with.",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 352,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "02:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Interactive VR/AR art experience!",
    "where": "Dusty Bunnies",
    "area": "Forest Entry"
  },
  {
    "id": 353,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "02:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Interactive VR/AR art experience!",
    "where": "Dusty Bunnies",
    "area": "Forest Entry"
  },
  {
    "id": 354,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Interactive VR/AR art experience!",
    "where": "Dusty Bunnies",
    "area": "Forest Entry"
  },
  {
    "id": 355,
    "day": "Sunday",
    "when": "03:00 PM-04:30 PM",
    "eventType": "Class/Workshop",
    "what": "Intimacy and Connection Through Rope — There are many reasons why one would want to dabble with rope. Come to this beginners' workshop for a different flavor of rope, where we focus on the connection between intimacy and bondage play. Bring your own rope. Limited selection of rope available for people who don’t have their own. Adults only please.Seating, Alcohol-Free, 18+",
    "where": "Universal Eggsperience",
    "area": "Upper Bowl"
  },
  {
    "id": 356,
    "day": "Sunday",
    "when": "12:00 PM-12:50 PM",
    "eventType": "Class/Workshop",
    "what": "Intro to Contact Levi-Wand Wizardry — Learn essential techniques and foundational skills to wield the levi-wand and flow with grace and precision. Some levi-wands will be available to borrow, but bring your own if one is available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 357,
    "day": "Sunday",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "INTRO TO IMPACT PLAY “Impact Play” refers to hitting someone, and/or being hit, for fun and gratification of a sexual/kinky variety. If you find yourself intrigued by the idea but don't know where or how to get started, this is the event for you! Bare hands, floggers, paddles, straps, and canes! Discussion, demonstrations, and practice!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 358,
    "day": "Saturday",
    "when": "01:00 PM-02:30 PM",
    "eventType": "Class/Workshop",
    "what": "Intro to Integration. What is integration? Why does Set, Setting and Safety matter? What are our parts and how do I access them? Will cover some integration basics and tools to raise your self awareness.",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 359,
    "day": "Friday",
    "when": "01:00 PM-01:50 PM",
    "eventType": "Class/Workshop",
    "what": "Intro to Prop Dancing: Demystifying Movement\n \n\n Bring any flow prop and prepare to have the secrets revealed! Learn the underlying language and foundation of prop movements. Walk (or flow) away with knowledge you can apply today! (For any skill level, most props)",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 360,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Isolation Station: If you're feeling overstimulated and your inner introvert is asking for a break, come rest and reset at our Isolation Station. Nothing to see, nothing to hear, and nobody to talk to, just a comforting slice of sensory deprivation.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 361,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Isolation Station: If you're feeling overstimulated and your inner introvert is asking for a break, come rest and reset at our Isolation Station. Nothing to see, nothing to hear, and nobody to talk to, just a comforting slice of sensory deprivation.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 362,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Isolation Station: If you're feeling overstimulated and your inner introvert is asking for a break, come rest and reset at our Isolation Station. Nothing to see, nothing to hear, and nobody to talk to, just a comforting slice of sensory deprivation.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 363,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Isolation Station: If you're feeling overstimulated and your inner introvert is asking for a break, come rest and reset at our Isolation Station. Nothing to see, nothing to hear, and nobody to talk to, just a comforting slice of sensory deprivation.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 364,
    "day": "Friday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Jewelry-Making Workshop. Various supplies for jewelry creation will be provided. Bring your creativity and see what kind of beauty results. We will have some polished stones, wire, beads, chords and charms available to help you create your vision. Want to make woven friendship bracelets? We will have plenty of threads as well!\n Brought to you by Camp Touchy Feely, a camp specializing in sensory objects and fidget toys.Seating, Alcohol-Free",
    "where": "Touchy Feely",
    "area": "Forest Entry"
  },
  {
    "id": 365,
    "day": "Saturday",
    "when": "11:00 AM-12:30 PM",
    "eventType": "Class/Workshop",
    "what": "Join Lucifer for a sensual movement journey! Step into a realm where movement transcends the ordinary and\n self-expression takes center stage. In this unique dance class, you'll embark on a transformative experience that blends stretching, sensual movement, and ecstatic dance.",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 366,
    "day": "Sunday",
    "when": "12:00 PM-01:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "Join us at Buckets camp for Dance Church on Sunday from Noon-1:30 pm. High Priestess of House, Krista Basis, will be your deacon of dance as she plays tracks of booty shaking devotion while lifting your spirits — demons be gone!!! Mimosas provided (while supplies last\n — bring your cups!) EVERY BODY WELCOME AT DANCE CHURCH.",
    "where": "BUCKETS",
    "area": "Upper Bowl"
  },
  {
    "id": 367,
    "day": "Friday",
    "when": "01:30 PM-02:30 PM",
    "eventType": "Class/Workshop",
    "what": "Join us at Camp Clusterfuck with lessons and discussion of the essentials of live event magic with \"Backstage Bedlam.\" This workshop offers a dive into mastering sound, lights, and stage management, blending technical skill with the boundless creativity of the playa. Come learn to amplify your artistry and ensure every performance captivates and shines.Loud Noise, Flashing Lights, Alcohol-Free",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 368,
    "day": "Saturday",
    "daily": "Th, Sat, Sun",
    "when": "09:00 PM-12:00 AM",
    "eventType": "Food/Beverage",
    "what": "Join us at our Alpen Dämonen themed bar for an evening of revelry, spirits, and the bizarre. Be prepared to show your ID.\n 21+",
    "where": "Krampusberg",
    "area": "Forest Entry"
  },
  {
    "id": 369,
    "day": "Sunday",
    "daily": "Th, Sat, Sun",
    "when": "09:00 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "Join us at our Alpen Dämonen themed bar for an evening of revelry, spirits, and the bizarre. Be prepared to show your ID.\n 21+",
    "where": "Krampusberg",
    "area": "Forest Entry"
  },
  {
    "id": 370,
    "day": "Thursday",
    "daily": "Th, Sat, Sun",
    "when": "08:45 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "Join us at our Alpen Dämonen themed bar for an evening of revelry, spirits, and the bizarre. Be prepared to show your ID.\n 21+",
    "where": "Krampusberg",
    "area": "Forest Entry"
  },
  {
    "id": 371,
    "day": "Friday",
    "when": "01:00 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "Join us for a Flower Making Workshop at the Bee One With the Flowers installation! We’ll use cut paper petals and paint them with natural pigments. You have the option of adding your flower to the installation and retrieving it before you leave SOAK, or taking it with you after the workshop!",
    "where": "Bee One With the Flowers",
    "area": "Forest Entry"
  },
  {
    "id": 372,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us for some crazy Mad Libs fun! Help us create stories that will no doubt have us all laughing until our faces hurt. Don’t like Mad Libs, no worries—grab a book to read out loud to us.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 373,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "03:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us for some crazy Mad Libs fun! Help us create stories that will no doubt have us all laughing until our faces hurt. Don’t like Mad Libs, no worries—grab a book to read out loud to us.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 374,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us for some crazy Mad Libs fun! Help us create stories that will no doubt have us all laughing until our faces hurt. Don’t like Mad Libs, no worries—grab a book to read out loud to us.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 375,
    "day": "Friday",
    "daily": "Everyday",
    "when": "08:00 AM-10:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Join us in creating space to grow community and connections! The Community Conch is a unique interactive experience where participants will be invited to share part of their stories and experiences by writing or drawing on a piece of fabric. This fabric can then be woven into the structure of this sea shell shaped",
    "where": "Community conch",
    "area": "Riverside"
  },
  {
    "id": 376,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "08:00 AM-10:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Join us in creating space to grow community and connections! The Community Conch is a unique interactive experience where participants will be invited to share part of their stories and experiences by writing or drawing on a piece of fabric. This fabric can then be woven into the structure of this sea shell shaped sculpture. Together, we will create a cozy hangout spot for all to enjoy. So come on down and share your story!Flashing Lights, Seating",
    "where": "Community conch",
    "area": "Riverside"
  },
  {
    "id": 377,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "08:00 AM-10:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us in creating space to grow community and connections! The Community Conch is a unique interactive experience where participants will be invited to share part of their stories and experiences by writing or drawing on a piece of fabric. This fabric can then be woven into the structure of this sea shell shaped sculpture. Together, we will create a cozy hangout spot for all to enjoy. So come on down and share your story!Flashing Lights, Seating",
    "where": "Community conch",
    "area": "Riverside"
  },
  {
    "id": 378,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "08:00 AM-10:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Join us in creating space to grow community and connections! The Community Conch is a unique interactive experience where participants will be invited to share part of their stories and experiences by writing or drawing on a piece of fabric. This fabric can then be woven into the structure of this sea shell shaped sculpture. Together, we will create a cozy hangout spot for all to enjoy. So come on down and share your story!Flashing Lights, Seating",
    "where": "Community conch",
    "area": "Riverside"
  },
  {
    "id": 379,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us on the You Go There Boats for tours, adventures and joyrides. Drop by the information wharf to learn when daily departures are scheduled.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 380,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us on the You Go There Boats for tours, adventures and joyrides. Drop by the information wharf to learn when daily departures are scheduled.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 381,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us on the You Go There Boats for tours, adventures and joyrides. Drop by the information wharf to learn when daily departures are scheduled.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 382,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Join us on the You Go There Boats for tours, adventures and joyrides. Drop by the information wharf to learn when daily departures are scheduled.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 383,
    "day": "Friday",
    "daily": "Everyday",
    "when": "06:00 AM-06:05 AM",
    "eventType": "Experience/Interactive Art",
    "what": "KFC's 18+ Outdoor Adult Play Space's Hard Opening — Little Dungeon in the Woods is open 24 hours a day.\n Quiet hours are 3am-10am. Informed consent is required. Bring a towel to cover the furniture and take your MOOP. Latex condoms and vinyl gloves provided. Join our daily 4:20pm Meet and Greet Play Parties.21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 384,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "06:00 AM-06:05 AM",
    "eventType": "Experience/Interactive Art",
    "what": "KFC's 18+ Outdoor Adult Play Space's Hard Opening — Little Dungeon in the Woods is open 24 hours a day.\n Quiet hours are 3am-10am. Informed consent is required. Bring a towel to cover the furniture and take your MOOP. Latex condoms and vinyl gloves provided. Join our daily 4:20pm Meet and Greet Play Parties.21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 385,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "06:00 AM-06:05 AM",
    "eventType": "Experience/Interactive Art",
    "what": "KFC's 18+ Outdoor Adult Play Space's Hard Opening — Little Dungeon in the Woods is open 24 hours a day.\n Quiet hours are 3am-10am. Informed consent is required. Bring a towel to cover the furniture and take your MOOP. Latex condoms and vinyl gloves provided. Join our daily 4:20pm Meet and Greet Play Parties.21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 386,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 AM-06:05 AM",
    "eventType": "Experience/Interactive Art",
    "what": "KFC's 18+ Outdoor Adult Play Space's Hard Opening — Little Dungeon in the Woods is open 24 hours a day.\n Quiet hours are 3am-10am. Informed consent is required. Bring a towel to cover the furniture and take your MOOP. Latex condoms and vinyl gloves provided. Join our daily 4:20pm Meet and Greet Play Parties.21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 387,
    "day": "Sunday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Kids Dance Party — Folks, bring your kiddos and their dancing shoes for a kids-friendly rager, hosted by SOAK SUPER DAD DJ Hustleberry.Loud Noise",
    "where": "Babe Camp",
    "area": "Mezzanine"
  },
  {
    "id": 388,
    "day": "Friday",
    "when": "12:00 PM-12:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "KILT WALK Wear a kilt, straddle voyeurs, and walk the line as they lookie-lou from below you!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 389,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "Knot tying for burners. Learn some knots and level up to become your camp's go-to person for tying things down. All skill levels, or lack thereof, welcome.Seating",
    "where": "DSF",
    "area": "Main Field"
  },
  {
    "id": 390,
    "day": "Saturday",
    "when": "02:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Kraken Whackin’ — Our favorite Kraken, she likes a whackin’! In this tastings-style impact play event, interact with one of our talented Tops to negotiate a consensual sensation experience. Whether you’re a seasoned kinkster or newly curious about such shenanigans, come try a bunny-fur flogger, wooden paddle, or one of our many other options. Adults only please.Alcohol-Free, 18+",
    "where": "Universal Eggsperience",
    "area": "Upper Bowl"
  },
  {
    "id": 391,
    "day": "Saturday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Late Night Grilled Cheese! You know you want it. Come and get it. Cocktails provided.Vegetarian, Vegan, Gluten Free",
    "where": "Stoop",
    "area": "Main Field"
  },
  {
    "id": 392,
    "day": "Friday",
    "when": "02:45 PM-03:45 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Let your inner child freak flag fly and come play camp games with us!!!!",
    "where": "Le Kafé",
    "area": "Riverside"
  },
  {
    "id": 393,
    "day": "Friday",
    "daily": "Everyday",
    "when": "01:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Let's play \"Doctor.\" You won't even have to show us 'Yours!' We have lots of fun interactive medical spoofs planned as well as amazing therapeutic options.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 394,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "01:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Let's play \"Doctor.\" You won't even have to show us 'Yours!' We have lots of fun interactive medical spoofs planned as well as amazing therapeutic options.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 395,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "01:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Let's play \"Doctor.\" You won't even have to show us 'Yours!' We have lots of fun interactive medical spoofs planned as well as amazing therapeutic options.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 396,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "01:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Let's play \"Doctor.\" You won't even have to show us 'Yours!' We have lots of fun interactive medical spoofs planned as well as amazing therapeutic options.",
    "where": "Dr. Bev's Clinic",
    "area": "Main Field"
  },
  {
    "id": 397,
    "day": "Saturday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "LIFE DRAWING — Be Model or Artist. We provide mer-tails and drawing materials. Artists, create your take-home souvenir. Experiment w/chalk, charcoal, pencil. Models, wear our mer-tails. Pose and become\n your alter-ego Copenhagen Mermaid, Ariel, Ran, Siren, Atargatis, or Amphitrite – google them. ADA friendly.Seating",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 398,
    "day": "Sunday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "LIFE DRAWING — Be Model or Artist. We provide mer-tails and drawing materials. Artists, create your take-home souvenir. Experiment w/chalk, charcoal, pencil. Models, wear our mer-tails. Pose and become\n your alter-ego Copenhagen Mermaid, Ariel, Ran, Siren, Atargatis, or Amphitrite – google them. ADA friendly.Seating",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 399,
    "day": "Friday",
    "when": "04:00 PM-04:50 PM",
    "eventType": "Class/Workshop",
    "what": "Lights, Laughter, Action: Improv Theater Games\n \n\n Cats in Space invites you to come and be your most authentic silly goose!!! Come joke, laugh, play, and let your inner child shine with us in a series of improv theater games and laughter exercises.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 400,
    "day": "Friday",
    "when": "10:00 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Live Band Karaoke — Bring a drink, pick a song from our list of easily improvised favorites, and let'er rip! Or bring the chords to your favorite (simple) song, and we'll do our best. Let PolyJ become your new favorite KJ!! Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 401,
    "day": "Friday",
    "when": "09:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "Live Music — Dreamland Farmers. Treat your ears to some genre-bending original live tunes. Friday around 9pm and Sunday after temple.Loud Noise",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 402,
    "day": "Sunday",
    "when": "10:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "Live Music — Dreamland Farmers. Treat your ears to some genre-bending original live tunes. Friday around 9pm and Sunday after temple.Loud Noise",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 403,
    "day": "Friday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Food/Beverage",
    "what": "Looking for a late-night delight? Do you crave gooey goodness? Do Toppings Turn your crank? Snacks 'n' Snuggles is bringing back Midnight Pizza! Let us spice up your night with slices of cheesy goodness! This year, we will be offering an optional side of spankings from one of our dedicated volunteers! Join us for a slice and a smack! #SizzleAndSpankSeating, Vegetarian, Vegan, 18+",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 404,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Class/Workshop",
    "what": "Loopdee Loop — Get in the loop with this live looping session. Walk up and scream, sing, growl, or add any other random noise in the loop mix. Try for the first time or do a deep dive — who knows what could happen!Loud Noise",
    "where": "Drumamine",
    "area": "Mezzanine"
  },
  {
    "id": 405,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Love emo music? Want to sing along to nostalgic bops or chill with like-minded elder emos? Then our afternoon emo hour is perfect for you! Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 406,
    "day": "Sunday",
    "when": "01:11 AM-03:33 AM",
    "eventType": "Performance/Entertainment",
    "what": "Low Tide Lounge: walk/ride/float/sway/saunter on down for immersive ambiance & downtempo soundscapes in the big bell tent.Loud Noise, Seating",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 407,
    "day": "Friday",
    "when": "06:00 PM-07:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Mad Lib Poetry: Fancy yourself a poet? Want to express yourself but can't find the words? Help us create mad lib poetry and read them aloud for everyone to enjoy.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 408,
    "day": "Saturday",
    "when": "12:00 AM-01:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Mad Lib Poetry: Fancy yourself a poet? Want to express yourself but can't find the words? Help us create mad lib poetry and read them aloud for everyone to enjoy.",
    "where": "Absinthe Minded",
    "area": "Riverside"
  },
  {
    "id": 409,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Make your own fidget toy. Camp Touchy Feely, which specializes in sensory objects and fidget toys, presents this hands-on toy-making workshop. Participants will create gooey Slime bags and customize them with duct tape and food coloring. Take your toy home for unlimited future fidgeting! Supplies are limited.Seating,\n Alcohol-Free",
    "where": "Touchy Feely",
    "area": "Forest Entry"
  },
  {
    "id": 410,
    "day": "Friday",
    "when": "01:00 PM-02:30 PM",
    "eventType": "Class/Workshop",
    "what": "MAKE YOUR OWN FLOGGER This workshop is in the running for the most Portland event ever; we will be making our own floggers out of upcycled bicycle inner tubes! At this hands-on crafting event, we have all the materials necessary; all you need to bring is yourself and a playful, crafty attitude.",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 411,
    "day": "Saturday",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "MANY HANDS MASSAGE Ever wanted to be touched and massaged by 4 hands? Guests will be grouped into triads and take turns receiving and giving massage and thoughtful, sensual touch to one another, while honoring boundaries, practicing consent and asking for what you want! (Or don't want!)",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 412,
    "day": "Thursday",
    "when": "07:00 PM-08:30 PM",
    "eventType": "Class/Workshop",
    "what": "MANY HANDS MASSAGE Ever wanted to be touched and massaged by 4 hands? Guests will be grouped into triads and take turns receiving and giving massage and thoughtful, sensual touch to one another, while honoring boundaries, practicing consent and asking for what you want! (Or don't want!)",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 413,
    "day": "Sunday",
    "when": "12:00 PM-01:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Mass of the Sacred Harvest — Calling all ye faithful and juicy sinners alike! Join us in the hallowed orchards of tradition for the Sacrament of the Peach. Partake in the holy rites of ripeness, be absolved of your mortal burdens, and revel in praise among your fellow peach worshipers. Here, every soul is welcomed to bask in the glory of the fuzz and find salvation in the sweetness.\n Mass at noon, confessional and weddings to follow.",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 414,
    "day": "Saturday",
    "when": "08:00 PM-09:30 PM",
    "eventType": "Food/Beverage",
    "what": "MBS PRE-BURN PARTY: Find your favorite spot to watch the burn, set up your chairs, and lay down your blankets, then come grab a drink at our Pre-Burn Party. Enjoy some tunes while we wait for the final go-ahead for MBS's Saturday Burn. If you forget where your blanket is, the view from WET TRIBE doesn't disappoint.Loud Noise, Flashing Lights, Seating",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 415,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Class/Workshop",
    "what": "Meditation and Intention-Setting—A short guided meditation to transition from the grind of default world to a weekend full of possibilities. Breathe, drop into now, and listen to your heart’s true desire.\n Seating",
    "where": "Camp Take the Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 416,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Meditation with Mindful. Come explore the subtle ways we reject change and discomfort through the practice of sitting meditation. Some sitting supplies may be available, but please bring something that will allow you to sit comfortably upright without relying on back support if you have it.",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 417,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Meditation with Mindful. Come explore the subtle ways we reject change and discomfort through the practice of sitting meditation. Some sitting supplies may be available, but please bring something that will allow you to sit comfortably upright without relying on back support if you have it.",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 418,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Class/Workshop",
    "what": "Meditation with Mindful. Come explore the subtle ways we reject change and discomfort through the practice of sitting meditation. Some sitting supplies may be available, but please bring something that will allow you to sit comfortably upright without relying on back support if you have it.",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 419,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "09:30 PM-12:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Meet your non-human neighbors of the Tygh Valley! Steady UV black lights on a large white cloth attract beautiful moths and other nocturnal wildlife. Biology nerd standing by to make up answers to your questions! Open if rain is light; closed if rain is heavy. May run into wee morning hours if the bugs are extra neat.Flashing Lights",
    "where": "see neat bugs!",
    "area": "Riverside"
  },
  {
    "id": 420,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "10:30 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Meet your non-human neighbors of the Tygh Valley! Steady UV black lights on a large white cloth attract beautiful moths and other nocturnal wildlife. Biology nerd standing by to make up answers to your questions! Open if rain is light; closed if rain is heavy. May run into wee morning hours if the bugs are extra neat.Flashing Lights",
    "where": "see neat bugs!",
    "area": "Riverside"
  },
  {
    "id": 421,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "09:30 PM-12:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Meet your non-human neighbors of the Tygh Valley! Steady UV black lights on a large white cloth attract beautiful moths and other nocturnal wildlife. Biology nerd standing by to make up answers to your questions! Open if rain is light; closed if rain is heavy. May run into wee morning hours if the bugs are extra neat.Flashing Lights",
    "where": "see neat bugs!",
    "area": "Riverside"
  },
  {
    "id": 422,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "MERFOLK PHOTOGRAPHY BOOTH—Ahoy you\n Mermaids, Mermen, Merkids, or Triton! Take mer-group, mer-single photos. Mer-tails provided. Be your version of The Copenhagen Mermaid, Baby Shark, or Ariel! Bring your cameras. Free seaweed if you stay for Life Drawing at 4pm as model or artist. ADA friendly.\n Seating",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 423,
    "day": "Sunday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "MERFOLK PHOTOGRAPHY BOOTH—Ahoy you\n Mermaids, Mermen, Merkids, or Triton! Take mer-group, mer-single photos. Mer-tails provided. Be your version of The Copenhagen Mermaid, Baby Shark, or Ariel! Bring your cameras. Free seaweed if you stay for Life Drawing at 4pm as model or artist. ADA friendly.\n Seating",
    "where": "TALK WITH STRANGERS",
    "area": "Meadow"
  },
  {
    "id": 424,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Mermaids are real?! Prepare with a sip of margarita to splash your mind, or craft your own tin foil hat to tune into their Atlantean songs. Then you'll be ready to understand—or even contribute to—our conspiracy (evidence!) board.",
    "where": "Cracked Pot",
    "area": "Upper Bowl"
  },
  {
    "id": 425,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Mid-morning each day, we will serve coffee with the alcohol of / in the styles of different countries around the world. We will have drip coffee, percolator coffee, and espresso. Coffee without alcohol is also available.\n Vegetarian, Vegan, Gluten Free",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 426,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Mid-morning each day, we will serve coffee with the alcohol of / in the styles of different countries around the world. We will have drip coffee, percolator coffee, and espresso. Coffee without alcohol is also available.\n Vegetarian, Vegan, Gluten Free",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 427,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Mid-morning each day, we will serve coffee with the alcohol of / in the styles of different countries around the world. We will have drip coffee, percolator coffee, and espresso. Coffee without alcohol is also available.\n Vegetarian, Vegan, Gluten Free",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 428,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Mid-morning each day, we will serve coffee with the alcohol of / in the styles of different countries around the world. We will have drip coffee, percolator coffee, and espresso. Coffee without alcohol is also available.\n Vegetarian, Vegan, Gluten Free",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 429,
    "day": "Saturday",
    "when": "12:00 AM-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "Midnight Margs — Our margaritas are practically magic! Help put the lime in the coconut with Snack Shack’s resident witches and warlocks by joining our coven at the stroke of midnight. Carne Asada tacos and veg options to be provided. Late night magic can build quite the appetite!Loud Noise, Flashing Lights, Seating, Gluten Free, 21+, Sexual Content",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 430,
    "day": "Sunday",
    "when": "12:00 AM-01:30 AM",
    "eventType": "Food/Beverage",
    "what": "Midnight Miso — Vegetarian miso to keep you warm, nourished, moving, and grooving into the wee hours. Come by after the MBS burn, while the miso lasts.Vegan, Gluten Free",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 431,
    "day": "Sunday",
    "when": "01:00 PM-01:50 PM",
    "eventType": "Class/Workshop",
    "what": "Mindful Movement: Body Self-Care 101 — Learn full spine and extremity mobilizations, stretches, and muscle activation for common under-used muscles. Tailored for movement artists. Bring a towel/yoga belt, and mat if you have one available.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 432,
    "day": "Friday",
    "when": "08:00 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Miss SOAK After Party! Immediately following the Miss SOAK 2024 competition, join us at Babe Camp for a night of dancing and music, with a lineup curated by Portland's own Mienne and DJ Hustleberry!Loud Noise, Flashing Lights",
    "where": "Babe Camp",
    "area": "Mezzanine"
  },
  {
    "id": 433,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Mix Tape Sessions: Join Raven and Trip Hazard as we take you on a sonic nostalgic journey. Boogie along as we play some of our favorite deep cuts and dancey tunes from the decades prior. Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 434,
    "day": "Sunday",
    "when": "08:00 AM-09:00 AM",
    "eventType": "Food/Beverage",
    "what": "Mommy's Avocado Toast — Stay up late or wake up early. Serving toast and toppings until they're gone. Will have GF bread option.Vegetarian, Vegan, Alcohol-Free",
    "where": "By the barn",
    "area": "Barn"
  },
  {
    "id": 435,
    "day": "Friday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Moogle — Distant Land and Voids",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 436,
    "day": "Thursday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Moogle — Synths and Bits",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 437,
    "day": "Thursday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Moogle — Tales from the B Side",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 438,
    "day": "Sunday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Moogle \"Return of the Synth\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 439,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:00 AM-11:30 AM",
    "eventType": "Class/Workshop",
    "what": "Mystery Improv Theater 9001 — Learn some physical warm-ups, theater theories, rituals, and practice some Sassy Answers to Silly Questions (as inspired by MAD's Al Jaffee)! 20 person limit. Physical exercise involved.\n Please bring H2O.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 440,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 AM-11:30 AM",
    "eventType": "Class/Workshop",
    "what": "Mystery Improv Theater 9001 — Learn some physical warm-ups, theater theories, rituals, and practice some Sassy Answers to Silly Questions (as inspired by MAD's Al Jaffee)! 20 person limit. Physical exercise involved.\n Please bring H2O.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 441,
    "day": "Sunday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Nectar Nuptials — Welcome to the ripest, juiciest wedding chapel in town. No reservation? No problem! We're serving up matrimonial bliss peachy style - where every match is ripe for the “I do” under the watchful eye of the eternal peach.",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 442,
    "day": "Friday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Need a new playa name? Don't have one at all!? Well, come over to Chameleon Camp and play the NAME GAME! Spin the wheel and see what fate has in store for ya. Happy hour afterwards where you can show off that NEW NAME! You might even earn a prize!! See you there, (insert NEW NAME here)!!!",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 443,
    "day": "Friday",
    "when": "05:00 PM-05:30 PM",
    "eventType": "Class/Workshop",
    "what": "Negotiations class.Seating",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 444,
    "day": "Thursday",
    "when": "05:00 PM-05:30 PM",
    "eventType": "Class/Workshop",
    "what": "Negotiations class.Seating",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 445,
    "day": "Sunday",
    "when": "12:01 AM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Neon Nail Salon: Discovery our richly decorated blacklight lounge for a dreamy and fantastical experience, complete with absurd performance art, groovy music, and of course, a neon nail bar. There's only one rule: you cannot paint your own nails.Loud Noise, Flashing Lights, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 446,
    "day": "Friday",
    "when": "05:20 PM-05:50 PM",
    "eventType": "Food/Beverage",
    "what": "Nickleback Thicklebacks — You’ve heard of Picklebacks, now prepare yourself for the latest advancement in mixology: The Thickleback! Honestly, it’s better if we don’t try to describe it and you just experience it for yourself. Yes, Nickelback is involved. That’s a Spider Fact.",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 447,
    "day": "Thursday",
    "when": "10:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Noodle Soup! Come get your late-night nood fix at SMASH camp. BYOBowl. Vegan and GF options available.Seating, Vegetarian, Vegan, Gluten Free",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 448,
    "day": "Thursday",
    "when": "9:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Octopussy's \"Hi\" Tea Cozy: self-serve tea and cookies in a plush cozy cuddle space. Come learn about all our event offerings from massage to sound baths! Vegetarian",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 449,
    "day": "Sunday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Octopussy’s Sense-8-tional Garden – Come back into your bodies after the Temple Burn with blindfolds and sensational delights. This is a consent-filled space for exploring sensual experience, not intended to be overtly sexual.21+, Alcohol-Free",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 450,
    "day": "Saturday",
    "when": "After Burn-02:00 AM",
    "eventType": "Food/Beverage",
    "what": "Octopussy's Tea Cozy: self-serve tea and cookies in a plush cozy cuddle space.Vegetarian",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 451,
    "day": "Friday",
    "when": "08:30 PM-10:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "On your way through the Meadows, stop by Baggage Check and watch an eclectic movie and enjoy a cold drink. There may even be some snacks. Would you like to discuss what you just watched? You never know what we will have, but it will always be entertaining.",
    "where": "Baggage Check",
    "area": "Meadow"
  },
  {
    "id": 452,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Open deck! Come and play some music for us!Loud Noise",
    "where": "Acid Drip",
    "area": "Mezzanine"
  },
  {
    "id": 453,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Open deck! Come and play some music for us!Loud Noise",
    "where": "Acid Drip",
    "area": "Mezzanine"
  },
  {
    "id": 454,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Open deck! Come and play some music for us!Loud Noise",
    "where": "Acid Drip",
    "area": "Mezzanine"
  },
  {
    "id": 455,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Open deck! Come and play some music for us!Loud Noise",
    "where": "Acid Drip",
    "area": "Mezzanine"
  },
  {
    "id": 456,
    "day": "Thursday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Open Decks Warm-Up: DJ's come and show us your stuff! Help open the sound stage for the Church of Party and play on our awesome Yamaha / Turbosound system. Loud Noise, Flashing Lights, 21+",
    "where": "Main Stage at Church of Party",
    "area": "Upper Bowl"
  },
  {
    "id": 457,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Performance/Entertainment",
    "what": "Open Jam! Join our troupe of merry music-makers and experience the true essence of what non-possessive and free-flow music can be! You're already in the band! Our instruments are always available, our stage always open! Come share your gift and feel supported by a talented band behind you, in a non-judgmental environment. We jam all day.Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 458,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Performance/Entertainment",
    "what": "Open Jam! Join our troupe of merry music-makers and experience the true essence of what non-possessive and free-flow music can be! You're already in the band! Our instruments are always available, our stage always open! Come share your gift and feel supported by a talented band behind you, in a non-judgmental environment. We jam all day.Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 459,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Performance/Entertainment",
    "what": "Open Jam! Join our troupe of merry music-makers and experience the true essence of what non-possessive and free-flow music can be! You're already in the band! Our instruments are always available, our stage always open! Come share your gift and feel supported by a talented band behind you, in a non-judgmental environment. We jam all day.Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 460,
    "day": "Thursday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Otter Pop Speed Dating — How quickly can you make a connection? The date ends when you finish your freezie! Meet your soulmate, a fling for the weekend, or a new best friend. Find us in the Main Field, near The Barn.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "Black Rock Center for Unlearning",
    "area": "Main Field"
  },
  {
    "id": 461,
    "day": "Friday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Our daily happy hour featuring craft cocktail (and non-alcoholic) creations with top shelf booze and\n hand-crafted syrups and juices. Join us for music and good company, with vibes ranging from lounging to dance party, depending on the day.",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 462,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Our daily happy hour featuring craft cocktail (and non-alcoholic) creations with top shelf booze and\n hand-crafted syrups and juices. Join us for music and good company, with vibes ranging from lounging to dance party, depending on the day.",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 463,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Our daily happy hour featuring craft cocktail (and non-alcoholic) creations with top shelf booze and\n hand-crafted syrups and juices. Join us for music and good company, with vibes ranging from lounging to dance party, depending on the day.",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 464,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Our daily happy hour featuring craft cocktail (and non-alcoholic) creations with top shelf booze and\n hand-crafted syrups and juices. Join us for music and good company, with vibes ranging from lounging to dance party, depending on the day.",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 465,
    "day": "Saturday",
    "when": "03:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "paint n sip. All ages welcome. We’ll have alcoholic and non-alcoholic drinks. Come follow along while we paint jellyfish on a tiny canvas.Alcohol-Free",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 466,
    "day": "Friday",
    "when": "11:00 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "PARALLEL PLAY PARTY Calling all voyeurs and exhibitionists! Bring your established partner(s) and engage in “Parallel Play”! Guests are invited to come explore their sexual energy with their partners, while others do the same in a shared environment. Only play with the people you enter with - this is not a place for forming new connections. Bring a towel or yoga mat!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 467,
    "day": "Sunday",
    "when": "02:00 PM-02:50 PM",
    "eventType": "Class/Workshop",
    "what": "Partner Poi 101: Sync Your Flow — Discover the fundamentals of synchronized movement, communication, and collaboration with a partner. Basic poi skills including the weave and butterfly will be needed for this class. Bring a partner or find one to learn with at the workshop. Some poi will be available to borrow, but bring your own if available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 468,
    "day": "Friday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Pathlighter Happy Hour! Are you a Pathlighter volly? Would you like to be? Come meet the team and let us shed some light on ya while you enjoy some beverages (NA options available!).",
    "where": "S*M*A*S*H",
    "area": "Riverside"
  },
  {
    "id": 469,
    "day": "Saturday",
    "when": "12:00 PM-01:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Peach and Worship (Totally Not a Cult) — Join us for a \"Peachy Praise\" gathering, where we'll harvest the sweet melodies of devotion in a symphony of succulent song. It’s a juicy opportunity to let your spirit ripen in the orchard of faith, singing hymns that drip with the nectar of gratitude and reverence. Communion to follow.",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 470,
    "day": "Friday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Peacharoos — Now that you’ve got your stretch on and experienced our peach fuzz, saunter on over to be baptized by one of our peach priest(ess)s and take the next step of our 69-step pilgrimage to the center of the peach. (dunkaroos).21+",
    "where": "The Cult of the Peach",
    "area": "Upper Bowl"
  },
  {
    "id": 471,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Class/Workshop",
    "what": "Peer Support Integration Circle: Intentional space to process, share, and integrate insights and emotions from transformational experiences. Trained psilocybin facilitators will guide group discussions, mindfulness exercises, creative expression, and psychoeducation, participants explore their psychedelic experiences, integrate insights, address challenges, and foster personal growth and resilience.No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 472,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Class/Workshop",
    "what": "Peer Support Integration Circle: Intentional space to process, share, and integrate insights and emotions from transformational experiences. Trained psilocybin facilitators will guide group discussions, mindfulness exercises, creative expression, and psychoeducation, participants explore their psychedelic experiences, integrate insights, address challenges, and foster personal growth and resilience.No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 473,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Peer Support Integration Circle: Intentional space to process, share, and integrate insights and emotions from transformational experiences. Trained psilocybin facilitators will guide group discussions, mindfulness exercises, creative expression, and psychoeducation, participants explore their psychedelic experiences, integrate insights, address challenges, and foster personal growth and resilience.No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 474,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "03:00 PM-03:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Pillow fight. Come and have some fun with a good old pillow fight. Bring a pillow or use some of ours. Once you enter, you have given consent to being hit with a pillow.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 475,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:00 AM-11:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Pillow fight. Come and have some fun with a good old pillow fight. Bring a pillow or use some of ours. Once you enter, you have given consent to being hit with a pillow.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 476,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "03:00 PM-03:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Pillow fight. Come and have some fun with a good old pillow fight. Bring a pillow or use some of ours. Once you enter, you have given consent to being hit with a pillow.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 477,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 AM-11:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Pillow fight. Come and have some fun with a good old pillow fight. Bring a pillow or use some of ours. Once you enter, you have given consent to being hit with a pillow.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 478,
    "day": "Friday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Pirate Cult Recruitment — Keep your periscopes polished and be on the look out, matey, for your friendly local Pirate Cult! We will be scuppering on the prowl for recruits. If we hook you, we'll drag you back to PolyJam camp for Sea Shanties and jams (and BYO libations!), and you may never be released from our merry clutches again!!! Arghhhh!!Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 479,
    "day": "Friday",
    "when": "07:00 PM-10:30 PM",
    "eventType": "Food/Beverage",
    "what": "Pizza Night at KID BAR - The bar is open and will be slinging wood-fired pizza until all the pies are gone. Vegetarian will be available. Bring your own cup and plate. Come hang out and play at the gaming tables. (20 and younger only)",
    "where": "KID BAR",
    "area": "Main Field"
  },
  {
    "id": 480,
    "day": "Sunday",
    "when": "06:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Please be sure to have finished your visit to the Temple by 6 PM Sunday evening — our Fire Perimeter team will be closing the Temple to allow the crew to prepare it for the evening's burn. Don't get turned away because you left it too late!",
    "where": "The Temple, Upper Bowl",
    "area": "Upper Bowl"
  },
  {
    "id": 481,
    "day": "Sunday",
    "when": "03:30 PM-05:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Pleasuring the Planetary Body Tea Party: At this tea party, we will imagine and discuss irresistibly beautiful visions and possibilities for the future of life on this planet. Practice consciously co-evolving together.\n Vegetarian",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 482,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "POLAR PLUNGE SILENT DISCO ECSTATIC DANCE\n (clothing optional): When the weather allows and the vibe demands, we will be hosting Silent Discos throughout SOAK. With 5 different channels, there's something for everyone. Freely dive into the music and move comfortably in our safe space. Consensual contact only. All levels and degrees of dress welcome.",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 483,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "POLAR PLUNGE SILENT DISCO ECSTATIC DANCE\n (clothing optional): When the weather allows and the vibe demands, we will be hosting Silent Discos throughout SOAK. With 5 different channels, there's something for everyone. Freely dive into the music and move comfortably in our safe space. Consensual contact only. All levels and degrees of dress welcome.",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 484,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "POLAR PLUNGE SILENT DISCO ECSTATIC DANCE\n (clothing optional): When the weather allows and the vibe demands, we will be hosting Silent Discos throughout SOAK. With 5 different channels, there's something for everyone. Freely dive into the music and move comfortably in our safe space. Consensual contact only. All levels and degrees of dress welcome.",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 485,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "POLAR PLUNGE SILENT DISCO ECSTATIC DANCE\n (clothing optional): When the weather allows and the vibe demands, we will be hosting Silent Discos throughout SOAK. With 5 different channels, there's something for everyone. Freely dive into the music and move comfortably in our safe space. Consensual contact only. All levels and degrees of dress welcome.",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 486,
    "day": "Saturday",
    "when": "02:30 PM-04:00 PM",
    "eventType": "Class/Workshop",
    "what": "POLY 101 + GROUP DISCUSSION — Whether you call it Polyamory, Ethical Non-Monogamy, or something else, we’re here to talk about relationship structures beyond monogamy. Interested in dating more than one person but don’t know where to start? New to poly and finding yourself fumbling around? We’ve got you babe.\n REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 487,
    "day": "Saturday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Food/Beverage",
    "what": "Pop into the bar for a dickleback, grab a bite of a\n body-part-shaped food, and party!Loud Noise, Seating, 21+",
    "where": "DSF",
    "area": "Main Field"
  },
  {
    "id": 488,
    "day": "Friday",
    "when": "11:59 PM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "PORNDOGGING: It’s back and hotter than ever! Come to OverFuckingRated hungry and ready to take it all in for our time-honored corn dogging tradition. Do it for the glory (hole) and don’t forget the condiments! 21+",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 489,
    "day": "Friday",
    "daily": "Everyday",
    "when": "04:00 PM-04:00 AM",
    "allDay": true,
    "eventType": "Other",
    "what": "POWER-UP! Drained and don't want to walk back to camp? The Embassy has you covered. If you need to charge a device, we have power for you! If it's just you that needs recharging, hang out around our warming fire/safe space and enjoy some popcorn. Don't know where the Embassy is? Just ask Lost & Found, and\n you’re sure to find your way. 😉Seating",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 490,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "04:00 PM-04:00 AM",
    "allDay": true,
    "eventType": "Other",
    "what": "POWER-UP! Drained and don't want to walk back to camp? The Embassy has you covered. If you need to charge a device, we have power for you! If it's just you that needs recharging, hang out around our warming fire/safe space and enjoy some popcorn. Don't know where the Embassy is? Just ask Lost & Found, and\n you’re sure to find your way. 😉Seating",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 491,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "04:00 PM-04:00 AM",
    "allDay": true,
    "eventType": "Other",
    "what": "POWER-UP! Drained and don't want to walk back to camp? The Embassy has you covered. If you need to charge a device, we have power for you! If it's just you that needs recharging, hang out around our warming fire/safe space and enjoy some popcorn. Don't know where the Embassy is? Just ask Lost & Found, and\n you’re sure to find your way. 😉Seating",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 492,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "04:00 PM-04:00 AM",
    "allDay": true,
    "eventType": "Other",
    "what": "POWER-UP! Drained and don't want to walk back to camp? The Embassy has you covered. If you need to charge a device, we have power for you! If it's just you that needs recharging, hang out around our warming fire/safe space and enjoy some popcorn. Don't know where the Embassy is? Just ask Lost & Found, and\n you’re sure to find your way. 😉Seating",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 493,
    "day": "Friday",
    "when": "05:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "Probably Pizza — Upgraded in probability for our second year! Do we have fresh, hot, delicious New Haven-style pizza available for your enjoyment? Probably! Will you have to brave the Wheel of Pizza? Probably! Will food and fun be had? Almost certainly!Vegetarian",
    "where": "Camp Take the Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 494,
    "day": "Saturday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Probably Pizza — Upgraded in probability for our second year! Do we have fresh, hot, delicious New Haven-style pizza available for your enjoyment? Probably! Will you have to brave the Wheel of Pizza? Probably! Will food and fun be had? Almost certainly!Vegetarian",
    "where": "Camp Take The Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 495,
    "day": "Thursday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats — Morning Show for People Who Don't Do Mornings",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 496,
    "day": "Friday",
    "when": "10:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Bangers & Mash\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 497,
    "day": "Thursday",
    "when": "10:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Bangers & Mash\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 498,
    "day": "Sunday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Daylight Afterhours\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 499,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Golden Hour\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 500,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Golden Hour\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 501,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Golden Hour\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 502,
    "day": "Sunday",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Professor Beats \"Midnite Moshup\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 503,
    "day": "Saturday",
    "when": "11:11 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Prophetic Pancakes — What will be revealed in the patterns of batter bubbles as they sizzle on the griddle? Allow our soothsayers to see your fortune through the divinatory arts of Pancake Prophecy and Tarot. Eat your fate (BYO plate), receive a reading, read yourself, or practice reading for others. Are we for realz, or full of sh*t? Come find out!Vegetarian, Alcohol-Free",
    "where": "Universal Eggsperience",
    "area": "Upper Bowl"
  },
  {
    "id": 504,
    "day": "Friday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Psychedelic Harm Reduction and Risk Informed Decision Making. Join some of our Rock Medicine medical professionals as they review the physiological and psychological signs, symptoms, and risk factors associated with common mind altering substances.No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 505,
    "day": "Saturday",
    "when": "09:00 PM-06:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "PSYTRANCE!!! If the psytrance isn't fast enough, please let us know.Loud Noise, Flashing Lights",
    "where": "HYSS",
    "area": "Mezzanine"
  },
  {
    "id": 506,
    "day": "Thursday",
    "when": "04:20 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Public Displays of Kink presents Face Slapping, Flogging, and Food Play, oh my! Learn face slapping and flogging techniques. This is a BYOFFF event — Bring Your Own Face, Flogger, and Food. Watch the action from the street or come join in the fun. 21+",
    "where": "Kinky Fucking Camp",
    "area": "North Point Forest"
  },
  {
    "id": 507,
    "day": "Sunday",
    "when": "04:20 PM-05:45 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Purple Haze Lounge. Stop by and get high. Joints, snacks, and vibes. Seating, Vegan, Gluten Free, 21+",
    "where": "Camp Take the Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 508,
    "day": "Thursday",
    "when": "05:45 PM-07:15 PM",
    "eventType": "Class/Workshop",
    "what": "qualiTEA touch — A consent driven, hands on—or hands off—embodiment workshop, facilitated by licensed massage therapists. We invite participants to mindfully engage with self, partnered, or group touch. Through deep listening/experiencing, we connect conscious understanding with nervous system knowing. Come for attuned bodywork, stay for discussion and tea service to follow. Seating",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 509,
    "day": "Saturday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Queer as in Happy Hour — As the saying goes: queer as in happy, gay as in f your burn, and trans af! Meet other queer folks, listen to some hella queer music, and have a fabulous UL cocktail.Seating",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 510,
    "day": "Friday",
    "when": "04:30 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "QUEER SPEED DATING / SPEED FRIENDSHIP:\n Looking to connect with other queer babes at SOAK? Here's your chance to meet sweet humans for friendship, flirting, and beyond. No pressure for any date-like outcomes, all identities welcome. REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 511,
    "day": "Friday",
    "when": "08:00 PM-10:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "QUEER SWIM — Queer sex matinee! MOIST'S sexy play space (the Splash) is open early for LGBTQ+ babes to play. REQUIRED FOR ENTRY: Physical ID verifying you’re 21+, a buddy (no singles admitted), the mental clarity to navigate consent, and willingness to play by the rules. 21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 512,
    "day": "Friday",
    "daily": "Everyday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Random Lunch",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 513,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Random Lunch",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 514,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Random Lunch",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 515,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Random Lunch",
    "where": "Watts-4-Lunch",
    "area": "Upper Bowl"
  },
  {
    "id": 516,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Random Song Generator — Do you like Madlibs? Well, this is way better (and way more musical!) Roll the dice (literally) to generate a completely improvised sonic creation with your own prompts and build the song structure in real time. Take the PolyJam to modes unknown and unlock new keys never before heard. Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 517,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Random Song Generator — Do you like Madlibs? Well, this is way better (and way more musical!) Roll the dice (literally) to generate a completely improvised sonic creation with your own prompts and build the song structure in real time. Take the PolyJam to modes unknown and unlock new keys never before heard. Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 518,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Random Song Generator — Do you like Madlibs? Well, this is way better (and way more musical!) Roll the dice (literally) to generate a completely improvised sonic creation with your own prompts and build the song structure in real time. Take the PolyJam to modes unknown and unlock new keys never before heard. Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 519,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Random Song Generator — Do you like Madlibs? Well, this is way better (and way more musical!) Roll the dice (literally) to generate a completely improvised sonic creation with your own prompts and build the song structure in real time. Take the PolyJam to modes unknown and unlock new keys never before heard. Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 520,
    "day": "Friday",
    "when": "03:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "REEDIE REUNION: A gathering happening for the colorful Venn diagram overlap between Reed College Alumni x SOAK Attendee. Let's find our cohort within the crowd! Bonus points for bringing the Doyle Owl.",
    "where": "The Barn by the Embassy",
    "area": "Barn"
  },
  {
    "id": 521,
    "day": "Friday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "Reject the Beach Happy Hour — Reject the afternoon slump, relax with us, and groove to some Rejected music. We're offering non-alcoholic drinks, cacao, snacks, Rejection Cookies ,and personalized rejections. Reject the Electric Ocean and wear whatever you wouldn't wear to the beach, or reject that idea and come as you are! Featuring playa-seasoned La Croix (while supplies last).Vegetarian, Alcohol-Free",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 522,
    "day": "Saturday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Food/Beverage",
    "what": "Reject the Beach Happy Hour — Reject the afternoon slump, relax with us, and groove to some Rejected music. We're offering non-alcoholic drinks, cacao, snacks, Rejection Cookies ,and personalized rejections. Reject the Electric Ocean and wear whatever you wouldn't wear to the beach, or reject that idea and come as you are! Featuring playa-seasoned La Croix (while supplies last).Vegetarian, Alcohol-Free",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 523,
    "day": "Sunday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Reject Your Feet? Get SOAK'ed! The ideal self care is when you sit down and someone else does the work for you. JK, you’re the one making the choice to show your feet some love. Soak your feet in lukewarm water, get them sugar scrubbed, and toes painted if you please.\n And come talk about what else you reject in your life. Dusty toes welcome!",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 524,
    "day": "Friday",
    "when": "01:00 PM-02:30 PM",
    "eventType": "Class/Workshop",
    "what": "Rejection and Consent Workshop. Come practice asking for what you want and rejecting what you don't in pairs/small groups. Discussion on the topic of receiving rejecting and having a difficult time saying no. Come alone or as part of a pair/small group.",
    "where": "Camp Rejected",
    "area": "Upper Bowl"
  },
  {
    "id": 525,
    "day": "Friday",
    "when": "05:15 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Remind your homies what you’re working with and take the top prize in our Sensual hot dog eating contest!\n Come show off those DSLs as you fellate our foot long franks for Snack Shack’s professional judges. All open mouths welcome to participate at 5:15pm!Loud Noise, Seating, 18+",
    "where": "Snack Shack",
    "area": "Upper Bowl"
  },
  {
    "id": 526,
    "day": "Saturday",
    "when": "08:15 PM-09:15 PM",
    "eventType": "Performance/Entertainment",
    "what": "REVERSE STRIP DANCING: Back by popular demand for a second year! Choose your favorite song, start with as little clothes as you'd like, and get dressed as sexy or silly as you can! Prep an outfit or let our stylists help you add the perfect accesories to complete your lewk. It's a choose-your-own-adventure kind of thing.Loud Noise, Seating, 18+",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 527,
    "day": "Friday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Rim job for a shot — come beat the skins and earn a shot at greatness!Loud Noise",
    "where": "Drumamine",
    "area": "Mezzanine"
  },
  {
    "id": 528,
    "day": "Sunday",
    "when": "03:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "S.O.A.K. Open Mic and Talent Showcase - Come one, come all! Partake in the culmination of Radical\n Self-Expression at S.O.A.K. 2024! Recite a poem, sing a song, deliver a monologue, or lead the band in a creation all your own! All forms of expression are welcome. All Polyjam band instruments available for your performance. A Polyjam Camp talent showcase will close out the spectacle.Loud Noise, Seating",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 529,
    "day": "Friday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Sabor a Brunch: Reggaeton Fiesta. Disfruta de un brunch con ritmo reggaeton en el set matutino de DJ Habanero. ¡No te pierdas esta divertida fiesta que te hará bailar desde el principio!Loud Noise, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 530,
    "day": "Friday",
    "when": "05:30 PM-06:00 PM",
    "eventType": "Class/Workshop",
    "what": "Safety — fire play 101. How to have a safe fire season.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 531,
    "day": "Thursday",
    "when": "05:30 PM-06:00 PM",
    "eventType": "Class/Workshop",
    "what": "Safety — fire play 101. How to have a safe fire season.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 532,
    "day": "Saturday",
    "when": "09:00 PM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "SALTY SOIREE! Keep the flames going and enjoy interacting with fire and friends. Discover the magic of three at TRIFECTA, float away on waves of bliss under the Flaming Sunflowers, shake your booty to our beats, or climb aboard our pirate ship and head out to sea. A night of surprises and delight with something for every salty dog.",
    "where": "Plunderground",
    "area": "Main Field"
  },
  {
    "id": 533,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Salut, mes chers amis! Come by and begin (or end) your day by indulging in our cozy lounge, musical vibes, and sweet connections as we serve you our world famous fresh-brewed organic coffee—hot and iced, as well as tea and pastries. Mandatory feather dustings. Spankings encouraged but optional.Vegetarian, Gluten Free, Alcohol-Free",
    "where": "Le Kafé",
    "area": "Riverside"
  },
  {
    "id": 534,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Salut, mes chers amis! Come by and begin (or end) your day by indulging in our cozy lounge, musical vibes, and sweet connections as we serve you our world famous fresh-brewed organic coffee—hot and iced, as well as tea and pastries. Mandatory feather dustings. Spankings encouraged but optional.Vegetarian, Gluten Free, Alcohol-Free",
    "where": "Le Kafé",
    "area": "Riverside"
  },
  {
    "id": 535,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Salut, mes chers amis! Come by and begin (or end) your day by indulging in our cozy lounge, musical vibes, and sweet connections as we serve you our world famous fresh-brewed organic coffee—hot and iced, as well as tea and pastries. Mandatory feather dustings. Spankings encouraged but optional.Vegetarian, Gluten Free, Alcohol-Free",
    "where": "Le Kafé",
    "area": "Riverside"
  },
  {
    "id": 536,
    "day": "Saturday",
    "when": "10:00 AM-11:00 PM",
    "allDay": true,
    "eventType": "Performance/Entertainment",
    "what": "Saturday Morning Breakfast with Moogle",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 537,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Saturday Spritz: It’s time to take a break from the beer and enjoy a refreshing aperitivo! Tantalize those tastebuds and settle that stomach with a classic, bubbly Italian treat. Come to OverFuckinRated for the best aperol spritz this side of the Tygh Valley!21+",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 538,
    "day": "Saturday",
    "when": "03:30 PM-05:30 PM",
    "eventType": "Class/Workshop",
    "what": "Sea of Experiences — How has your experience of sexuality been shaped throughout your life? What stories are imprinted into your body? Come share your experiences and witness others through grief and celebration. Includes a body story show-and-tell and offerings for non-sexual touch. Nudity is welcomed, bring a towel to sit on. Closed container, please arrive on time. 21+, Alcohol-Free",
    "where": "Octopussy's Tea House",
    "area": "Mezzanine"
  },
  {
    "id": 539,
    "day": "Saturday",
    "when": "12:30 PM-01:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "SELF PLEASURE CIRCLE We were taught that self pleasure was private, shouldn't be shared with anyone. This event challenges that belief and dives deeply into our own pleasure while others pleasure themselves. Join us for a visual and auditory experience that enhances pleasure in an intimate setting. Please bring a towel and toys you want to use.",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 540,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Class/Workshop",
    "what": "Self-care, Mindfulness, and Movement: Take a moment for yourself and join Rock Medicine staff for an interactive experience in the fine art of self-care and stress management. We will spend time unwinding, grounding, and learning new skills to manage the experiences found deep in the ocean of transformational experiences.No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 541,
    "day": "Saturday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Class/Workshop",
    "what": "Self-informed psychedelic harm reduction approaches and role play scenario practice: Join White Bird Rock Medicine staff for an interactive presentation on harm reduction strategies and humanistic intervention practices centered around the main tool you have in any scenario… Yourself!No Alcohol",
    "where": "Tent in front of Medical",
    "area": "Main Field"
  },
  {
    "id": 542,
    "day": "Friday",
    "when": "09:30 PM-10:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "Senile Felines: a Palindrama Read-Along — “Dammit I’m mad! Desserts, I stressed! In word salad, alas, drown I.” If you enjoy these and other palindromes, please attend and participate in our theatrical reading of Otto: a Palindrama. We’ll take turns reading an illustrated palindrome story. Before you know it, you’ll ask “was it a cat I saw?”Seating",
    "where": "Universal Eggsperience",
    "area": "Upper Bowl"
  },
  {
    "id": 543,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving the best biscuits and gravy with a side of bacon that you've ever had. Fri, Sat, Sun, 11 am until gone.",
    "where": "CBGB (Camp Biscuits, Gravy & Bacon)",
    "area": "Riverside"
  },
  {
    "id": 544,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving the best biscuits and gravy with a side of bacon that you've ever had. Fri, Sat, Sun, 11 am until gone.",
    "where": "CBGB (Camp Biscuits, Gravy & Bacon)",
    "area": "Riverside"
  },
  {
    "id": 545,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving the best biscuits and gravy with a side of bacon that you've ever had. Fri, Sat, Sun, 11 am until gone.",
    "where": "CBGB (Camp Biscuits, Gravy & Bacon)",
    "area": "Riverside"
  },
  {
    "id": 546,
    "day": "Friday",
    "daily": "Everyday",
    "when": "06:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving up the Best Mashed Potatoes Ever!Seating, Vegetarian, Gluten Free",
    "where": "I'd Smash that!",
    "area": "Upper Bowl"
  },
  {
    "id": 547,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "06:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving up the Best Mashed Potatoes Ever!Seating, Vegetarian, Gluten Free",
    "where": "I'd Smash that!",
    "area": "Upper Bowl"
  },
  {
    "id": 548,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "06:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving up the Best Mashed Potatoes Ever!Seating, Vegetarian, Gluten Free",
    "where": "I'd Smash that!",
    "area": "Upper Bowl"
  },
  {
    "id": 549,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Serving up the Best Mashed Potatoes Ever!Seating, Vegetarian, Gluten Free",
    "where": "I'd Smash that!",
    "area": "Upper Bowl"
  },
  {
    "id": 550,
    "day": "Friday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Set yourself up right for the weekend! Come ground with Jupiter, Light, & Miniata at Camp Clusterfuck as they lead you through the astrological forecast of the weekend and into a guided meditation sound bath. We will end with some refreshing mimosas! (N/A option available) Bring yourself, a cup, & whatever you need to get cozy (yoga mat, blanket, pillow, etc.)Loud Noise, Seating, 21+, Alcohol-Free",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 551,
    "day": "Thursday",
    "when": "02:00 PM-03:30 PM",
    "eventType": "Class/Workshop",
    "what": "SEX POSITIVE MEET & GREET Find your sex positive humans! Connect and create space where sex positive campers can meet and share ideas. Connection cards will help spark conversation and conversation!",
    "where": "Sex Positivity - EveryBody Gets Wet",
    "area": "Upper Bowl"
  },
  {
    "id": 552,
    "day": "Friday",
    "when": "12:30 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "SEXY STRETCHING 101. Get loose before you get down! Hot and knowledgeable sexperts will show you some techniques for making sure you’re nice and limber for the NSFW activities of your choosing. REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 553,
    "day": "Sunday",
    "when": "11:30 AM-01:45 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Shrinky Dinks on Sunday!! We have Pim Particles!! Come make a memento or a gift to take home!!!\n PG-13Seating, Loud Noise",
    "where": "The Teenie Weenie Art Tent",
    "area": "Riverside"
  },
  {
    "id": 554,
    "day": "Sunday",
    "when": "01:30 PM-02:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "Sing your (Future) Self Silly! We'll play with sound and create original music. Musical improvisation games and shenanigans. No experience necessary. Loud Noise",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 555,
    "day": "Friday",
    "when": "01:00 PM-02:15 PM",
    "eventType": "Class/Workshop",
    "what": "Sink into Intimacy: Bring a partner, friend, or stranger and begin your SOAK journey by sinking into intimacy. We will guide you through heart-opening and embodied experiences intended to cultivate authentic, loving connection with one another. A blend of exercises from meditation and reflective sharing to eye gazing and compassionate touch… Come play with us!Seating",
    "where": "Le Kafé",
    "area": "Riverside"
  },
  {
    "id": 556,
    "day": "Friday",
    "when": "02:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Slow Cuts: Come to the Slow Cuts Salon to get your hair cut or your beard trimmed. With our lentissimo approach, you can explore a fab new style without any fear of regrets. Seating, Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 557,
    "day": "Saturday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Smoked Bacon. We will be handing it out in front of camp, so bring something to put it on. Once it's gone, it’s gone. Come enjoy some fresh smoked bacon in the morning after a good night of dancing & frolicking.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 558,
    "day": "Sunday",
    "when": "10:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Smoked Bacon. We will be handing it out in front of camp, so bring something to put it on. Once it's gone, it’s gone. Come enjoy some fresh smoked bacon in the morning after a good night of dancing & frolicking.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 559,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "SMS: Slow Messaging Service — With SLOW ROLL's patented Slow Messaging Service (SMS), you can send messages to anyone at SOAK! Tell your crush your true feelings, thank the DJ from last night's set, or send positive vibes to a cool cat at the top of the hill. After writing a message, take another SMS on a delivery adventure.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 560,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "SMS: Slow Messaging Service — With SLOW ROLL's patented Slow Messaging Service (SMS), you can send messages to anyone at SOAK! Tell your crush your true feelings, thank the DJ from last night's set, or send positive vibes to a cool cat at the top of the hill. After writing a message, take another SMS on a delivery adventure.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 561,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "SMS: Slow Messaging Service — With SLOW ROLL's patented Slow Messaging Service (SMS), you can send messages to anyone at SOAK! Tell your crush your true feelings, thank the DJ from last night's set, or send positive vibes to a cool cat at the top of the hill. After writing a message, take another SMS on a delivery adventure.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 562,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "SMS: Slow Messaging Service — With SLOW ROLL's patented Slow Messaging Service (SMS), you can send messages to anyone at SOAK! Tell your crush your true feelings, thank the DJ from last night's set, or send positive vibes to a cool cat at the top of the hill. After writing a message, take another SMS on a delivery adventure.Vegetarian, Vegan, Gluten Free, Alcohol-Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 563,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Snack Time: Egg! Egg-based snack time. Egg-related events. Egg time. (May include: Egg.)Seating, Vegetarian, Gluten Free",
    "where": "Snacks N Snuggles",
    "area": "Upper Bowl"
  },
  {
    "id": 564,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Snail Mail: Get back to the basics with the snailest of mails. Come to Slow Roll and write a postcard to your future self. Make promises, tell secrets, record memories. It will take a loooonnnnngggg time to arrive. Vegetarian, Vegan, Gluten Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 565,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Snail Mail: Get back to the basics with the snailest of mails. Come to Slow Roll and write a postcard to your future self. Make promises, tell secrets, record memories. It will take a loooonnnnngggg time to arrive.Vegetarian, Vegan, Gluten Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 566,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Snail Mail: Get back to the basics with the snailest of mails. Come to Slow Roll and write a postcard to your future self. Make promises, tell secrets, record memories. It will take a loooonnnnngggg time to arrive.Vegetarian, Vegan, Gluten Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 567,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Snail Mail: Get back to the basics with the snailest of mails. Come to Slow Roll and write a postcard to your future self. Make promises, tell secrets, record memories. It will take a loooonnnnngggg time to arrive.Vegetarian, Vegan, Gluten Free",
    "where": "SLOW ROLL",
    "area": "Forest Entry"
  },
  {
    "id": 568,
    "day": "Thursday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Snake Daddy & Time Being — Cute Puke",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 569,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Snake Daddy's One-Eyed Wonder Hour",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 570,
    "day": "Saturday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Snap & Seek a Selfie-Scavenger Hunt: Get ready to seek, snap, and win at our selfie-scavenger hunt. Collect a list of unique selfies while connecting with fellow burners. Be one of the first to complete the list and claim a cute trophy as your prize!",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 571,
    "day": "Friday",
    "daily": "Everyday",
    "when": "09:00 PM-06:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Snuggle under the amazing flames as they dance above your head.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 572,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:00 PM-06:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Snuggle under the amazing flames as they dance above your head.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 573,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 PM-04:20 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Snuggle under the amazing flames as they dance above your head.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 574,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "09:00 PM-06:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Snuggle under the amazing flames as they dance above your head.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 575,
    "day": "Friday",
    "when": "12:00 PM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "SOAK 2024 Puzzle Hunt — 16 puzzles will be placed around the event. The puzzles will be available 24 hours a day after being placed around SOAK. After all the puzzles are solved, players try to solve the meta (a puzzle that requires all the answers of the other puzzles to solve). Skylar the puzzle master has a reward for completing the hunt. Runs all event long!!",
    "where": "Baggage Check",
    "area": "Meadow"
  },
  {
    "id": 576,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK SOAP Box: Come use the public use space at the Embassy to tell others about your \"thing.\" Schedule and Sign-up available onsite. No amplifiers or loud drums.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 577,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK SOAP Box: Come use the public use space at the Embassy to tell others about your \"thing.\" Schedule and Sign-up available onsite. No amplifiers or loud drums.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 578,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK SOAP Box: Come use the public use space at the Embassy to tell others about your \"thing.\" Schedule and Sign-up available onsite. No amplifiers or loud drums.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 579,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK SOAP Box: Come use the public use space at the Embassy to tell others about your \"thing.\" Schedule and Sign-up available onsite. No amplifiers or loud drums.",
    "where": "Embassy",
    "area": "Mezzanine"
  },
  {
    "id": 580,
    "day": "Saturday",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK'd in Sound: A Church of Party Dance Extravaganza! It's the moment you've all been waiting for! Alright Alright, Trim, and BobWire join forces for a genre-bending dance odyssey. Deep Tech House, Funk, Breaks, Disco—it's all on the menu. Prepare to get SOAK'd on the dance floor at Church of Party!Loud Noise",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 581,
    "day": "Friday",
    "when": "03:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK's favorite day party is BACK! Join us at Camp Clusterfuck for the official welcoming party. We've got daytime jams from The Serious, Mienne, and Loopacabra. While you're here, cool off with a refreshing dunkaroo. This party will get you ready to dance the night away!Loud Noise, Flashing Lights, Seating, Dancing",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 582,
    "day": "Sunday",
    "when": "04:30 PM-05:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "SOAK'S FUNNIEST: Come visit us at the \"North Pole\" stripper pole for our new event, SOAK's Funniest.\n Represent your camp and perform a 5 min stand-up (or sit down) comedy set. The big winner will get awarded the title of being SOAK's Funniest 2024... and a silly prize! Seating, Vegetarian, Vegan, Gluten Free, Nuts",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 583,
    "day": "Friday",
    "when": "04:30 PM-07:30 PM",
    "eventType": "Food/Beverage",
    "what": "Soft Rock Happy Hour — Come dance to some of your favorite Soft Rock and enjoy frozen blended drinks at our Monkey Bar. This icy shit is bananas! N/A also offered.\n Loud Noise",
    "where": "Camp Monkey Business",
    "area": "Upper Bowl"
  },
  {
    "id": 584,
    "day": "Saturday",
    "when": "04:30 PM-07:30 PM",
    "eventType": "Food/Beverage",
    "what": "Soft Rock Happy Hour — Come dance to some of your favorite Soft Rock and enjoy frozen blended drinks at our Monkey Bar. This icy shit is bananas! N/A also offered.\n Loud Noise",
    "where": "Camp Monkey Business",
    "area": "Upper Bowl"
  },
  {
    "id": 585,
    "day": "Friday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Sparking Connections: Make new friends, deepen existing bonds, spark meaningful conversations. Join for connection and camaraderie. All are welcome to the Oasis in the Meadow for conversation starters, mingling activities, and everything needed to jump-start new friendships.\n Seating",
    "where": "Future Self",
    "area": "North Point Forest"
  },
  {
    "id": 586,
    "day": "Friday",
    "when": "09:00 PM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Sparkle Party!!! For all those who like to dancey-dance, wear rubber boots and party pants!!! Dress like the sparkley disco ball you were meant to be and come get down on our dance floor! Loud Noise, Flashing Lights",
    "where": "Disco Tango Foxtrot—. DTF",
    "area": "Mezzanine"
  },
  {
    "id": 587,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Spider Makeovers and Spa - Bored of only having 4 limbs and 2 eyes? We get it! Come get a more spidery perspective on life by adding a few! While you’re here, treat yo’ self to some spider attractant lotion, a therapeutic spider bean bath, and an eight-legged massage. We’ll have you skittering about in no time!",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 588,
    "day": "Sunday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Spider Show and Tell/Rehoming Hour — Found a cool spider named Reginald at your camp/under your car/on the forest path/in the porto? Come tell us about him! No prior experience in spider ID necessary. For this hour only, you may rehome spiders with us without needing an entire container full of them, though you may have to complete some immigration paperwork.",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 589,
    "day": "Friday",
    "when": "03:00 PM-03:50 PM",
    "eventType": "Class/Workshop",
    "what": "Spin & Play: Intro to Hooping Workshop\n \n\n Learn basic techniques, waist hooping, and simple tricks to kickstart your hoop journey. Join us for a fun-filled session and discover the joy of flow through hoops!\n Some hoops will be available to borrow, but bring your own if one is available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 590,
    "day": "Saturday",
    "when": "02:30 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "SPIN THE BOTTLE — Need some social fluffing to get smooching? Come to this consent-forward and negotiation-based game of spin-the-bottle. We’ll lead by example and show you how to reclaim this game in the hottest, queerest way possible. Shy people encouraged! All genders welcome. More than kissing is allowed, but no genital play. REQUIRED FOR ENTRY: Physical ID showing you're 21+.21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 591,
    "day": "Friday",
    "daily": "Th, Fri, Sat",
    "when": "10:00 PM-02:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "SPLASH OPEN PLAY HOURS. Looking for a place to Sex? Come to the Splash! A sultry play space with beds, safer sex, and personal hygiene supplies, a BDSM area, and a lounge. REQUIRED FOR ENTRY: Physical ID verifying you’re 21+, a buddy (no singles admitted), the mental clarity to navigate consent, and willingness to play by the rules. 21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 592,
    "day": "Saturday",
    "daily": "Th, Fri, Sat",
    "when": "10:00 PM-02:30 AM",
    "eventType": "Experience/Interactive Art",
    "what": "SPLASH OPEN PLAY HOURS. Looking for a place to Sex? Come to the Splash! A sultry play space with beds, safer sex, and personal hygiene supplies, a BDSM area, and a lounge. REQUIRED FOR ENTRY: Physical ID verifying you’re 21+, a buddy (no singles admitted), the mental clarity to navigate consent, and willingness to play by the rules. 21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 593,
    "day": "Thursday",
    "daily": "Th, Fri, Sat",
    "when": "10:00 PM-01:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "SPLASH OPEN PLAY HOURS. Looking for a place to Sex? Come to the Splash! A sultry play space with beds, safer sex, and personal hygiene supplies, a BDSM area, and a lounge. REQUIRED FOR ENTRY: Physical ID verifying you’re 21+, a buddy (no singles admitted), the mental clarity to navigate consent, and willingness to play by the rules. 21+",
    "where": "MOIST",
    "area": "Lower Bowl"
  },
  {
    "id": 594,
    "day": "Friday",
    "daily": "Everyday",
    "when": "08:00 AM-03:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Stare into the eyes of God — Have you ever wondered what God looked like? What if God was one of us? Come by the portal near the Church of Party dance floor to catch a glimpse at the one responsible for this life we life.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 595,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "08:00 AM-03:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Stare into the eyes of God — Have you ever wondered what God looked like? What if God was one of us? Come by the portal near the Church of Party dance floor to catch a glimpse at the one responsible for this life we life.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 596,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "08:00 AM-03:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "Stare into the eyes of God — Have you ever wondered what God looked like? What if God was one of us? Come by the portal near the Church of Party dance floor to catch a glimpse at the one responsible for this life we life.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 597,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "04:00 PM-03:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Stare into the eyes of God — Have you ever wondered what God looked like? What if God was one of us? Come by the portal near the Church of Party dance floor to catch a glimpse at the one responsible for this life we life.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 598,
    "day": "Saturday",
    "when": "10:00 AM-11:15 AM",
    "eventType": "Class/Workshop",
    "what": "Start your morning melting into a fusion of movement and sound. We will stretch, rest, rejuvenate, and open our hearts as we dive into a Vinyasa Yoga Flow accompanied by a live experimental sound bath jam. Alcohol-Free",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 599,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Step right up to Camp Tiny Tramp! Join us for delicious espresso martinis, lawn games, and plenty of fun with our pint-sized tramp. Come make caffeinated, shaken, and stirred memories you may end up forgetting.Seating, 21+",
    "where": "Tiny Tramp Espresso-Tinis",
    "area": "Forest Entry"
  },
  {
    "id": 600,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Food/Beverage",
    "what": "Step right up to Camp Tiny Tramp! Join us for delicious espresso martinis, lawn games, and plenty of fun with our pint-sized tramp. Come make caffeinated, shaken, and stirred memories you may end up forgetting.Seating, 21+",
    "where": "Tiny Tramp Espresso-Tinis",
    "area": "Forest Entry"
  },
  {
    "id": 601,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Food/Beverage",
    "what": "Step right up to Camp Tiny Tramp! Join us for delicious espresso martinis, lawn games, and plenty of fun with our pint-sized tramp. Come make caffeinated, shaken, and stirred memories you may end up forgetting.Seating, 21+",
    "where": "Tiny Tramp Espresso-Tinis",
    "area": "Forest Entry"
  },
  {
    "id": 602,
    "day": "Sunday",
    "when": "11:00 AM-11:50 AM",
    "eventType": "Class/Workshop",
    "what": "STEVE, Steve, Where Are You?!: A Beginner Staff Workshop — No matter your level of experience or the kind of staff you get your flow on with, harness the classic Steve roll with drills, tips, and tricks. Some staffs will be available to borrow, but bring your own if one is available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 603,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Class/Workshop",
    "what": "Stick and Poke and Toke — Come learn stick and poke on fake skin from an actual tattoo artist. Limited supplies will be provided to first comers.\n 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 604,
    "day": "Friday",
    "when": "07:00 PM-10:00 PM",
    "eventType": "Food/Beverage",
    "what": "Stomach need a bump after so much bass dropping? Feeling famished after confessions with Father Fuckitall? Tasteful Side Booth provides amidst the prismatic vibes of Church of Party! Our spread of sweet and savory offerings will tickle all fancies and have asses ready for dance floor worship. Service held Friday and Saturday, 7pm-10pmLoud Noise, Flashing Lights, 21+, Sexual Content",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 605,
    "day": "Saturday",
    "when": "07:00 PM-10:00 PM",
    "eventType": "Food/Beverage",
    "what": "Stomach need a bump after so much bass dropping? Feeling famished after confessions with Father Fuckitall? Tasteful Side Booth provides amidst the prismatic vibes of Church of Party! Our spread of sweet and savory offerings will tickle all fancies and have asses ready for dance floor worship. Service held Friday and Saturday, 7pm-10pmLoud Noise, Flashing Lights, 21+, Sexual Content",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 606,
    "day": "Saturday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Stop by for a storytime adventure unlike any you’ve experienced. Gregory the storyteller will lead you on a family-friendly fantasy adventure created for and by the audience! It’s sure to be a rollicking good time for all.\n Brought to you by Camp Touchy Feely, a camp specializing in sensory objects and fidget toys.Seating, Alcohol-Free",
    "where": "Touchy Feely",
    "area": "Forest Entry"
  },
  {
    "id": 607,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-08:00 PM",
    "eventType": "Food/Beverage",
    "what": "Stop by Pickleodeon's pickle stand or find our roving pickle cart in the wild, and get a briney treat!",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 608,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "10:00 AM-07:00 PM",
    "allDay": true,
    "eventType": "Food/Beverage",
    "what": "Stop by Pickleodeon's pickle stand or find our roving pickle cart in the wild, and get a briney treat!",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 609,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Stop by Pickleodeon's pickle stand or find our roving pickle cart in the wild, and get a briney treat!",
    "where": "Pickleodeon",
    "area": "Main Field"
  },
  {
    "id": 610,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Stop by the Reading Rainbow library and browse our collection of hand-selected children’s books. Reawaken your inner child as you enjoy tales of wonder from across the ages. Books to read, Mad Libs to fill in, pillows to relax on - take a moment to chill, get lost in your imagination, or simply enjoy the company of other cool people.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 611,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Stop by the Reading Rainbow library and browse our collection of hand-selected children’s books. Reawaken your inner child as you enjoy tales of wonder from across the ages. Books to read, Mad Libs to fill in, pillows to relax on - take a moment to chill, get lost in your imagination, or simply enjoy the company of other cool people.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 612,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Stop by the Reading Rainbow library and browse our collection of hand-selected children’s books. Reawaken your inner child as you enjoy tales of wonder from across the ages. Books to read, Mad Libs to fill in, pillows to relax on - take a moment to chill, get lost in your imagination, or simply enjoy the company of other cool people.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 613,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Stop by the Reading Rainbow library and browse our collection of hand-selected children’s books. Reawaken your inner child as you enjoy tales of wonder from across the ages. Books to read, Mad Libs to fill in, pillows to relax on - take a moment to chill, get lost in your imagination, or simply enjoy the company of other cool people.Seating",
    "where": "Reading Rainbow",
    "area": "Main Field"
  },
  {
    "id": 614,
    "day": "Friday",
    "when": "08:00 PM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Strip karaoke! We will have a bar, dance pole, and karaoke machine at \"Camp Misbehaving.\" We hope to do a fun night of entertainment. Want to take off clothes?\n Sure! Just want to sing? No problem! Whatever you're comfortable with.Loud Noise, Seating, 18+",
    "where": "Camp\n Mis-Behaving",
    "area": "Meadow"
  },
  {
    "id": 615,
    "day": "Thursday",
    "when": "08:00 PM-09:30 PM",
    "eventType": "Food/Beverage",
    "what": "Strip off those sweaty set-up duds, slap on your SOAK finest, and join DSF for Sunset Formal Cocktail Hour!Loud Noise, 21+",
    "where": "DSF",
    "area": "Main Field"
  },
  {
    "id": 616,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Sugar cereal! You love it! You need it! We've\n de-commodified it for your enjoyment. BYOB (Bring your own bowl). Spoon users, bring one. Come to Chameleon Camp to start your day off RIGHT (or left). While supplies last.Vegetarian, Alcohol-Free",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 617,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Sugar cereal! You love it! You need it! We've\n de-commodified it for your enjoyment. BYOB (Bring your own bowl). Spoon users, bring one. Come to Chameleon Camp to start your day off RIGHT (or left). While supplies last.Vegetarian, Alcohol-Free",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 618,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Sugar cereal! You love it! You need it! We've\n de-commodified it for your enjoyment. BYOB (Bring your own bowl). Spoon users, bring one. Come to Chameleon Camp to start your day off RIGHT (or left). While supplies last.Vegetarian, Alcohol-Free",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 619,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Sugar cereal! You love it! You need it! We've\n de-commodified it for your enjoyment. BYOB (Bring your own bowl). Spoon users, bring one. Come to Chameleon Camp to start your day off RIGHT (or left). While supplies last.Vegetarian, Alcohol-Free",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 620,
    "day": "Sunday",
    "when": "05:15 AM-06:15 AM",
    "eventType": "Food/Beverage",
    "what": "SUNRISE BUBBLES AND BUBBLY: We'll be making\n Giant Bubbles and Bubbly Mimosas for Sunrise. We'll also have plenty of bubble-making fun to share. Let's start Sunday off right by blowing bubbles, not popping them!Vegetarian, Vegan, Gluten Free",
    "where": "WET TRIBE",
    "area": "Upper Bowl"
  },
  {
    "id": 621,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Swim on by and start your day off right! We're serving Pancakes and Soft Rock from Nine-ish to Noon-ish. Enjoy some hot syrupy goodness while being serenaded by sweet tunes! Gluten-free & vegan pancakes available. BYO plate.",
    "where": "Pancake Playhouse",
    "area": "Forest Entry"
  },
  {
    "id": 622,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Swim on by and start your day off right! We're serving Pancakes and Soft Rock from Nine-ish to Noon-ish. Enjoy some hot syrupy goodness while being serenaded by sweet tunes! Gluten-free & vegan pancakes available. BYO plate.",
    "where": "Pancake Playhouse",
    "area": "Forest Entry"
  },
  {
    "id": 623,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-12:00 PM",
    "eventType": "Food/Beverage",
    "what": "Swim on by and start your day off right! We're serving Pancakes and Soft Rock from Nine-ish to Noon-ish. Enjoy some hot syrupy goodness while being serenaded by sweet tunes! Gluten-free & vegan pancakes available. BYO plate.",
    "where": "Pancake Playhouse",
    "area": "Forest Entry"
  },
  {
    "id": 624,
    "day": "Thursday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Swim on over for a fun guided meditation with youthful imaginative scenes. All ages are welcome. Bring any items that help you feel calm and comfortable. There are plenty of huggable stuffies in camp who would be happy to keep you company. Brought to you by Camp Touchy Feely, a camp specializing in sensory objects and fidget toys.Seating, Alcohol-Free",
    "where": "Touchy Feely",
    "area": "Forest Entry"
  },
  {
    "id": 625,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Swim The Smack invites participants to \"swim\" through a multi-colored group of jellyfish (also known as a Bloom) in a playful way. While the iridescent jellyfish will reside under the simulated surface for play during the daytime; the experience is even better at night as the jellyfish light up and the ocean's surface magically comes to life.\n Flashing Lights",
    "where": "Our piece (#46) will be located on the West Side of the Barn",
    "area": "Barn"
  },
  {
    "id": 626,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Swim The Smack invites participants to \"swim\" through a multi-colored group of jellyfish (also known as a Bloom) in a playful way. While the iridescent jellyfish will reside under the simulated surface for play during the daytime; the experience is even better at night as the jellyfish light up and the ocean's surface magically comes to life.\n Flashing Lights",
    "where": "Our piece (#46) will be located on the West Side of the Barn",
    "area": "Barn"
  },
  {
    "id": 627,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Swim The Smack invites participants to \"swim\" through a multi-colored group of jellyfish (also known as a Bloom) in a playful way. While the iridescent jellyfish will reside under the simulated surface for play during the daytime; the experience is even better at night as the jellyfish light up and the ocean's surface magically comes to life.\n Flashing Lights",
    "where": "Our piece (#46) will be located on the West Side of the Barn",
    "area": "Barn"
  },
  {
    "id": 628,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "12:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Swim The Smack invites participants to \"swim\" through a multi-colored group of jellyfish (also known as a Bloom) in a playful way. While the iridescent jellyfish will reside under the simulated surface for play during the daytime; the experience is even better at night as the jellyfish light up and the ocean's surface magically comes to life.\n Flashing Lights",
    "where": "Our piece (#46) will be located on the West Side of the Barn",
    "area": "Barn"
  },
  {
    "id": 629,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Swing by Pampered Camper to our new FLUFFER station! Seat yourself upon the throne and receive the royal treatment. We have a wide range of fluffy, feathery, and fluttery things to delight your body with. Non-kinky and non-sexual! Just some fun with fluff!",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 630,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Swing by Pampered Camper to our new FLUFFER station! Seat yourself upon the throne and receive the royal treatment. We have a wide range of fluffy, feathery, and fluttery things to delight your body with. Non-kinky and non-sexual! Just some fun with fluff!",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 631,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Swing by Pampered Camper to our new FLUFFER station! Seat yourself upon the throne and receive the royal treatment. We have a wide range of fluffy, feathery, and fluttery things to delight your body with. Non-kinky and non-sexual! Just some fun with fluff!",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 632,
    "day": "Saturday",
    "when": "03:00 PM-04:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Talent Show/Open Mic — Show us what you got! Sign up before the show over at Babe Camp.",
    "where": "Babe Camp",
    "area": "Mezzanine"
  },
  {
    "id": 633,
    "day": "Friday",
    "when": "12:00 PM-12:50 PM",
    "eventType": "Class/Workshop",
    "what": "Tantric Embodiment: Awaken your Senses — Join us in playful space merging body, mind, and spirit! Exploring breathwork, movement, and touch for a transformative, sensory experience. Embracing self-love through tantric practice & (con)sensual awareness. Leaving behind judgement to embrace our vulnerability. Bring a partner or come solo, prepared to connect deeply with yourself/others, yoga mat if accessible, water bottle18+",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 634,
    "day": "Friday",
    "when": "07:30 PM-09:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Tarot & Tea: Wondering what the future has in store for you? Do you have a question you've been needing answered? Or a difficult decision to make? Settle into our cozy space with a relaxing cup of tea, share what's on your mind, and allow the cards to shed some light onto those difficult life questions.",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 635,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Tea Steep and Sound Bath: Join us for Tea Steep and Sound Bath! Relax and drift away with a sound bath serenade.",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 636,
    "day": "Friday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tea Time Jams (and Crackers) — Every day at proper tea time (4pm), we will serving up home-made jams while jamming! If that's your jam, please do enjoy some complimentary tea and crackers with our jam. If you're in a jam, you might as well jam in our jam and taste our jams for good measure. 4pm everyday 'til the jam is gone...Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 637,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tea Time Jams (and Crackers) — Every day at proper tea time (4pm), we will serving up home-made jams while jamming! If that's your jam, please do enjoy some complimentary tea and crackers with our jam. If you're in a jam, you might as well jam in our jam and taste our jams for good measure. 4pm everyday 'til the jam is gone...Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 638,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tea Time Jams (and Crackers) — Every day at proper tea time (4pm), we will serving up home-made jams while jamming! If that's your jam, please do enjoy some complimentary tea and crackers with our jam. If you're in a jam, you might as well jam in our jam and taste our jams for good measure. 4pm everyday 'til the jam is gone...Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 639,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tea Time Jams (and Crackers) — Every day at proper tea time (4pm), we will serving up home-made jams while jamming! If that's your jam, please do enjoy some complimentary tea and crackers with our jam. If you're in a jam, you might as well jam in our jam and taste our jams for good measure. 4pm everyday 'til the jam is gone...Loud Noise, Seating, Vegetarian, Vegan, Gluten Free",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 640,
    "day": "Sunday",
    "when": "03:00 PM-03:50 PM",
    "eventType": "Class/Workshop",
    "what": "Tech Fan Magic: Initiation Workshop — Get initiated into your fan magic! We will guide you through grip techniques, shapes, and transitions you can mix and match to your flow style. Awaken your inner tech fan magician today! Some fans will be available to borrow, but bring a set of fans if one is available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 641,
    "day": "Friday",
    "when": "02:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tentacle, Tarot, and High Tea: Come get in-depth tarot readings by seers adorned with tentacle fingers amidst an aquatic-themed high tea served by elegant fish. This surreal gathering promises a mesmerizing journey into the future, wrapped in the enchantment of the deep sea.Seating",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 642,
    "day": "Saturday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tentacle, Tarot, and High Tea: Come get in-depth tarot readings by seers adorned with tentacle fingers amidst an aquatic-themed high tea served by elegant fish. This surreal gathering promises a mesmerizing journey into the future, wrapped in the enchantment of the deep sea.Seating",
    "where": "Cosmic Chai",
    "area": "Upper Bowl"
  },
  {
    "id": 643,
    "day": "Friday",
    "when": "02:00 PM-02:50 PM",
    "eventType": "Class/Workshop",
    "what": "The Art of Repetitive Dropping: Juggling 101\n \n\n If you catch em, you don't have to bend over to pick em up again. Bring your own juggling objects or borrow some of ours for the workshop.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 644,
    "day": "Friday",
    "daily": "Everyday",
    "when": "11:00 AM-02:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The board game lounge is open! Hang out in our lounge, play a game from our collection, and enjoy a cup of tea (tea available in afternoons). Open late! Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 645,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:00 AM-02:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The board game lounge is open! Hang out in our lounge, play a game from our collection, and enjoy a cup of tea (tea available in afternoons). Open late! Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 646,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "The board game lounge is open! Hang out in our lounge, play a game from our collection, and enjoy a cup of tea (tea available in afternoons). Open late! Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 647,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "05:00 PM-02:00 AM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The board game lounge is open! Hang out in our lounge, play a game from our collection, and enjoy a cup of tea (tea available in afternoons). Open late! Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 648,
    "day": "Friday",
    "when": "10:00 PM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "The Cosmic Ocean and The Spark—Dance Party—From primordial seas of the universe, lightning sparks life and miraculously culminates in a dance party!Loud Noise, Flashing Lights",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 649,
    "day": "Friday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The finest purveyors of high-quality, bargain-priced soulmates. Come by during our warehouse store hours, order a refreshment from the Pharmacy, and one of our associates will get to know you before connecting you with others we think you should meet. Our core goals are facilitating moments for authentic human connection, delivering service with a smile, and maximizing shareholder investments.",
    "where": "Costco Soulmate Trading Outlet",
    "area": "Riverside"
  },
  {
    "id": 650,
    "day": "Saturday",
    "when": "01:00 PM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The finest purveyors of high-quality, bargain-priced soulmates. Come by during our warehouse store hours, order a refreshment from the Pharmacy, and one of our associates will get to know you before connecting you with others we think you should meet. Our core goals are facilitating moments for authentic human connection, delivering service with a smile, and maximizing shareholder investments.",
    "where": "Costco Soulmate Trading Outlet",
    "area": "Riverside"
  },
  {
    "id": 651,
    "day": "Saturday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The Great Hammock Race — Teams of 3 race to complete a course in the quickest time possible. The catch is that one member can't touch the ground or another teammates in order to move. Prizes awarded to the speediest teams.",
    "where": "Hang Loose",
    "area": "Lower Bowl"
  },
  {
    "id": 652,
    "day": "Friday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The Great Pacific Giving Plankton — While the Great Pacific Garbage Patch is accumulating, our GPGP is intended to dissipate. Help give these items a second-life. Do your part to help shrink the Giving Plankton! Repurpose, Reuse, Upcycle.",
    "where": "The Great Pacific Giving Plankton",
    "area": "Barn"
  },
  {
    "id": 653,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The Great Pacific Giving Plankton — While the Great Pacific Garbage Patch is accumulating, our GPGP is intended to dissipate. Help give these items a second-life. Do your part to help shrink the Giving Plankton! Repurpose, Reuse, Upcycle.",
    "where": "The Great Pacific Giving Plankton",
    "area": "Barn"
  },
  {
    "id": 654,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "The Great Pacific Giving Plankton — While the Great Pacific Garbage Patch is accumulating, our GPGP is intended to dissipate. Help give these items a second-life. Do your part to help shrink the Giving Plankton! Repurpose, Reuse, Upcycle.",
    "where": "The Great Pacific Giving Plankton",
    "area": "Barn"
  },
  {
    "id": 655,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 PM-11:59 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The Great Pacific Giving Plankton — While the Great Pacific Garbage Patch is accumulating, our GPGP is intended to dissipate. Help give these items a second-life. Do your part to help shrink the Giving Plankton! Repurpose, Reuse, Upcycle.",
    "where": "The Great Pacific Giving Plankton",
    "area": "Barn"
  },
  {
    "id": 656,
    "day": "Friday",
    "when": "06:30 PM-08:30 PM",
    "eventType": "Performance/Entertainment",
    "what": "The Infamous Miss Soak Pageant and Cocktail Party hosted by Miss Soak and Glowdeo Drive! Come dressed to impress, and bring your kookiest talent, too! Posh cocktails and Hot Corn Dogs await.",
    "where": "Glowdeo Drive",
    "area": "Main Field"
  },
  {
    "id": 657,
    "day": "Friday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The Librarian is in! While Bibliothēca Idiōmatis is open 24 hours for your enjoyment, swing by during librarian hours for an extra barrel of laughs. You may have chance to throw your hat in the ring, boil the ocean, or kick the bucket. It'll be a whale of a time!",
    "where": "Bibliothēca Idiōmatis",
    "area": "Barn"
  },
  {
    "id": 658,
    "day": "Saturday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The Librarian is in! While Bibliothēca Idiōmatis is open 24 hours for your enjoyment, swing by during librarian hours for an extra barrel of laughs. You may have chance to throw your hat in the ring, boil the ocean, or kick the bucket. It'll be a whale of a time!",
    "where": "Bibliothēca Idiōmatis",
    "area": "Barn"
  },
  {
    "id": 659,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-03:00 AM",
    "eventType": "Food/Beverage",
    "what": "The Sauteed Spider Bar — After scurrying about all day, give your legs a rest at our late night bar, The Sauteed Spider, where you’ll find hot SPIced cIDER and all your favorite breakfast cereals (yes, even Spooder Loops!) served with an enthusiastic helping of Spider Facts.Seating",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 660,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-03:00 AM",
    "eventType": "Food/Beverage",
    "what": "The Sauteed Spider Bar—After scurrying about all day, give your legs a rest at our late night bar, The Sauteed Spider, where you’ll find hot SPIced cIDER and all your favorite breakfast cereals (yes, even Spooder Loops!) served with an enthusiastic helping of Spider Facts.Seating",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 661,
    "day": "Saturday",
    "when": "02:00 PM-04:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "The Sauteed Spider Bar—After scurrying about all day, give your legs a rest at our late night bar, The Sauteed Spider, where you’ll find hot SPIced cIDER and all your favorite breakfast cereals (yes, even Spooder Loops!) served with an enthusiastic helping of Spider Facts.Seating",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 662,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "12:00 AM-03:00 AM",
    "eventType": "Food/Beverage",
    "what": "The Sauteed Spider Bar—After scurrying about all day, give your legs a rest at our late night bar, The Sauteed Spider, where you’ll find hot SPIced cIDER and all your favorite breakfast cereals (yes, even Spooder Loops!) served with an enthusiastic helping of Spider Facts.Seating",
    "where": "Spider Town",
    "area": "Upper Bowl"
  },
  {
    "id": 663,
    "day": "Saturday",
    "when": "11:00 PM-04:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "The Sinner's Ball: Join us following Saturday's burn ceremony as Portland local DJ's NIKKID, Mienne, Krista Basis, Cazrep, and others take you on a blazing musical journey right in our own pulpit! Our DJ's will be spinning a blend of house, tech house and bass house beats all night to keep your booty low and your spirits high. Loud Noise, Flashing Lights, 21+",
    "where": "Church of Party Main Stage",
    "area": "Mezzanine"
  },
  {
    "id": 664,
    "day": "Saturday",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Class/Workshop",
    "what": "The Sounds of Light — Can a music note cast a shadow? Can a color have a crescendo? Swing by Camp Synthesthesia for a brief history of music, light, and everything in-between.",
    "where": "Synthesthesia",
    "area": "Upper Bowl"
  },
  {
    "id": 665,
    "day": "Saturday",
    "when": "12:01 AM-02:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "The Uranus Lounge: Feeling strange? Is there a tingling in your butt? Weird aliens are here to abduct you, and they are…. sexy? Come lounge, groove, sip some brain juice, play with alien artifacts, and wiggle to the sounds of intergalactic bass in our neon lounge.Loud Noise, Flashing Lights, Seating, 21+",
    "where": "Best Butt",
    "area": "Mezzanine"
  },
  {
    "id": 666,
    "day": "Sunday",
    "when": "10:15 AM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "There's no shame in drinking on Sunday morning in Krampusberg. Come and join our congregation for a morning of unusual spirits and a different vibe at our Alpen Dämonen themed bar. Be prepared to show your ID.\n 21+",
    "where": "Krampusberg",
    "area": "Forest Entry"
  },
  {
    "id": 667,
    "day": "Sunday",
    "when": "08:30 PM-11:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "TIDAL RAVE! Send the weekend out right by following the Siren’s Song to the watery depths of Plunderground and relax, flow, and swirl in the currents of your joy.\n Embody the ocean’s calm energy as you soothe your soul in the warmth of good times and magical moments. Last chance to get your booty over and check it out!",
    "where": "Plunderground",
    "area": "Main Field"
  },
  {
    "id": 668,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot coffee, cold creamer, and other rejuvenating refreshments. Join us in the Tiddy Dome to get the day started with DJ Wake the Funk Up mixing funk, soul, and 90's jams. We will discuss the great philosophical questions: \"What happened last night? Where are my pants? Why do I smell like burnt hair?\"Vegetarian, Vegan, Gluten Free, Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 669,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot coffee, cold creamer, and other rejuvenating refreshments. Join us in the Tiddy Dome to get the day started with DJ Wake the Funk Up mixing funk, soul, and 90's jams. We will discuss the great philosophical questions: \"What happened last night? Where are my pants? Why do I smell like burnt hair?\"Vegetarian, Vegan, Gluten Free, Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 670,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "09:00 AM-11:00 AM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot coffee, cold creamer, and other rejuvenating refreshments. Join us in the Tiddy Dome to get the day started with DJ Wake the Funk Up mixing funk, soul, and 90's jams. We will discuss the great philosophical questions: \"What happened last night? Where are my pants? Why do I smell like burnt hair?\"Vegetarian, Vegan, Gluten Free, Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 671,
    "day": "Friday",
    "daily": "Everyday",
    "when": "08:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot herbal teas and other rejuvenating refreshments. Join us in the Tiddy Dome for a hot beverage and roll the dice for an engaging experience. Take a seat by the fire, where we will discuss the great philosophical questions: \"Is this a giant glowing nipple? What's in this drink? What's that shiny thing over there?\"Seating, Vegetarian, Vegan, Gluten Free,\n Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 672,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 PM-11:59 PM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot herbal teas and other rejuvenating refreshments. Join us in the Tiddy Dome for a hot beverage and roll the dice for an engaging experience. Take a seat by the fire, where we will discuss the great philosophical questions: \"Is this a giant glowing nipple? What's in this drink? What's that shiny thing over there?\"Seating, Vegetarian, Vegan, Gluten Free,\n Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 673,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "09:30 PM-11:30 PM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot herbal teas and other rejuvenating refreshments. Join us in the Tiddy Dome for a hot beverage and roll the dice for an engaging experience. Take a seat by the fire, where we will discuss the great philosophical questions: \"Is this a giant glowing nipple? What's in this drink? What's that shiny thing over there?\"Seating, Vegetarian, Vegan, Gluten Free,\n Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 674,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "08:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tiddy Space has everything! Hot herbal teas and other rejuvenating refreshments. Join us in the Tiddy Dome for a hot beverage and roll the dice for an engaging experience. Take a seat by the fire, where we will discuss the great philosophical questions: \"Is this a giant glowing nipple? What's in this drink? What's that shiny thing over there?\"Seating, Vegetarian, Vegan, Gluten Free,\n Alcohol-Free, 21+",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 675,
    "day": "Saturday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Time Being — Derriére Discotheque",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 676,
    "day": "Friday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Time Being — Rump-Shaker Radio Hour",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 677,
    "day": "Friday",
    "when": "11:00 AM-12:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Time Being — Sunglasses & Asprin",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 678,
    "day": "Sunday",
    "when": "04:00 PM-05:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Time Being \"Emo Elders' Time Capsule\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 679,
    "day": "Thursday",
    "when": "08:00 PM-09:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Time Being \"For the Time Being\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 680,
    "day": "Saturday",
    "when": "08:30 PM-10:30 PM",
    "eventType": "Food/Beverage",
    "what": "Toast! Get Toasted, reincarnated! Immediately following MBS.",
    "where": "Babe Camp",
    "area": "Mezzanine"
  },
  {
    "id": 681,
    "day": "Thursday",
    "when": "05:00 PM-06:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock — Groovy Shit",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 682,
    "day": "Thursday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock \"Hard-Ass Techno\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 683,
    "day": "Saturday",
    "when": "10:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Tock & Time Being \"Disco Inferno\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 684,
    "day": "Friday",
    "when": "08:00 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock & Zann \"The Stars My Destination\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 685,
    "day": "Saturday",
    "when": "12:00 AM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Tock & Zann \"The Stars My Destination\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 686,
    "day": "Thursday",
    "when": "12:00 AM-01:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Tock's Late Night Vibez",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 687,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock's Melancholy Dance Jamz",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 688,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock's Melancholy Dance Jamz",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 689,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "01:00 PM-02:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Tock's Melancholy Dance Jamz",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 690,
    "day": "Thursday",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Food/Beverage",
    "what": "Tots and Prayers. Come by for a hot helping of tater tots while we say a prayer (or take a pre-confessional) for folks who may need a little guidance before we delve into this weekend of hedonism and revelry.Vegetarian, Gluten Free",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 691,
    "day": "Friday",
    "daily": "Everyday",
    "when": "07:30 PM-03:00 AM",
    "eventType": "Food/Beverage",
    "what": "Twilight Tea: We'll start serving tea each night beginning around twilight. We offer a variety of teas, both caffeinated and non-, served in a gongfu-inspired ceremonial manner. Come for the chill vibes and stay for the divination!",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 692,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "07:30 PM-03:00 AM",
    "eventType": "Food/Beverage",
    "what": "Twilight Tea: We'll start serving tea each night beginning around twilight. We offer a variety of teas, both caffeinated and non-, served in a gongfu-inspired ceremonial manner. Come for the chill vibes and stay for the divination!",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 693,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "07:30 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Twilight Tea: We'll start serving tea each night beginning around twilight. We offer a variety of teas, both caffeinated and non-, served in a gongfu-inspired ceremonial manner. Come for the chill vibes and stay for the divination!",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 694,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "07:30 PM-12:00 AM",
    "eventType": "Food/Beverage",
    "what": "Twilight Tea: We'll start serving tea each night beginning around twilight. We offer a variety of teas, both caffeinated and non-, served in a gongfu-inspired ceremonial manner. Come for the chill vibes and stay for the divination!",
    "where": "Fortunia",
    "area": "Forest Entry"
  },
  {
    "id": 695,
    "day": "Friday",
    "when": "02:00 AM-04:00 AM",
    "eventType": "Class/Workshop",
    "what": "Universe Life Salon — Existence... what's up with that? Let's sit on the shore of the cosmic ocean, watch some videos, and talk all about it.Seating",
    "where": "Undisclosed Location",
    "area": "Main Field"
  },
  {
    "id": 696,
    "day": "Thursday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Unruly Roulette: Ya feelin' lucky, drunk? Well, do ya? Spin the boozy roulette wheel for a chance to win a shot of something! Who knows what you'll get! We don't! 21+",
    "where": "OverFuckinRated",
    "area": "Main Field"
  },
  {
    "id": 697,
    "day": "Friday",
    "when": "03:00 PM-05:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Virgin Bloody Mary — The Papal Poop awaits your confession! Which of the 10 Dusty Sins have you committed? The Virgin and Bloody Mary will offer absolution and/or condemnation via flogging,\n self-actualized Eucharistic beverages and snacks, or other means. You are holy, your body is hole-y. Mass will be held at 4:20pm, homily: E=mc². Adults only please.\n Can I get a gaaaaymen?!Alcohol-Free, 18+",
    "where": "Universal Eggsperience",
    "area": "Upper Bowl"
  },
  {
    "id": 698,
    "day": "Friday",
    "when": "03:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Waddle your way down and join us in shaking our tail feathers at Love Games for our duck-themed happy-hour dance party! Open decks (come play with our decks) from 3-5pm, with our camp local DJs spinning from\n 5-8pm. Loud Noise, Seating",
    "where": "Love Games",
    "area": "Forest Entry"
  },
  {
    "id": 699,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Wanna get HOT and bothered? Dress for less and come play a few rounds of Bingo Bang Bang at Camp Take The Heat. Leave the kids at camp and take your chances at our naughty bingo game. Bring your A game, and come join us for some filthy fun on Friday afternoon!Vegetarian, Vegan, Gluten Free, 21+",
    "where": "Camp Take The Heat",
    "area": "Upper Bowl"
  },
  {
    "id": 700,
    "day": "Sunday",
    "when": "04:00 PM-06:00 PM",
    "eventType": "Food/Beverage",
    "what": "Want a nice fancy cocktail that is zero proof and looks and tastes amazing? This event is for you. Bring a cup.Alcohol-Free",
    "where": "Caffeinated Catalyst",
    "area": "Main Field"
  },
  {
    "id": 701,
    "day": "Saturday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Want cleaner, softer feet? Step into Softer Space, where three fearless foot fairies tackle your festival-filthy feet. Let them work their magic with a foot wash, moisturizing massage, and a DIY neon nail polish finale. Embrace the quirky charm and dance the night away with feet fit for a festival fairytale. Experience Softer Space for softer feet and fun!",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 702,
    "day": "Sunday",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Want cleaner, softer feet? Step into Softer Space, where three fearless foot fairies tackle your festival-filthy feet. Let them work their magic with a foot wash, moisturizing massage, and a DIY neon nail polish finale. Embrace the quirky charm and dance the night away with feet fit for a festival fairytale. Experience Softer Space for softer feet and fun!",
    "where": "Softer Space",
    "area": "Riverside"
  },
  {
    "id": 703,
    "day": "Friday",
    "daily": "Everyday",
    "when": "06:00 AM-09:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Warm Snuggles is open for snuggling almost the entire event, day and night.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 704,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "06:00 AM-09:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Warm Snuggles is open for snuggling almost the entire event, day and night.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 705,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "06:00 AM-09:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Warm Snuggles is open for snuggling almost the entire event, day and night.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 706,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 AM-09:00 PM",
    "allDay": true,
    "eventType": "Experience/Interactive Art",
    "what": "Warm Snuggles is open for snuggling almost the entire event, day and night.",
    "where": "Warm Snuggles",
    "area": "Mezzanine"
  },
  {
    "id": 707,
    "day": "Thursday",
    "when": "10:00 AM-08:00 PM",
    "allDay": true,
    "eventType": "Class/Workshop",
    "what": "We are a completely off-grid charging hub for all of your charging needs. There will be numerous outlets, multiple voltage chargers for your batteries along with random dance parties and other group thangs.Flashing Lights, Seating",
    "where": "Cheeky Chargers",
    "area": "Mezzanine"
  },
  {
    "id": 708,
    "day": "Friday",
    "daily": "Everyday",
    "when": "11:00 AM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "We are an iced tea and lemonade stand that will quench your thirst on the hottest day. We also have hot tea at night if you need to warm up right! Alcohol-Free",
    "where": "Tea Time",
    "area": "Mezzanine"
  },
  {
    "id": 709,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:00 AM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "We are an iced tea and lemonade stand that will quench your thirst on the hottest day. We also have hot tea at night if you need to warm up right! Alcohol-Free",
    "where": "Tea Time",
    "area": "Mezzanine"
  },
  {
    "id": 710,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:00 AM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "We are an iced tea and lemonade stand that will quench your thirst on the hottest day. We also have hot tea at night if you need to warm up right! Alcohol-Free",
    "where": "Tea Time",
    "area": "Mezzanine"
  },
  {
    "id": 711,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "05:00 PM-11:00 PM",
    "eventType": "Food/Beverage",
    "what": "We are an iced tea and lemonade stand that will quench your thirst on the hottest day. We also have hot tea at night if you need to warm up right! Alcohol-Free",
    "where": "Tea Time",
    "area": "Mezzanine"
  },
  {
    "id": 712,
    "day": "Friday",
    "daily": "Everyday",
    "when": "11:30 AM-01:30 PM",
    "eventType": "Food/Beverage",
    "what": "We will provide authentic fresh handmade fettuccini noodles for lunches during SOAK! We have vegan options but no gluten-free options yet. If you want to watch us make the noodles, get there a little early!",
    "where": "Fresh Hot Noods",
    "area": "Forest Entry"
  },
  {
    "id": 713,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "11:30 AM-01:30 PM",
    "eventType": "Food/Beverage",
    "what": "We will provide authentic fresh handmade fettuccini noodles for lunches during SOAK! We have vegan options but no gluten-free options yet. If you want to watch us make the noodles, get there a little early!",
    "where": "Fresh Hot Noods",
    "area": "Forest Entry"
  },
  {
    "id": 714,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "11:30 AM-01:30 PM",
    "eventType": "Food/Beverage",
    "what": "We will provide authentic fresh handmade fettuccini noodles for lunches during SOAK! We have vegan options but no gluten-free options yet. If you want to watch us make the noodles, get there a little early!",
    "where": "Fresh Hot Noods",
    "area": "Forest Entry"
  },
  {
    "id": 715,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Food/Beverage",
    "what": "We will provide authentic fresh handmade fettuccini noodles for lunches during SOAK! We have vegan options but no gluten-free options yet. If you want to watch us make the noodles, get there a little early!",
    "where": "Fresh Hot Noods",
    "area": "Forest Entry"
  },
  {
    "id": 716,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Welcome to Cereal Monogamy Ceremonies! Find your true love in a sea of cereal boxes. Sample, mingle, and commit to your perfect match for a lifetime of breakfast bliss. It's cereal monogamy at its finest!",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 717,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Welcome to Cereal Monogamy Ceremonies! Find your true love in a sea of cereal boxes. Sample, mingle, and commit to your perfect match for a lifetime of breakfast bliss. It's cereal monogamy at its finest!",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 718,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Welcome to Cereal Monogamy Ceremonies! Find your true love in a sea of cereal boxes. Sample, mingle, and commit to your perfect match for a lifetime of breakfast bliss. It's cereal monogamy at its finest!",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 719,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Welcome to Cereal Monogamy Ceremonies! Find your true love in a sea of cereal boxes. Sample, mingle, and commit to your perfect match for a lifetime of breakfast bliss. It's cereal monogamy at its finest!",
    "where": "Chameleon Camp",
    "area": "Forest Entry"
  },
  {
    "id": 720,
    "day": "Thursday",
    "when": "12:00 PM-01:00 PM",
    "eventType": "Food/Beverage",
    "what": "Welcome to the Jungle! Our grand opening relay event! Want to help us make the Jungle Juice for the weekend? Join us for a 2-team relay race to see who can mix drinks the fastest. Find us in the Main Field, near The Barn.21+",
    "where": "Black Rock Center for Unlearning",
    "area": "Main Field"
  },
  {
    "id": 721,
    "day": "Friday",
    "when": "04:00 PM-09:00 PM",
    "eventType": "Food/Beverage",
    "what": "Welcome to Wontonamo Bay. Friday is Fryday. What do you like in your wontons? Better question, do you have any of that stuff? Bring by an ingredient, and let's make some bizarre flavor combinations. Roll your own and fried to order.",
    "where": "FryGuy",
    "area": "Mezzanine"
  },
  {
    "id": 722,
    "day": "Friday",
    "when": "09:00 PM-11:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "What's In Your Pocket? Come roll glowing dice under the black light for some Tiddy-lating rounds of the classic game of Three's! What are the stakes? Well, what's in your pocket?! Trinkets, whimsical bits and bobs, that piece of pocket lint you swear looks like your grandma—all bets and offers are welcome!",
    "where": "Tiddy Space",
    "area": "Forest Entry"
  },
  {
    "id": 723,
    "day": "Friday",
    "when": "08:30 PM-10:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "WHEEL OF KARAOKE — Karaoke song books are overwhelming, amirite? Why not let THE WHEEL decide which (somewhat silly and reasonably well-known) song you'll sing?! Spin us right round, baby.",
    "where": "Flower Bower",
    "area": "Meadow"
  },
  {
    "id": 724,
    "day": "Friday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "While You Are Here, take a dinghy for a stroll, fish for wishes, learn what’s happening around SOAK and spread the word about your camp events at our Information Wharf.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 725,
    "day": "Saturday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "While You Are Here, take a dinghy for a stroll, fish for wishes, learn what’s happening around SOAK and spread the word about your camp events at our Information Wharf.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 726,
    "day": "Sunday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "While You Are Here, take a dinghy for a stroll, fish for wishes, learn what’s happening around SOAK and spread the word about your camp events at our Information Wharf.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 727,
    "day": "Thursday",
    "daily": "Everyday",
    "when": "10:00 AM-12:00 AM",
    "eventType": "Experience/Interactive Art",
    "what": "While You Are Here, take a dinghy for a stroll, fish for wishes, learn what’s happening around SOAK and spread the word about your camp events at our Information Wharf.",
    "where": "You Are\n Here-Information Wharf",
    "area": "Forest Entry"
  },
  {
    "id": 728,
    "day": "Friday",
    "when": "06:00 PM-08:00 PM",
    "eventType": "Food/Beverage",
    "what": "Whine and Cheese! Pour a glass, grab a plate, and let it all out. Share your frustrations over fine wine and gourmet cheese in a judgment-free zone. Join us for an evening of venting and camaraderie. 21+",
    "where": "Church of Cheese",
    "area": "Main Field"
  },
  {
    "id": 729,
    "day": "Saturday",
    "when": "06:00 PM-07:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Who needs a drum circle when you can have a drum square? Come get ready for the burn with us. Plenty of drums or bring your own!Loud Noise",
    "where": "Drumamine",
    "area": "Mezzanine"
  },
  {
    "id": 730,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "06:30 PM-07:30 PM",
    "eventType": "Food/Beverage",
    "what": "Wind down with a bowl of mush in the Great Green Room! Oatmeal, toppings, and milk provided. Stay for a reading of Goodnight Moon and other children’s books. Fun for all ages. Bring your own bowl.",
    "where": "The Great Green Room",
    "area": "Forest Entry"
  },
  {
    "id": 731,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "06:30 PM-07:30 PM",
    "eventType": "Food/Beverage",
    "what": "Wind down with a bowl of mush in the Great Green Room! Oatmeal, toppings, and milk provided. Stay for a reading of Goodnight Moon and other children’s books. Fun for all ages. Bring your own bowl.",
    "where": "The Great Green Room",
    "area": "Forest Entry"
  },
  {
    "id": 732,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "06:30 PM-07:30 PM",
    "eventType": "Food/Beverage",
    "what": "Wind down with a bowl of mush in the Great Green Room! Oatmeal, toppings, and milk provided. Stay for a reading of Goodnight Moon and other children’s books. Fun for all ages. Bring your own bowl.",
    "where": "The Great Green Room",
    "area": "Forest Entry"
  },
  {
    "id": 733,
    "day": "Thursday",
    "when": "12:00 AM-11:59 PM",
    "allDay": true,
    "eventType": "Performance/Entertainment",
    "what": "x",
    "where": "PolyJamorous Camp",
    "area": "Upper Bowl"
  },
  {
    "id": 734,
    "day": "Friday",
    "when": "12:00 PM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Yarn Social — Show off your crochet, knit, or other handmade items, and meet others crafters to talk techniques, yarn, and all the fun stuff. Drinks provided.",
    "where": "Church of Party",
    "area": "Mezzanine"
  },
  {
    "id": 735,
    "day": "Sunday",
    "when": "02:00 PM-04:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Yarn-making demo — Come watch and experience yarn-making in action! We'll be using a drum carder as well as actively handspinning with wheels and drop spindles. Give it a whirl yourself or bring your craft projects to hang with us!",
    "where": "Practical Pleasures",
    "area": "Forest Entry"
  },
  {
    "id": 736,
    "day": "Friday",
    "when": "11:00 AM-11:50 AM",
    "eventType": "Class/Workshop",
    "what": "Year of the Dragon Staff: All Levels Workshop—Beginning to advanced dragon spinners welcome! Beginning at chi rolls and working our way to weaves. Bring a hat or hood to put hair up under. Some dragon staffs will be available to borrow, but bring your own if one is available to you.",
    "where": "Cats in Space!",
    "area": "Mezzanine"
  },
  {
    "id": 737,
    "day": "Saturday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Yoga2Ecstatic — Come for the yoga and maybe end up dancing? Come join us at Camp Clusterfuck for some morning movement. Maybe bring a mat or use one of ours. Loud Noise",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 738,
    "day": "Sunday",
    "when": "11:00 AM-01:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Yoga2Ecstatic — Come for the yoga and maybe end up dancing? Come join us at Camp Clusterfuck for some morning movement. Maybe bring a mat or use one of ours. Loud Noise",
    "where": "Camp Clusterfuck",
    "area": "Lower Bowl"
  },
  {
    "id": 739,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon — Morning Show for People Who Don't Do Mornings",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 740,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon — Morning Show for People Who Don't Do Mornings",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 741,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "03:00 PM-04:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon — Morning Show for People Who Don't Do Mornings",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 742,
    "day": "Friday",
    "when": "11:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon \"Beyond the Valley of the Desert\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 743,
    "day": "Thursday",
    "when": "07:00 PM-08:00 PM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon \"Golden Hour\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 744,
    "day": "Thursday",
    "when": "11:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon \"Tryp Ticket to Ride\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 745,
    "day": "Friday",
    "when": "12:00 AM-02:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon & Four to the Floor \"Midnight at the Oasis\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 746,
    "day": "Sunday",
    "when": "10:00 PM-12:00 AM",
    "eventType": "Performance/Entertainment",
    "what": "Yoon & Friends \"Mellotronic Meltdown\"",
    "where": "KBUTT",
    "area": "Upper Bowl"
  },
  {
    "id": 747,
    "day": "Friday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "You Go There Boats Kids Evening Boat Parade is an evening sightseeing tour for Kids. We ship out after dark. (Around 9)",
    "where": "You Are\n Here-Information Wharf!",
    "area": "Forest Entry"
  },
  {
    "id": 748,
    "day": "Thursday",
    "when": "09:00 PM-10:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "You Go There Boats Kids Evening Boat Parade is an evening sightseeing tour for Kids. We ship out after dark. (Around 9)",
    "where": "You Are\n Here-Information Wharf!",
    "area": "Forest Entry"
  },
  {
    "id": 749,
    "day": "Friday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "YOU NEED A MASSAGE! We have an amazing crew of LMTs with various modalities and talents who are ready to scintillate your being. Show up at 11am if you want to make the list.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 750,
    "day": "Saturday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-03:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "YOU NEED A MASSAGE! We have an amazing crew of LMTs with various modalities and talents who are ready to scintillate your being. Show up at 11am if you want to make the list.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 751,
    "day": "Sunday",
    "daily": "Fri, Sat, Sun",
    "when": "11:00 AM-02:00 PM",
    "eventType": "Experience/Interactive Art",
    "what": "YOU NEED A MASSAGE! We have an amazing crew of LMTs with various modalities and talents who are ready to scintillate your being. Show up at 11am if you want to make the list.",
    "where": "Pampered Camper",
    "area": "Meadow"
  },
  {
    "id": 752,
    "day": "Friday",
    "when": "07:00 PM-08:30 PM",
    "eventType": "Experience/Interactive Art",
    "what": "Your A- hole in a K- hole choose your own adventure reading.Seating",
    "where": "Camp Butt Hurt",
    "area": "Upper Bowl"
  }
]
