import * as React from "react";

function DayTime(props) {
  return (
    <svg
      id="prefix__Layer_2"
      data-name="Layer 2"
      width="4em"
      height="4em"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{stroke-width:0;fill:#1a1a1a}"}</style>
      </defs>
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path
          className="prefix__cls-2"
          d="M64 99.146C44.62 99.146 28.853 83.38 28.853 64c0-19.38 15.767-35.146 35.147-35.146S99.146 44.62 99.146 64c0 19.38-15.766 35.146-35.146 35.146zm0-65.292c-16.623 0-30.147 13.523-30.147 30.146S47.377 94.146 64 94.146 94.146 80.623 94.146 64 80.623 33.854 64 33.854zM64 20.331a2.5 2.5 0 01-2.5-2.5V6a2.5 2.5 0 115 0V17.83a2.5 2.5 0 01-2.5 2.5zM96.646 33.854a2.5 2.5 0 01-1.768-4.268l8.367-8.366a2.5 2.5 0 113.535 3.535l-8.366 8.366a2.492 2.492 0 01-1.768.733zM122 66.5h-11.831a2.5 2.5 0 110-5h11.83a2.5 2.5 0 110 5zM105.013 107.512c-.64 0-1.28-.244-1.768-.732l-8.366-8.366a2.5 2.5 0 113.535-3.536l8.366 8.366a2.5 2.5 0 01-1.767 4.268zM64 124.5a2.5 2.5 0 01-2.5-2.5v-11.831a2.5 2.5 0 115 0v11.83a2.5 2.5 0 01-2.5 2.5zM22.988 107.512a2.5 2.5 0 01-1.768-4.268l8.366-8.365a2.5 2.5 0 113.535 3.535l-8.365 8.366a2.492 2.492 0 01-1.768.732zM17.83 66.5H6a2.5 2.5 0 110-5h11.83a2.5 2.5 0 110 5zM31.353 33.854c-.64 0-1.279-.244-1.767-.733l-8.365-8.366a2.5 2.5 0 113.535-3.535l8.365 8.366a2.5 2.5 0 01-1.768 4.268z"
        />
        <path strokeWidth={0} fill="none" d="M0 0h128v128H0z" />
      </g>
    </svg>
  );
}

const MemoDayTime = React.memo(DayTime);
export default MemoDayTime;
