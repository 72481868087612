import * as React from "react";

function AllDay(props) {
  return (
    <svg
      id="prefix__Layer_2"
      data-name="Layer 2"
      width="4em"
      height="4em"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{stroke-width:0;fill:#1a1a1a}"}</style>
      </defs>
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path
          className="prefix__cls-2"
          d="M36.43 54.255c-9.829 0-17.825-7.996-17.825-17.825s7.996-17.825 17.824-17.825c9.83 0 17.826 7.996 17.826 17.825s-7.996 17.825-17.826 17.825zm0-30.65c-7.072 0-12.825 5.753-12.825 12.825s5.753 12.825 12.824 12.825c7.073 0 12.826-5.753 12.826-12.825s-5.753-12.825-12.826-12.825zM36.43 14.883a2.5 2.5 0 01-2.5-2.5V6.22a2.5 2.5 0 115 0v6.163a2.5 2.5 0 01-2.5 2.5zM53.433 21.926a2.5 2.5 0 01-1.768-4.268l4.358-4.357a2.5 2.5 0 113.535 3.535l-4.357 4.358a2.492 2.492 0 01-1.768.732zM66.64 38.93h-6.163a2.5 2.5 0 110-5h6.162a2.5 2.5 0 110 5zM57.79 60.291c-.639 0-1.279-.244-1.767-.732L51.666 55.2a2.5 2.5 0 113.535-3.535l4.357 4.358a2.5 2.5 0 01-1.767 4.267zM36.43 69.14a2.5 2.5 0 01-2.5-2.5v-6.163a2.5 2.5 0 115 0v6.162a2.5 2.5 0 01-2.5 2.5zM15.07 60.291a2.5 2.5 0 01-1.768-4.268l4.357-4.357a2.5 2.5 0 113.535 3.535l-4.357 4.358a2.492 2.492 0 01-1.768.732zM12.383 38.93H6.22a2.5 2.5 0 110-5h6.163a2.5 2.5 0 110 5zM19.427 21.926c-.64 0-1.28-.244-1.768-.732l-4.357-4.358a2.5 2.5 0 113.535-3.535l4.357 4.357a2.5 2.5 0 01-1.767 4.268zM86.446 119.313c-13.954 0-26.419-8.868-31.017-22.067a2.502 2.502 0 01.98-2.907 2.502 2.502 0 013.06.232c4.6 4.166 10.522 6.46 16.68 6.46 13.72 0 24.882-11.162 24.882-24.882 0-6.158-2.294-12.081-6.46-16.679a2.499 2.499 0 012.675-4.04c13.198 4.597 22.066 17.061 22.066 31.016 0 18.123-14.744 32.867-32.866 32.867zm-21.864-15.625c5.184 6.576 13.185 10.625 21.864 10.625 15.365 0 27.866-12.501 27.866-27.867 0-8.68-4.048-16.68-10.624-21.864a29.692 29.692 0 012.343 11.567c0 16.477-13.405 29.882-29.882 29.882-4.009 0-7.934-.808-11.567-2.343zM13.057 122.72a2.5 2.5 0 01-1.768-4.268L118.454 11.29a2.5 2.5 0 113.535 3.535L14.825 121.988a2.492 2.492 0 01-1.768.732z"
        />
        <path strokeWidth={0} fill="none" d="M0 0h128v128H0z" />
      </g>
    </svg>
  );
}

const MemoAllDay = React.memo(AllDay);
export default MemoAllDay;
