import * as React from "react";

function CatArt(props) {
  return (
    <svg
      id="prefix__Layer_2"
      data-name="Layer 2"
      width="4em"
      height="4em"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{stroke-width:0;fill:#1a1a1a}"}</style>
      </defs>
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path
          className="prefix__cls-2"
          d="M60.34 116.5a53.57 53.57 0 01-10.432-1.033C28.174 111.149 11.282 92.87 8.83 71.017c-1.753-15.623 3.597-30.94 14.68-42.025C34.754 17.75 50.383 12.328 66.361 14.12c11.44 1.283 22.302 6.515 30.586 14.734a2.5 2.5 0 11-3.522 3.55c-7.487-7.429-17.296-12.157-27.62-13.315-14.474-1.62-28.597 3.277-38.758 13.439C17.046 42.53 12.217 56.356 13.799 70.46c2.211 19.713 17.46 36.204 37.084 40.103a48.478 48.478 0 0011.643.886 4.65 4.65 0 003.26-1.517 4.332 4.332 0 001.125-3.154c-.146-2.721-.327-6.11-.365-8.363-.102-6.034 2.248-11.037 6.285-13.382 2.387-1.386 5.133-1.716 7.339-1.982 6.908-.83 13.022-2.037 19.243-3.796 3.92-1.108 6.885-4.468 7.378-8.36.527-4.16.478-8.4-.146-12.602-.885-5.955-2.933-11.62-6.09-16.837a2.5 2.5 0 114.278-2.588c3.502 5.787 5.775 12.076 6.758 18.69a51.24 51.24 0 01.16 13.966c-.754 5.946-5.063 10.87-10.977 12.543-6.477 1.832-12.835 3.086-20.007 3.949-1.931.232-3.928.472-5.425 1.342-2.882 1.673-3.851 5.732-3.796 8.973.036 2.161.213 5.5.357 8.18a9.289 9.289 0 01-2.41 6.775c-1.733 1.915-4.124 3.036-6.735 3.157-.804.039-1.61.057-2.418.057z"
        />
        <path
          className="prefix__cls-2"
          d="M61.966 44.798c-5.74 0-10.41-4.636-10.41-10.335 0-5.698 4.67-10.334 10.41-10.334s10.41 4.636 10.41 10.334c0 5.699-4.67 10.335-10.41 10.335zm0-15.67c-2.983 0-5.41 2.394-5.41 5.335 0 2.942 2.427 5.335 5.41 5.335s5.41-2.393 5.41-5.335c0-2.941-2.427-5.334-5.41-5.334zM37.029 58.098c-5.74 0-10.41-4.636-10.41-10.334 0-5.699 4.67-10.335 10.41-10.335s10.41 4.636 10.41 10.335c0 5.698-4.67 10.334-10.41 10.334zm0-15.67c-2.983 0-5.41 2.394-5.41 5.336 0 2.941 2.427 5.334 5.41 5.334 2.982 0 5.41-2.393 5.41-5.334 0-2.942-2.428-5.335-5.41-5.335zM31.274 84.7c-5.74 0-10.41-4.637-10.41-10.336 0-5.698 4.67-10.334 10.41-10.334s10.41 4.636 10.41 10.334c0 5.7-4.67 10.335-10.41 10.335zm0-15.67c-2.982 0-5.41 2.393-5.41 5.334 0 2.942 2.428 5.335 5.41 5.335s5.41-2.393 5.41-5.335c0-2.941-2.427-5.334-5.41-5.334zM48.539 105.6c-5.74 0-10.41-4.636-10.41-10.334s4.67-10.335 10.41-10.335c5.739 0 10.409 4.637 10.409 10.335s-4.67 10.334-10.41 10.334zm0-15.67c-2.984 0-5.41 2.394-5.41 5.336s2.426 5.334 5.41 5.334c2.982 0 5.409-2.393 5.409-5.334s-2.427-5.335-5.41-5.335zM82.726 60.735a2.5 2.5 0 01-1.664-4.366c33.76-30.093 37.371-37.958 37.451-39.893h-.036c-2.846 0-14.447 7.232-40.776 35.444a2.5 2.5 0 11-3.656-3.411c22.796-24.425 37.754-36.876 44.463-37.007 1.848-.049 2.99.716 3.635 1.355s1.403 1.774 1.355 3.565c-.117 4.466-4.856 13.148-39.11 43.68a2.49 2.49 0 01-1.662.633z"
        />
        <path
          className="prefix__cls-2"
          d="M90.434 53.228a2.5 2.5 0 01-2.165-1.246c-2.02-3.484-4.259-5.698-6.657-6.58a2.5 2.5 0 111.727-4.692c3.521 1.295 6.635 4.244 9.256 8.764a2.5 2.5 0 01-2.16 3.754zM66.731 77.206c-2.175 0-4.462-.373-6.839-1.119-2.403-.753-4.338-1.74-5.914-3.016-1.914-1.547-3.186-3.878-4.03-5.42l-.161-.297a2.501 2.501 0 012.132-3.704c.68-.02 3.695-.717 4.346-.905 1.932-.578 3.035-1.074 3.694-2.175.405-.676.72-1.485 1.055-2.34.267-.682.542-1.387.889-2.1.877-1.8 2.155-3.25 3.8-4.305 2.663-1.708 6.089-2.21 9.403-1.367 3.328.844 6.135 2.96 7.7 5.803 1.454 2.641 1.832 6.076.962 8.749-1.063 3.273-3.545 5.969-4.928 7.284-2.39 2.27-5.261 3.828-8.303 4.504a17.473 17.473 0 01-3.806.408zm-10.74-9.246c.353.473.733.902 1.132 1.224 1.079.873 2.473 1.57 4.265 2.133 2.964.929 5.68 1.132 8.064.6 2.156-.479 4.212-1.602 5.943-3.247 1.745-1.66 3.098-3.605 3.618-5.206.442-1.357.195-3.372-.587-4.792-.9-1.635-2.558-2.862-4.55-3.368-1.974-.5-3.97-.235-5.473.73a5.552 5.552 0 00-2.004 2.284c-.259.532-.486 1.115-.727 1.731-.395 1.01-.803 2.054-1.422 3.089-1.639 2.738-4.243 3.706-6.556 4.398-.015.005-.744.199-1.702.424zm11.062-14.03h.01-.01z"
        />
        <path strokeWidth={0} fill="none" d="M0 0h128v128H0z" />
      </g>
    </svg>
  );
}

const MemoCatArt = React.memo(CatArt);
export default MemoCatArt;
